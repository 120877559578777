import { Role } from "../domain/Role";
import { UserRoleContext } from "../providers/UserProvider";

type ScopedAttribute = {
    [key: string]: {
        highestScope: "All" | "Product" | "Instance" | "Project";
        // allScopes is stored as string: "{ name: string; value: string }"
        // use JSON.parse() to get back proper object
        allScopes: Set<string>;
    };
};

export const findHighestScope = (scopes?: { name: string; value: string }[], scope?: string): string | undefined => {
    if (!scopes) return undefined;
    if (scopes?.length === 0 || scope === "All") return "All";

    const scopeTypes: {
        [key: string]: {
            priority: number;
            scopedAccess: string;
        };
    } = {
        ProductId: {
            priority: 1,
            scopedAccess: "Product"
        },
        InstanceId: {
            priority: 2,
            scopedAccess: "Instance"
        },
        AttributeId: {
            priority: 3,
            scopedAccess: "Project"
        }
    };
    let highestScope = "None";

    for (let scope of scopes) {
        const { name } = scope;
        if (highestScope === "None") {
            highestScope = name;
        } else {
            if (scopeTypes[highestScope]?.priority > scopeTypes[name]?.priority) {
                highestScope = name;
            }
        }
    }

    if (!!scope) {
        for (let key in scopeTypes) {
            if (scopeTypes[key].scopedAccess === scope) scope = key;
        }
        highestScope = scopeTypes[highestScope]?.priority > scopeTypes[scope]?.priority ? scope : highestScope;
    }

    return scopeTypes[highestScope]?.scopedAccess;
};

export const getUserScopedAttributes = (adminCenterRoles: Role[]): ScopedAttribute => {
    return adminCenterRoles.reduce((scopedAttributes: any, role: Role) => {
        const { attributes } = role;
        for (let attribute of attributes) {
            const { key, scopes } = attribute;

            if (scopedAttributes[key]) {
                const { highestScope, allScopes } = scopedAttributes[key];

                scopes?.length === 0
                    ? allScopes.add("[]")
                    : scopes?.forEach((scope) => allScopes.add(JSON.stringify(scope)));

                scopedAttributes[key] = {
                    highestScope: findHighestScope(scopes, highestScope),
                    allScopes
                };
            } else {
                const newScopes = new Set();

                scopes?.length === 0
                    ? newScopes.add("[]")
                    : scopes?.forEach((scope) => newScopes.add(JSON.stringify(scope)));

                scopedAttributes[key] = {
                    highestScope: findHighestScope(scopes),
                    allScopes: newScopes
                };
            }
        }
        return scopedAttributes;
    }, {});
};

export const hasScopedAccess = (highestScope: string, scopedAccess: string): boolean => {
    const scopeLevels = ["Project", "Instance", "Product", "All"];

    return scopeLevels.indexOf(highestScope) >= scopeLevels.indexOf(scopedAccess);
};

export const allowScope = ({
    scope,
    context,
    email
}: {
    scope: { name: string; value: string };
    context: UserRoleContext;
    email: string | undefined;
}): boolean => {
    if (scope.value === "self") {
        return context[scope.name] === email;
    }

    return scope.value === context[scope.name];
};
