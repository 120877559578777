import { useServicesContext } from "../../providers/ServicesProvider";
import { IAccessContext } from "../../services/UserService";
import useSWR from "swr";
import { UserGroup } from "../../domain/UserGroup";
import { Collection } from "../../domain/Collection";
import { useProducts } from "../useProducts/useProducts";
import { useAttributes } from "../useAttributes/useAttributes";
import { useEffect, useState } from "react";
import { OrganizationPermission, ProductPermission } from "../../domain/Permission.interface";
import {
    filterPermissionListsForContext,
    getOrgPermissionsFromGroupList,
    getProjects,
    orgPermissionListToAccessList
} from "../../services/AccessListTransforms";
import { IProductAttribute } from "../../domain/ProductAttribute.interface";
import { ALL_PAGES_SIZE, SWR_KEY_PREFIX } from "../../constants/constants";
import { User } from "../../domain/User";

export const useAccessList = ({
    organizationId,
    user,
    context
}: {
    organizationId?: string;
    user?: User | null;
    context?: IAccessContext;
}): {
    accessList: ProductPermission[] | undefined;
    error: Error | undefined;
    isLoading: boolean | undefined;
    organizationGroups: ProductPermission[] | undefined;
    projectsByInstance?: { [key: string]: IProductAttribute[] };
    userAccessLoading: boolean;
} => {
    const { userService } = useServicesContext();
    const { products } = useProducts({ organizationId, includeServices: true });
    const { getExperimentationProjects } = useAttributes({ isActive: true });
    const [accessList, setAccessList] = useState<ProductPermission[] | undefined>(undefined);
    const [organizationGroups, setOrganizationGroups] = useState<ProductPermission[] | undefined>(undefined);

    const [userAccessLoading, setUserAccessLoading] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const key =
        organizationId && user?.email
            ? `${
                  SWR_KEY_PREFIX.GROUPS
              }/api/usergroups/?organizationId=${organizationId}&email=${user?.email}&${user?.userGroupIds.join(",")}`
            : null;
    const orgGroupsKey = organizationId
        ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups/?organizationId=${organizationId}`
        : null;

    const { data: groups, error } = useSWR<Collection<UserGroup>, Error>(key, () => {
        return userService.getUserGroups({
            organizationId: organizationId!,
            emails: [user?.email!],
            size: ALL_PAGES_SIZE
        });
    });
    const { data: orgGroups, error: orgGroupsError } = useSWR<Collection<UserGroup>, Error>(orgGroupsKey, () => {
        return userService.getUserGroups({ organizationId: organizationId!, size: ALL_PAGES_SIZE });
    });

    useEffect(() => {
        setIsLoading(true);
        setUserAccessLoading(true);
    }, [user?.email]);

    useEffect(() => {
        const populateData = async (
            groups: Collection<UserGroup> | undefined,
            error: Error | undefined
        ): Promise<ProductPermission[] | undefined> => {
            if (groups && products && !error) {
                let orgPermissions: OrganizationPermission[] | undefined = getOrgPermissionsFromGroupList(
                    groups.items,
                    products
                );

                //get projects for EXP instances
                const promiseList = getProjects(orgPermissions, getExperimentationProjects);

                //wait until all projects are loaded (if any) before creating the access map.
                await Promise.all(promiseList);

                //filter by context scope and items
                orgPermissions = filterPermissionListsForContext(context!, orgPermissions);

                //turn into nested product/instance/project list
                return orgPermissionListToAccessList(orgPermissions);
            } else {
                return Promise.resolve([]);
            }
        };

        // create flat list of all permissions for the user based on their group membership
        populateData(groups, error).then((result: ProductPermission[] | undefined) => {
            if (result) setAccessList(result);
            setUserAccessLoading(false);
        });

        //create flat list of all permissions for all usergroups in the org.
        populateData(orgGroups, orgGroupsError).then((result: ProductPermission[] | undefined) => {
            if (result) setOrganizationGroups(result);
            setIsLoading(false);
        });
    }, [context, groups, orgGroups, getExperimentationProjects, products, error, orgGroupsError]);

    return {
        accessList,
        organizationGroups,
        userAccessLoading,
        isLoading: isLoading || (!groups && !error) || (!orgGroups && !orgGroupsError),
        error
    };
};
