import React, { useEffect, useRef, useState } from "react";
import { User } from "../../../../domain/User";
import { Attention, Button, Disclose, Typography } from "@optimizely/axiom";
import { SidebarFooter } from "../../Sidebar/SidebarFooter";
import { useUserContext } from "../../../../providers/UserProvider";
import { AccessManagementUserFormInstanceTable } from "./AccessManagementUserFormInstanceTable";
import { useAccessList } from "../../../../hooks/useAccessList/useAccessList";
import { UserFormNameFields } from "./UserFormNameFields";
import { useAccessFlowUserFormContext } from "./AccessManagementUserFormProvider";
import {
    ATTRIBUTE_ROLES,
    ADMINCENTER_PRODUCT_ID,
    ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
    REMOVE_GROUP_ACCESS_SUCCESS_MESSAGE,
    ADD_GROUP_ACCESS_SUCCESS_MESSAGE,
    CUSTOM_GROUP_ACCESS_ACTION,
    INVITATION_ERROR_MESSAGE,
    INVITATION_SUCCESS_MESSAGE
} from "../../../../constants/constants";
import { useUserGroups } from "../../../../hooks/useUserGroups/useUserGroups";
import { UserFormActivationStatus } from "./UserFormActivationStatus";
import { TableSkeleton } from "../../Skeleton/TableSkeleton";

import styles from "./AccessManagementUserForm.module.scss";
import { AccessManagementUserFormAccessHeader } from "./AccessManagementUserFormAccessHeader";
import { AddProductAccessPermission, AddProductAccessForm } from "../AddProductAccess/AddProductAccessForm";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../../feature-flags/flags";
import LimitByRole from "../../LimitByRole/LimitByRole";
import { AddGroupAccessForm } from "../AddGroupAccess/AddGroupAccessForm";
import { CustomGroupAccessConfirmationModal } from "../CustomGroupAccessConfirmation/CustomGroupAccessConfirmationModal";
import { datadogRum } from "@datadog/browser-rum";
import { Invitation } from "../../../../domain/Invitation";
import { useInvitations } from "../../../../hooks/useInvitations/useInvitations";
import { emitToast } from "../../../../lib/toaster-utils";
import { useAnalyticsTracking } from "../../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { getGrantPermissionsPayload } from "../../../../lib/analytics-helpers";
import { useLocation } from "react-router-dom";
import { ANALYTICS_FLOWS } from "../../../../constants/analytics-constants";

export const AccessManagementUserForm = ({
    onCancel,
    organizationId,
    showModal,
    user
}: {
    onCancel: () => void;
    organizationId?: string;
    showModal: React.Dispatch<React.SetStateAction<boolean>>;
    user: User;
}) => {
    const { email, homeOrganizationId: targetUserHomeOrgId, firstName, lastName } = user;
    const { enabled: enableNewAccessForm } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { setUserGroupIds, setActiveField, updateCustomGroupAccess, setUpdateCustomGroupAccess } =
        useAccessFlowUserFormContext();
    const { sendTrackEvent } = useAnalyticsTracking();
    const { pathname } = useLocation();

    const [error, setError] = useState<string | null>(null);
    const [accessFormType, setAccessFormType] = useState<"Product" | "Custom Group" | undefined>(undefined);
    const { accessContext, canUserDoAction, instancePermissions, profile } = useUserContext();
    const { email: actingUserEmail } = profile || {};
    const userEmail = useRef<string | undefined>(user?.email);
    const [inProgress, setInProgress] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const isProductAccessFlow = pathname.includes("/access/");
    const {
        accessList: access,
        organizationGroups: groupAvailability,
        isLoading: accessListLoading
    } = useAccessList({
        organizationId,
        user,
        context: accessContext
    });

    const { addUserGroupUsers, deleteUserGroupUsers } = useUserGroups({ organizationId });
    const { createInvitation } = useInvitations({ organizationId: organizationId });

    const handleGroupsChanged = async (
        previousGroupId: string | undefined,
        updatedGroupId: string | undefined
    ): Promise<any | void> => {
        if (!updatedGroupId) {
            return deleteUserGroupUsers({ userGroupId: previousGroupId || "", userEmails: [email] }).catch(handleError);
        }

        return deleteUserGroupUsers({ userGroupId: previousGroupId || "", userEmails: [email] })
            .then(() => addUserGroupUsers({ userGroupId: updatedGroupId, userEmails: [email] }))
            .catch(handleError);
    };

    const handleError = (error: Error) => {
        const normalizedError = Array.isArray(error) ? error[0] : error;
        let customMessage;

        if (updateCustomGroupAccess?.action === CUSTOM_GROUP_ACCESS_ACTION.REMOVE) {
            customMessage = "Failed to remove group access from user:";
        }

        if (updateCustomGroupAccess?.action === CUSTOM_GROUP_ACCESS_ACTION.ADD) {
            customMessage = "Failed to add new group access to user:";
        }

        const errorMessage = customMessage ? `${customMessage} ${normalizedError.message}` : normalizedError.message;

        setError(errorMessage);
        datadogRum.addError(normalizedError);
        console.error(errorMessage, normalizedError);
    };

    const userPermissionsInTargetHomeOrg = instancePermissions?.find((instancePermission) => {
        return (
            instancePermission.organizationId === targetUserHomeOrgId &&
            instancePermission.productId === ADMINCENTER_PRODUCT_ID
        );
    });
    const canEditUserName =
        organizationId === targetUserHomeOrgId
            ? canUserDoAction({ action: ATTRIBUTE_ROLES.USERS.UPDATE })
            : !!userPermissionsInTargetHomeOrg &&
              canUserDoAction({ action: ATTRIBUTE_ROLES.USERS.UPDATE, roles: userPermissionsInTargetHomeOrg?.roles });

    const closeAccessForm = () => {
        setAccessFormType(undefined);
    };

    const submitAddCustomGroupAccess = async () => {
        setInProgress(true);
        setError(null);

        await addUserGroupUsers({
            userGroupId: updateCustomGroupAccess?.id || "",
            userEmails: [email || ""]
        })
            .then(() => {
                const payload = getGrantPermissionsPayload({
                    userGroupIds: [updateCustomGroupAccess!.id!],
                    userGroupNames: [updateCustomGroupAccess!.name!],
                    email,
                    flow: isProductAccessFlow ? ANALYTICS_FLOWS.PRODUCT_ACCESS : ANALYTICS_FLOWS.USER_PAGE
                });
                sendTrackEvent(payload);
                setUpdateCustomGroupAccess(undefined);
                emitToast({ message: ADD_GROUP_ACCESS_SUCCESS_MESSAGE });
            })
            .catch(handleError)
            .finally(() => {
                setInProgress(false);
                setAccessFormType(undefined);
            });
    };

    const submitRemoveCustomGroupAccess = async () => {
        setInProgress(true);
        setError(null);
        await handleGroupsChanged(updateCustomGroupAccess?.id, undefined)
            .then(() => {
                setUpdateCustomGroupAccess(undefined);
                emitToast({ message: REMOVE_GROUP_ACCESS_SUCCESS_MESSAGE });
            })
            .finally(() => {
                setInProgress(false);
                setAccessFormType(undefined);
            });
    };

    const cancelUpdateCustomGroupAccess = () => {
        setUpdateCustomGroupAccess(undefined);
        setError(null);
    };

    const handleConfirmationModalSubmit = async () => {
        switch (updateCustomGroupAccess?.action) {
            case CUSTOM_GROUP_ACCESS_ACTION.ADD:
                return await submitAddCustomGroupAccess();
            case CUSTOM_GROUP_ACCESS_ACTION.REMOVE:
                return await submitRemoveCustomGroupAccess();
            default:
                return Promise.resolve().then(cancelUpdateCustomGroupAccess);
        }
    };

    useEffect(() => {
        setError(null);
        setUserGroupIds(user.userGroupIds);

        if (user?.email !== userEmail.current) {
            userEmail.current = user?.email;
            setActiveField({ name: null });
        }
    }, [user, setUserGroupIds, setActiveField]);

    const onGroupAccessClose = () => {
        setAccessFormType(undefined);
        setError(null);
    };

    useEffect(() => {
        const handleEvent = (e: CustomEvent) => {
            const { action, id, name, userGroup } = e.detail;
            setUpdateCustomGroupAccess({ action, id, name, userGroup });
        };

        window.addEventListener(
            ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
            handleEvent as EventListenerOrEventListenerObject
        );

        return () => {
            window.removeEventListener(
                ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
                handleEvent as EventListenerOrEventListenerObject
            );
        };
    }, [setUpdateCustomGroupAccess]);

    const handleSave = ({ permission }: { permission: AddProductAccessPermission }) => {
        setIsSaving(true);

        if (!permission) {
            // TODO: Error?
            setIsSaving(false);
            return;
        }

        const invitation = new Invitation({
            firstName,
            lastName,
            email,
            requireAcceptance: false,
            organizationId: organizationId!,
            userGroups: [permission!.group],
            createdBy: actingUserEmail!,
            created: new Date(),
            modified: new Date()
        });
        createInvitation(invitation)
            .then(() => {
                const { group, project } = permission;
                const { id: groupId, name: groupName } = group;
                const { id: projectId, name: projectName } = project || {};
                const payload = getGrantPermissionsPayload({
                    email,
                    projectIds: projectId ? [projectId] : [],
                    projectNames: projectName ? [projectName] : [],
                    userGroupIds: [groupId],
                    userGroupNames: [groupName],
                    flow: isProductAccessFlow ? ANALYTICS_FLOWS.PRODUCT_ACCESS : ANALYTICS_FLOWS.USER_PAGE
                });
                sendTrackEvent(payload);
                emitToast({ message: INVITATION_SUCCESS_MESSAGE });
                setIsSaving(false);
                closeAccessForm();
            })
            .catch((err) => {
                datadogRum.addError(err);
                console.error(err);
                emitToast({ message: INVITATION_ERROR_MESSAGE });
                setIsSaving(false);
            });
    };

    return (
        <form>
            {error && (
                <Attention alignment="left" className="push--top push-quad--bottom push-quad--sides" type="bad-news">
                    An error occurred while saving the user.
                </Attention>
            )}
            <div className="push-quad--left push-quad--right">
                <UserFormNameFields user={user} disableEdit={!canEditUserName} />
                <label className={`${styles["user-form-field-label"]} oui-label`}>Email</label>
                <Typography type="body" tag="div">
                    {email}
                </Typography>
                <UserFormActivationStatus
                    user={{ email: user?.email, externalStatus: user?.externalStatus }}
                    setError={setError}
                    showModal={showModal}
                />
                <AccessManagementUserFormAccessHeader>
                    {enableNewAccessForm && (
                        <LimitByRole
                            action={[ATTRIBUTE_ROLES.GROUPS.UPDATE, ATTRIBUTE_ROLES.GROUPS.MANAGE]}
                            mode="hide"
                        >
                            <div>
                                <Button
                                    className="push--right"
                                    // eslint-disable-next-line react/style-prop-object
                                    style="outline"
                                    isDisabled={!!accessFormType}
                                    whiteBackground
                                    onClick={() => setAccessFormType("Product")}
                                >
                                    Add Product Access
                                </Button>
                                <Button
                                    // eslint-disable-next-line react/style-prop-object
                                    style="outline"
                                    isDisabled={!!accessFormType}
                                    whiteBackground
                                    onClick={() => setAccessFormType("Custom Group")}
                                >
                                    Add Group Access
                                </Button>
                            </div>
                        </LimitByRole>
                    )}
                </AccessManagementUserFormAccessHeader>
                {accessFormType === "Product" && (
                    <AddProductAccessForm
                        className="push-double--top push-double--bottom"
                        onSubmit={handleSave}
                        onClose={closeAccessForm}
                        user={user}
                        isSaving={isSaving}
                    />
                )}
                {accessFormType === "Custom Group" && (
                    <AddGroupAccessForm
                        onClose={onGroupAccessClose}
                        organizationId={organizationId}
                        email={user.email}
                        customGroups
                    />
                )}

                {!!updateCustomGroupAccess && (
                    <CustomGroupAccessConfirmationModal
                        action={updateCustomGroupAccess?.action}
                        errorMessage={error}
                        isSaving={inProgress}
                        groupId={updateCustomGroupAccess.id}
                        groupName={updateCustomGroupAccess.name}
                        onClose={cancelUpdateCustomGroupAccess}
                        onSubmit={handleConfirmationModalSubmit}
                    />
                )}

                {accessListLoading ? (
                    <TableSkeleton />
                ) : (
                    access?.map((item, itemIndex) => {
                        return (
                            <Disclose title={item.productName as string} childrenStyle="push--bottom" key={itemIndex}>
                                <AccessManagementUserFormInstanceTable
                                    className="push--top push--bottom push--left"
                                    productAccess={item}
                                    onGroupsChanged={handleGroupsChanged}
                                    productAvailability={groupAvailability?.find(
                                        (pp) => pp.productId === item.productId
                                    )}
                                />
                            </Disclose>
                        );
                    })
                )}
            </div>
            <SidebarFooter onCancel={onCancel} cancelStyle="plain"></SidebarFooter>
        </form>
    );
};
