/* eslint-disable react/style-prop-object */
import React from "react";
import { Dashboard } from "@episerver/looker-dashboard";
import { useSession } from "../../../../hooks/useSession/useSession";
import { useUserContext } from "../../../../providers/UserProvider";
import styles from "../../Usage/Usage.module.scss";

export const MasterDashboard = () => {
    const { accessToken } = useSession();
    const { organizationId } = useUserContext();

    return (
        <div className={styles["usage-page"]}>
            <Dashboard
                organizationId={organizationId}
                accessToken={accessToken}
                productId={process.env.REACT_APP_MASTERDASHBOARD_PRODUCT_ID}
            />
        </div>
    );
};

MasterDashboard.displayName = "MasterDashboard";
