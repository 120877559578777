import React, { useEffect, useState } from "react";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { useLocation } from "react-router-dom";
import { useSession } from "../../../hooks/useSession/useSession";
import { useUserContext } from "../../../providers/UserProvider";
import { ANALYTICS_EVENT_NAMES } from "../../../constants/analytics-constants";
import { AccessToken } from "@okta/okta-auth-js";
import { Token } from "@okta/okta-auth-js/types/lib/oidc/types/Token";

export const AnalyticsTracking = () => {
    const { sendPageEvent, sendIdentifyEvent, sendGroupEvent, sendSessionEvent } = useAnalyticsTracking();
    const { user, oktaAuth } = useSession();
    const { organizationId, isLoading } = useUserContext();
    const [pendingRequest, setPendingRequest] = useState<any>(null);

    const location = useLocation();

    useEffect(() => {
        sendPageEvent();
    }, [location?.pathname, sendPageEvent]);

    useEffect(() => {
        if (organizationId) {
            sendGroupEvent();
        }
    }, [organizationId, sendGroupEvent]);

    useEffect(() => {
        if (user?.email) {
            sendIdentifyEvent();
        }
    }, [user?.email, sendIdentifyEvent]);

    useEffect(() => {
        if (pendingRequest && organizationId) {
            sendSessionEvent(pendingRequest.name, pendingRequest.email, organizationId);
            setPendingRequest(null);
        }
    }, [organizationId, pendingRequest, sendSessionEvent]);

    useEffect(() => {
        oktaAuth.tokenManager.on("added", function (key: string, token: Token) {
            if (key === "accessToken" && location.pathname === "/callback") {
                const { sub: email } = (token as AccessToken)?.claims || {};
                if (!pendingRequest) {
                    setPendingRequest({ name: ANALYTICS_EVENT_NAMES.SESSION_LOGIN, email });
                }
            }
        });

        // Triggered when a token has been renewed
        oktaAuth.tokenManager.on("renewed", function (key: string, token: Token) {
            if (key === "accessToken" && location.pathname !== "/logout" && location.pathname !== "/callback") {
                const { sub: email } = (token as AccessToken)?.claims || {};
                if (!pendingRequest) {
                    setPendingRequest({ name: ANALYTICS_EVENT_NAMES.SESSION_RENEWED, email });
                }
            }
        });

        return () => {
            oktaAuth.tokenManager.off("added");
            oktaAuth.tokenManager.off("renewed");
        };
    }, [oktaAuth, isLoading, location.pathname, sendSessionEvent, organizationId, pendingRequest]);

    return <></>;
};

AnalyticsTracking.displayName = "AnalyticsTracking";
