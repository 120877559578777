import { BaseFieldProps } from "./BaseFieldProps";
import React from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";

export const JsonWebKeySetURLField = ({ disabled = false, onChange, value = "" }: BaseFieldProps) => {
    return (
        <>
            <label className="oui-label push-double--top" htmlFor="jwks">
                JWKS URL
            </label>
            <input
                aria-describedby="jwks-error"
                className="oui-text-input"
                id="jwks"
                disabled={disabled}
                type="text"
                value={value}
                onChange={(e) => handleFieldChange({ e, onChange })}
            />
            <span className="oui-form-note" id="jwks-description">
                URL of the JSON Web Key Set [JWK] document
            </span>
        </>
    );
};

JsonWebKeySetURLField.displayName = "JsonWebKeySetURLField";
