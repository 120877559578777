import React, { useState } from "react";
import { Dropdown, LayoutGrid, LayoutGridCell, LayoutGridContainer, Poptip, Typography } from "@optimizely/axiom";

import { useOrganizationIdp } from "../../../hooks/useOrganizationIdp/useOrganizationIdp";
import { useUserContext } from "../../../providers/UserProvider";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { IIdentityProvider, OIDCIdpWithoutId } from "../../../domain/IdentityProvider";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";
import { IdentityProviderDisclosure } from "../../components/SSOForm/IdentityProviderDisclosure/IdentityProviderDisclosure";
import { NONE, OIDC, SAML, SSO_CONNECTION_LIMIT } from "../../../constants/constants";
import { SSOConnectionSetupFormSheet } from "../../components/SSOForm/SSOOIDCForm/SSOConnectionSetupFormSheet";
import { ISSOSamlFormFields } from "../../components/SSOForm/SSOSamlForm/SSOSamlFormFields/SSOSamlFormFields";

// @TODO: Update when fully implemented - for demonstration purposes only.
type ConnectionTypes = "OIDC" | "SAML";
const CONNECTION_TYPES_ARRAY: ConnectionTypes[] = [OIDC, SAML];
const ADD_CONNECTION = "Add SSO Connection";
export const SSOSetup = () => {
    const { organizationId } = useUserContext();
    const { loading: organizationLoading, refetchOrganization } = useOrganization({ id: organizationId });
    const [selectedConnectionType, setSelectedConnectionType] = useState<"None" | ConnectionTypes>(NONE);
    const { identityProviders, addSAMLProvider, addOIDCProvider, deleteOrganizationProvider, error, isLoadingIdp } =
        useOrganizationIdp(organizationId);

    const addSAMLIdp = async ({ idpName, ssoUrl, issuerUrl, signatureCertificate }: ISSOSamlFormFields) => {
        await addSAMLProvider({
            idpName,
            issuer: issuerUrl,
            ssoUrl,
            certificate: signatureCertificate!
        });
        // ssoEnabled is computed and since it comes from the org we need to refetch...
        await refetchOrganization();
    };

    const addOIDCIdp = async ({ idp }: { idp: OIDCIdpWithoutId }) => {
        await addOIDCProvider({ ...idp, idpName: idp.idpName! });
        // ssoEnabled is computed and since it comes from the org we need to refetch...
        await refetchOrganization();
    };

    const handleNewConnectionRequest = async ({ idp }: { idp: OIDCIdpWithoutId | ISSOSamlFormFields }) => {
        if (selectedConnectionType === OIDC) {
            return addOIDCIdp({ idp: idp as OIDCIdpWithoutId });
        } else if (selectedConnectionType === SAML) {
            return addSAMLIdp(idp as ISSOSamlFormFields);
        }
    };

    if (isLoadingIdp || organizationLoading) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching SSO Connection</div>;

    const connectionLimitReached = (identityProviders?.length || 0) >= SSO_CONNECTION_LIMIT;

    return (
        <LayoutGridContainer className="width--1-1">
            <LayoutGrid>
                <LayoutGridCell xlarge={6} large={6} medium={8} small={4} className="push-triple--bottom">
                    <div className="push-double--top push-double--bottom">
                        <Typography type="header1" className="push-triple--top">
                            Single Sign-on (SSO)
                        </Typography>
                        <Typography type="body" tag="p">
                            Single sign-on (SSO) is an integration method that lets enterprise users access multiple
                            applications with a single authorization
                        </Typography>
                    </div>

                    {connectionLimitReached ? (
                        <Poptip
                            isInline
                            trigger="mouseenter"
                            content={"The limit of 5 SSO connections has been reached"}
                            className=""
                        >
                            <Dropdown isDisabled buttonContent={ADD_CONNECTION} width={600} arrowIcon="down"></Dropdown>
                        </Poptip>
                    ) : (
                        <Dropdown
                            isDisabled={connectionLimitReached}
                            buttonContent={selectedConnectionType === NONE ? ADD_CONNECTION : selectedConnectionType}
                            width={600}
                            arrowIcon="down"
                        >
                            <Dropdown.Contents>
                                {CONNECTION_TYPES_ARRAY.map((connection) => {
                                    return (
                                        <Dropdown.ListItem key={connection}>
                                            <Dropdown.BlockLink
                                                onClick={() => {
                                                    setSelectedConnectionType(connection);
                                                }}
                                            >
                                                {connection}
                                            </Dropdown.BlockLink>
                                        </Dropdown.ListItem>
                                    );
                                })}
                            </Dropdown.Contents>
                        </Dropdown>
                    )}

                    {selectedConnectionType !== NONE && (
                        <SSOConnectionSetupFormSheet
                            connectionType={selectedConnectionType}
                            onClose={() => setSelectedConnectionType(NONE)}
                            onSubmit={handleNewConnectionRequest}
                        />
                    )}
                    <div className="push-quad--top push-triple--right">
                        {identityProviders?.map((identityProvider) => {
                            return (
                                <IdentityProviderDisclosure
                                    key={identityProvider.id}
                                    identityProvider={identityProvider as IIdentityProvider}
                                    onRemove={async () => {
                                        setSelectedConnectionType(NONE);
                                        await deleteOrganizationProvider({ id: identityProvider.id });
                                        await refetchOrganization();
                                    }}
                                />
                            );
                        })}
                    </div>
                </LayoutGridCell>
            </LayoutGrid>
        </LayoutGridContainer>
    );
};

SSOSetup.displayName = "SSOSetupPage";
