export const MONTH_MAP: { [key: number]: string } = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
};

export const getFormattedDate = ({ date, hideTimestamp }: { date: Date; hideTimestamp?: boolean }) => {
    if (!date) {
        return null;
    }

    const d = new Date(date.toString());
    const local = d.toLocaleString();
    const timestamp = local.split(", ")[1] || "";

    const monthNumber = d.getUTCMonth() + 1;
    const month: string = MONTH_MAP[monthNumber];
    const day = d.getUTCDate();
    const year = d.getUTCFullYear();
    const mmddyyyy = `${month} ${day}, ${year}`;
    return hideTimestamp ? mmddyyyy : `${mmddyyyy} at ${timestamp}`;
};
