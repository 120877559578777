import { User } from "../../domain/User";
import { useInfiniteList } from "../useInfiniteList/useInfiniteList";
import { DEFAULT_PAGE_SIZE, SWR_KEY_PREFIX } from "../../constants/constants";
import { useServicesContext } from "../../providers/ServicesProvider";

export const useUsersByGroup = ({ userGroupId }: { userGroupId?: string }) => {
    const { userService } = useServicesContext();

    const { allData, loadNext, isLoadingInitialData, isLoadingMore, revalidate } = useInfiniteList<User>(
        userGroupId ? `${SWR_KEY_PREFIX.USERS}/api/users/${userGroupId}` : null,
        (key: string | null, page: number) => {
            return userService.getUsers({
                userGroupIds: [userGroupId!],
                size: DEFAULT_PAGE_SIZE,
                page
            });
        }
    );

    return {
        users: allData,
        loadNext,
        isLoadingInitialData,
        isLoadingMore,
        revalidate
    };
};
