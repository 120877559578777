/* eslint react/style-prop-object: 0 */
import React, { ChangeEvent, useState } from "react";
import { Attention, Button, Sheet, Textarea, Typography } from "@optimizely/axiom";
import { datadogRum } from "@datadog/browser-rum";
import { IdentityProvider } from "../../../../domain/IdentityProvider";
import { emitToast } from "../../../../lib/toaster-utils";

export const SSODomainsSheet = ({
    identityProvider,
    onClose,
    onSave
}: {
    identityProvider: IdentityProvider;
    onClose: () => void;
    onSave: ({ domains }: { domains: string[] }) => Promise<IdentityProvider>;
}) => {
    const { domains } = identityProvider;
    const [domainUrls, setDomainUrls] = useState<string[]>(domains || []);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState("");
    const handleSave = async () => {
        setIsSaving(true);
        setError("");
        try {
            await onSave({ domains: domainUrls });
            onClose();
            emitToast({
                message: "Domains updated successfully."
            });
        } catch (e) {
            if (Array.isArray(e)) {
                const [error] = e as any[];
                datadogRum.addError(error);
                console.error(error);
                setError(error?.message || "An unexpected error occurred.");
            } else {
                datadogRum.addError(e);
                console.error(e);
                setError("An unexpected error occurred.");
            }
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Sheet
            footerButtonList={[
                <Button onClick={onClose} isDisabled={isSaving}>
                    Cancel
                </Button>,
                <Button style="highlight" onClick={handleSave} isDisabled={isSaving} isLoading={isSaving}>
                    Save
                </Button>
            ]}
            onClose={onClose}
            title="SSO Domain Setup"
        >
            {!!error && (
                <Attention type="bad-news" className="push-triple--bottom">
                    {error}
                </Attention>
            )}
            <Typography tag="p" type="caption" className="push-triple--bottom">
                <em>
                    <strong>Note:</strong> PLEASE DO NOT INCLUDE SOCIAL DOMAINS (E.G., gmail.com, hotmail.com, etc.).
                    Users with social domains must be added via User Management in the Admin Center.
                </em>
            </Typography>
            <Textarea
                label={
                    <Typography type="body">
                        Add Domains for <span className="weight--bold">{identityProvider.idpName}</span>
                    </Typography>
                }
                isDisabled={isSaving}
                defaultValue={domainUrls.join(", ")}
                onChange={(e: ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    if (value.trim()) {
                        setDomainUrls(value.split(",").map((u) => u.trim()));
                    } else {
                        setDomainUrls([]);
                    }
                }}
            />
            <Typography type="xs">
                Please provide a comma separated list of domains to add. Must be a valid domain. Please include ".com",
                ".net", ".org", etc...
            </Typography>
        </Sheet>
    );
};

SSODomainsSheet.displayName = "SSODomainsSheet";
