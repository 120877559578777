import { Dropdown, Typography } from "@optimizely/axiom";
import { useProviders } from "../../../hooks/useProviders/useProviders";
import React, { useEffect, useState } from "react";

export const ProviderDropdown = ({
    disabled,
    onChange,
    value
}: {
    disabled?: boolean;
    onChange: any;
    value?: string;
}) => {
    const { providers = [] } = useProviders();
    const [selectedProvider, setSelectedProvider] = useState("Select Provider...");

    useEffect(() => {
        setSelectedProvider(value || "Select Provider...");
    }, [value]);

    return (
        <div className="display--position-relative">
            <Typography type="body" tag="div">
                Provider
                <span aria-label="(required)" className="oui-label--required" />
            </Typography>
            <Dropdown isDisabled={disabled} buttonContent={selectedProvider} width={200} arrowIcon={"down"}>
                <Dropdown.Contents>
                    {providers.map((provider: string) => {
                        return (
                            <Dropdown.ListItem key={provider}>
                                <Dropdown.BlockLink
                                    onClick={() => {
                                        setSelectedProvider(provider);
                                        if (onChange) {
                                            onChange(provider);
                                        }
                                    }}
                                >
                                    {provider}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};

ProviderDropdown.displayName = "ProviderDropdown";
