import React, { useState } from "react";
import { TabNav } from "@optimizely/axiom";

import { UserGroupProducts } from "../UserGroupProducts/UserGroupProducts";
import { UserGroupFormUsers } from "../UserGroupFormUsers/UserGroupFormUsers";

import styles from "./UserGroupFormTabs.module.scss";
import { UserGroupDetails } from "../UserGroupDetails/UserGroupDetails";

export const UserGroupFormTabs = ({ editing, isEveryoneGroup }: { editing?: boolean; isEveryoneGroup?: boolean }) => {
    const [currentTab, setCurrentTab] = useState("users");

    const handleTabClick = ({ event, tab }: { event: React.SyntheticEvent; tab: string }) => {
        event.preventDefault();
        setCurrentTab(tab);
    };

    return (
        <div
            aria-label="Group Form Tabs"
            className={`soft-double--bottom soft-quad--sides ${styles["user-group-form-tabs__container"]}`}
            role="tablist"
        >
            <TabNav activeTab={currentTab} className="push-quad--bottom" style={["dashboard"]}>
                <TabNav.Tab
                    tabId="users"
                    className={styles["user-group-form-tabs__tab"]}
                    onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "users" })}
                >
                    <button
                        className={styles["user-group-form-tabs__button"]}
                        id="users-tab"
                        role="tab"
                        tabIndex={-1}
                        onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "users" })}
                    >
                        Users
                    </button>
                </TabNav.Tab>
                <TabNav.Tab
                    tabId="products"
                    className={styles["user-group-form-tabs__tab"]}
                    onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "products" })}
                >
                    <button
                        className={styles["user-group-form-tabs__button"]}
                        id="products-tab"
                        role="tab"
                        tabIndex={-1}
                        onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "products" })}
                    >
                        Products
                    </button>
                </TabNav.Tab>
                <TabNav.Tab
                    tabId="details"
                    className={styles["user-group-form-tabs__tab"]}
                    onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "details" })}
                >
                    <button
                        className={styles["user-group-form-tabs__button"]}
                        id="details-tab"
                        role="tab"
                        tabIndex={-1}
                        onClick={(event: React.SyntheticEvent) => handleTabClick({ event, tab: "details" })}
                    >
                        Details
                    </button>
                </TabNav.Tab>
            </TabNav>
            {currentTab === "users" && <UserGroupFormUsers editing={editing && !isEveryoneGroup} />}
            {currentTab === "products" && <UserGroupProducts editing={editing} />}
            {currentTab === "details" && <UserGroupDetails editing={editing && !isEveryoneGroup} />}
        </div>
    );
};

UserGroupFormTabs.displayName = "UserGroupFormTabs";
