import React, { useEffect, useState } from "react";
import { Dropdown, Radio, Typography } from "@optimizely/axiom";
import { Role } from "../../../domain/Role";
import { SearchInput } from "../SearchInput/SearchInput";

export const DuplicateRole = ({
    onChange,
    roles
}: {
    onChange: ({ role }: { role?: Role }) => void;
    roles: Role[];
}) => {
    const [duplicate, setDuplicate] = useState<boolean>(false);
    const [role, setRole] = useState<Role | undefined>(undefined);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const filteredRoles = roles.filter((r) => r.name.toLowerCase().includes(filterQuery.toLowerCase()));

    useEffect(() => {
        onChange({ role });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    useEffect(() => {
        // clear selections when toggling duplicate option.
        setRole(undefined);
        onChange({ role: undefined });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicate]);

    const handleRoleSelection = ({ role: selectedRole }: { role: Role }) => {
        setRole(selectedRole);
    };

    return (
        <>
            <Typography type="header4">
                <span style={{ fontWeight: 300 }}>
                    Duplicate Role <span aria-label="(required)" className="oui-label--required" />
                </span>
            </Typography>
            <Radio name="No" label="No" checked={!duplicate} onChange={() => setDuplicate(false)} />
            <Radio name="Yes" label="Yes" checked={duplicate} onChange={() => setDuplicate(true)} />
            <div className="push--top push--bottom">You can modify the values of the role after duplicating.</div>
            <Dropdown
                arrowIcon={"down"}
                className="admin-center__form-dropdown"
                buttonContent={role?.name || "Select role to duplicate..."}
                fullWidth
                isDisabled={!duplicate}
                shouldHideChildrenOnClick={false}
            >
                {({ handleHideChildren }: { handleHideChildren: any }) => (
                    <>
                        <div className="background--white" style={{ padding: "10px" }}>
                            <SearchInput
                                placeholder="Search roles..."
                                onChange={({ value }) => {
                                    setFilterQuery(value);
                                }}
                                value={filterQuery}
                            />
                        </div>
                        <Dropdown.Contents style={{ marginTop: 0 }}>
                            {filteredRoles.map((r) => {
                                return (
                                    <Dropdown.ListItem key={r.id}>
                                        <Dropdown.BlockLink
                                            onClick={() => {
                                                handleRoleSelection({ role: r });
                                                handleHideChildren();
                                            }}
                                        >
                                            {r.name}
                                        </Dropdown.BlockLink>
                                    </Dropdown.ListItem>
                                );
                            })}
                        </Dropdown.Contents>
                    </>
                )}
            </Dropdown>
        </>
    );
};

DuplicateRole.displayName = "DuplicateRole";
