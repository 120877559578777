import { IdentityProvider } from "../../../../domain/IdentityProvider";
import { Button, Disclose, Typography } from "@optimizely/axiom";
import React from "react";

export const DomainsDisclosure = ({
    identityProvider,
    onEdit
}: {
    identityProvider: IdentityProvider;
    onEdit: () => void;
}) => {
    const { domains = [] } = identityProvider;
    return (
        <Disclose title={identityProvider.idpName}>
            <div className="flex flex--column push-triple--left">
                <Typography type="caption">Domains</Typography>
                <Typography type="xs">
                    <span style={{ color: "#707070" }}>{domains?.join(", ")}</span>
                </Typography>
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button className="push-triple--top min-width--150 max-width--150" style="outline" onClick={onEdit}>
                    Edit Domains
                </Button>
            </div>
        </Disclose>
    );
};

DomainsDisclosure.displayName = "DomainsDisclosure";
