import { useOktaAuth } from "@okta/okta-react";
import useSWR from "swr";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useCallback, useEffect } from "react";

export const useSession = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();

    const { data: user, error: oktaUserError } = useSWR(
        accessToken ?? `/okta/session/${accessToken}`,
        () => {
            return oktaAuth.getUser();
        },
        //revalidate on focus (tab switch back), reconnection, and every five minutes until not valid
        { revalidateOnFocus: true, revalidateOnReconnect: true, refreshInterval: 300000 }
    );

    const signIn = useCallback(async () => {
        oktaAuth.setOriginalUri(toRelativeUrl(window.location.href, window.location.origin));
        await oktaAuth.signInWithRedirect();
    }, [oktaAuth]);

    const signOut = useCallback(async () => {
        await oktaAuth.signOut();
        oktaAuth.tokenManager.clear();
    }, [oktaAuth]);

    const oktaClientIssuer = oktaAuth.getIssuerOrigin();
    const accessTokenIssuer = oktaAuth.authStateManager.getAuthState()?.accessToken?.claims.iss;

    const isLoading =
        (!oktaUserError && !user) ||
        !authState ||
        (authState?.isAuthenticated && !accessTokenIssuer?.includes(oktaClientIssuer));

    if (authState?.isAuthenticated && !accessTokenIssuer?.includes(oktaClientIssuer)) {
        oktaAuth
            .closeSession()
            .then((sessionClosed) => {
                if (sessionClosed) {
                    window.location.reload();
                }
            })
            .catch(() => signIn());
    }

    useEffect(() => {
        if (!accessToken && !isLoading && !user) {
            const refreshToken = oktaAuth.getRefreshToken();
            if (refreshToken) {
                oktaAuth.token.renewTokens().then((renewToken) => {
                    oktaAuth.tokenManager.setTokens(renewToken);
                });
            } else {
                signIn();
            }
        }
    }, [accessToken, user, isLoading, oktaAuth, signIn]);

    return {
        user,
        oktaAuth,
        isAuthenticated: authState?.isAuthenticated,
        accessToken,
        signIn,
        signOut,
        error: oktaUserError ?? null,
        isLoading
    };
};
