export class Invitation {
    constructor({
        id,
        createdBy,
        sentDates,
        created,
        modified,
        firstName,
        lastName,
        email,
        organizationId,
        organizationName,
        requireAcceptance = false,
        acceptedDate,
        revokedDate,
        expiredDate,
        status,
        userGroups
    }: {
        id?: string;
        createdBy: string;
        sentDates?: Date[];
        created: Date;
        modified: Date;
        firstName: string;
        lastName: string;
        email: string;
        organizationId: string;
        organizationName?: string;
        requireAcceptance?: boolean;
        acceptedDate?: Date;
        revokedDate?: Date;
        expiredDate?: Date;
        status?: string;
        userGroups: { id: string; name: string }[];
    }) {
        this.id = id;
        this.createdBy = createdBy;
        this.sentDates = sentDates || [];
        this.created = created;
        this.modified = modified;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.organizationId = organizationId;
        this.organizationName = organizationName;
        this.requireAcceptance = requireAcceptance;
        this.revokedDate = revokedDate;
        this.expiredDate = expiredDate;
        this.acceptedDate = acceptedDate;
        this.status = status;
        this.userGroups = userGroups || [];
    }

    id: string | undefined;
    createdBy: string;
    sentDates: Date[];
    created: Date;
    modified: Date;
    firstName: string;
    lastName: string;
    email: string;
    organizationId: string;
    organizationName?: string;
    requireAcceptance: boolean;
    revokedDate?: Date;
    acceptedDate?: Date;
    expiredDate?: Date;
    status: string | undefined;
    userGroups: { id: string; name: string }[];
}
