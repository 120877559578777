import { NavigationBarSettingsSubMenu } from "../ui/components/NavigationBar/NavigationBarSettingsSubMenu";

const SECONDARY_NAVIGATION_PATH_MAPPINGS = [{ path: "/settings/", component: NavigationBarSettingsSubMenu }];
export const getSecondaryNavigation = ({ path }: { path: string }): null | (() => JSX.Element | null) => {
    const submenu = SECONDARY_NAVIGATION_PATH_MAPPINGS.find((mapping) =>
        isChildPath({ pathname: path, path: mapping.path })
    )?.component;
    return submenu || null;
};

export const isChildPath = ({ pathname, path }: { pathname: string; path: string }) => {
    return pathname.startsWith(path);
};
