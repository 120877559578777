import React from "react";
import { Table } from "@optimizely/axiom";
import { ProductInstancesTableRow } from "./ProductInstancesTableRow";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { LoadingTable } from "../LoadingIndicator/LoadingTable";

type ProductInstancesTableProps = {
    instances?: IOrganizationProductInstance[];
    loading?: boolean;
    onInstanceClick?: ({ instance }: { instance: IOrganizationProductInstance }) => void;
    accessManagementEnabled?: boolean;
    productId?: string;
};
export const ProductInstancesTable = ({
    instances,
    loading,
    onInstanceClick,
    accessManagementEnabled,
    productId
}: ProductInstancesTableProps) => {
    if (loading) {
        return <LoadingTable headers={["Instances", "Region", "Technical Contact"]} />;
    }

    const showSidebarForInstance = ({ instance }: { instance: IOrganizationProductInstance }) => {
        onInstanceClick?.({ instance });
    };
    return (
        <Table
            className="push-double--bottom"
            density="loose"
            // eslint-disable-next-line react/style-prop-object
            style="rule-no-bottom-border"
        >
            <Table.THead>
                <Table.TR>
                    <Table.TH width="30%">Instances</Table.TH>
                    <Table.TH width="30%">Region</Table.TH>
                    <Table.TH width="30%">Technical Contact</Table.TH>
                </Table.TR>
            </Table.THead>
            <Table.TBody>
                {instances?.map((instance, instanceIndex) => (
                    <ProductInstancesTableRow
                        key={instance.id || instanceIndex}
                        instance={instance}
                        onInstanceClick={() => showSidebarForInstance({ instance })}
                        accessManagementEnabled={accessManagementEnabled}
                        productId={productId}
                    />
                ))}
            </Table.TBody>
        </Table>
    );
};

ProductInstancesTable.displayName = "ProductInstancesTable";
