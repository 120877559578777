import React from "react";
import { NavLink } from "react-router-dom";
import "./NavLinks.css";

interface Links {
    title: string;
    route: string;
}

export const NavLinks = ({ links }: { links: Links[] }) => {
    return (
        <div className="nav-links">
            {(links || []).map((link, linkIndex) => {
                return (
                    <NavLink
                        key={linkIndex}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to={link.route}
                        end
                    >
                        {link.title}
                    </NavLink>
                );
            })}
        </div>
    );
};

NavLinks.diplayName = "NavLinks";
