import { Dropdown, Table } from "@optimizely/axiom";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { NavLink } from "react-router-dom";
import { MoreMenu } from "../MoreMenu/MoreMenu";

type ProductInstancesTableRowProps = {
    highlighted?: boolean;
    instance: IOrganizationProductInstance;
    onInstanceClick?: () => void;
    accessManagementEnabled?: boolean;
    productId?: string;
};

export const ProductInstancesTableRow = ({
    highlighted = false,
    instance,
    onInstanceClick,
    accessManagementEnabled,
    productId
}: ProductInstancesTableRowProps) => {
    const { name: instanceName, nickname, id, region, technicalContactEmail, technicalContactName } = instance;
    const name = nickname || instanceName;
    const technicalContact = technicalContactEmail || technicalContactName || "N/A";
    const isExpProduct = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
    const href = `/access/products/${productId}/instances/${id}/${isExpProduct ? "projects" : "users"}`;

    return (
        <Table.TR isHighlighted={highlighted}>
            <Table.TD>
                {accessManagementEnabled ? (
                    <NavLink to={href} className="link button-as-link" type="button">
                        {name}
                    </NavLink>
                ) : (
                    <button className="link button-as-link" type="button" onClick={() => onInstanceClick?.()}>
                        {name}
                    </button>
                )}
            </Table.TD>
            <Table.TD>{region}</Table.TD>
            <Table.TD>{technicalContact}</Table.TD>
            {accessManagementEnabled && (
                <Table.TD className="user-manager__table--more-menu">
                    <MoreMenu>
                        <Dropdown.ListItem>
                            <Dropdown.BlockLink>
                                <NavLink to={href} type="button">
                                    {isExpProduct ? "View Projects" : "View Users"}
                                </NavLink>
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    </MoreMenu>
                </Table.TD>
            )}
            <Table.TD></Table.TD>
        </Table.TR>
    );
};

ProductInstancesTableRow.displayName = "ProductInstancesTableRow";
