/* eslint-disable react/style-prop-object */
import React from "react";
import { Attention, Button, DialogNew } from "@optimizely/axiom";

type ConfirmationDialogProps = {
    additionalActions?: React.ReactNode;
    children?: string | React.ReactNode | React.ReactNode[];
    callToActionText?: string;
    error?: string | undefined | null;
    loading?: boolean;
    onCancel: () => void;
    onConfirmation: () => void;
    style?: string;
    title?: string;
    subtitle?: string | React.ReactNode | React.ReactNode[];
};

export const ConfirmationDialog = ({
    additionalActions,
    children,
    callToActionText = "Confirm",
    error,
    loading,
    onCancel,
    onConfirmation,
    style = "danger",
    title = "Confirm",
    subtitle
}: ConfirmationDialogProps) => {
    let subtitleContent = Array.isArray(subtitle) ? subtitle : [subtitle].filter((e) => !!e);

    if (error) {
        subtitleContent = [
            ...subtitleContent,
            <Attention type="bad-news" key={0}>
                {error}
            </Attention>
        ];
    }
    return (
        <DialogNew
            footerButtonList={[
                <div key={2} style={{ display: "inline-flex", position: "absolute", left: "16px" }}>
                    {additionalActions && additionalActions}
                </div>,
                <Button key={0} isDisabled={loading} style="plain" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key={1}
                    isDisabled={loading || !!error}
                    isLoading={loading}
                    loadingText={callToActionText}
                    style={style}
                    onClick={onConfirmation}
                >
                    {callToActionText}
                </Button>
            ]}
            subtitle={subtitleContent}
            title={title}
            hasCloseButton
            hasOverlay
            onClose={onCancel}
        >
            <div className="push--bottom">{children}</div>
        </DialogNew>
    );
};

ConfirmationDialog.displayName = "ConfirmationDialog";
