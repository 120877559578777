import { IdentityProvider } from "../../../../domain/IdentityProvider";
import { Dropdown } from "@optimizely/axiom";
import React from "react";

type IdentityProviderDropdownProps = {
    disabled?: boolean;
    identityProviders: IdentityProvider[];
    onChange: ({ identityProvider }: { identityProvider: IdentityProvider }) => void;
};

export const IdentityProviderDropdown = ({
    disabled = false,
    identityProviders,
    onChange
}: IdentityProviderDropdownProps) => {
    return (
        <Dropdown
            buttonContent={"Add SSO Domains"}
            width={600}
            arrowIcon="down"
            isDisabled={disabled || !identityProviders.length}
        >
            <Dropdown.Contents>
                {identityProviders.map((idp) => {
                    return (
                        <Dropdown.ListItem key={idp.id}>
                            <Dropdown.BlockLink
                                onClick={() => {
                                    onChange({ identityProvider: idp });
                                }}
                            >
                                {idp.idpName}
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

IdentityProviderDropdown.displayName = "IdentityProviderDropdown";
