import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { Role } from "../../../domain/Role";
import React, { useCallback, useEffect, useState } from "react";
import { Disclose, Typography } from "@optimizely/axiom";

const ProjectList = ({ projects }: { projects: any[] }) => {
    return (
        <Typography type="body" className="label--disabled">
            {projects?.map((project) => {
                return <div key={project.key}>{project.name}</div>;
            })}
        </Typography>
    );
};

export const ExperimentationProjectList = ({ role }: { role: Role }) => {
    const { getExperimentationProjects } = useAttributes({});
    const [projects, setProjects] = useState<any[]>([]);

    const getProjects = useCallback(async () => {
        const results = await getExperimentationProjects({
            instanceId: role.targetIds[0],
            permissionType: "Editor",
            projectKeys: role?.attributes?.map((attr) => attr.key)
        });
        setProjects(results);
    }, [getExperimentationProjects, role]);

    useEffect(() => {
        getProjects();
    }, [getProjects, role]);

    if (projects.length > 10) {
        return (
            <Disclose title="Projects">
                <ProjectList projects={projects} />
            </Disclose>
        );
    }
    return (
        <>
            <span className="oui-label push--top">Projects</span>
            <ProjectList projects={projects} />
        </>
    );
};
