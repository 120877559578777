import { ConnectionNameInput } from "../../ConnectionNameInput/ConnectionNameInput";
import React, { useEffect, useReducer, useState } from "react";
import { ProviderDropdown } from "../../../ProviderDropdown/ProviderDropdown";
import { IOIDCIdp, OIDCIdpWithoutId } from "../../../../../domain/IdentityProvider";
import { Typography } from "@optimizely/axiom";
import { AuthorizationURLField } from "./AuthorizationURLField";
import { IssuerURLField } from "./IssuerURLField";
import { JsonWebKeySetURLField } from "./JsonWebKeySetURLField";
import { TokenURLField } from "./TokenURLField";
import { WellKnownMetadataURLField } from "./WellKnownMetadataURLField";
import { ClientIdField } from "./ClientIdField";
import { ClientSecretField } from "./ClientSecretField";
import { UserInfoURLField } from "./UserInfoURLField";

export const emptyForm = {
    authorizationUrl: "",
    idpName: "",
    clientId: "",
    clientSecret: "",
    issuerUrl: "",
    jwksUrl: "",
    organizationId: "",
    provider: "",
    tokenUrl: "",
    type: "OIDC",
    userInfoUrl: "",
    wellKnownUrl: ""
};

const fieldReducer = (state: OIDCIdpWithoutId, changes: { [key: string]: any }) => {
    return {
        ...state,
        ...changes
    };
};

type SSOOIDCFormFieldProps = {
    disabled?: boolean;
    onChange?: (values: OIDCIdpWithoutId) => void;
    idp?: IOIDCIdp;
};

export const SSOOIDCFormFields = ({ disabled, idp, onChange }: SSOOIDCFormFieldProps) => {
    const [values, updateValues] = useReducer<
        (state: OIDCIdpWithoutId, changes: { [key: string]: any }) => OIDCIdpWithoutId
    >(fieldReducer, {
        ...emptyForm,
        ...(idp || {}),
        type: "OIDC"
    });
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const handleRequiredField = ({ name, value }: { name: string; value: any }) => {
        if (!value) {
            setErrors({
                ...errors,
                [name]: true
            });
        } else {
            const updatedErrors = { ...errors };
            delete updatedErrors[name];
            setErrors(updatedErrors);
        }
        updateValues({ [name]: value });
    };

    const handleWellKnownChange = (value?: string) => {
        if (!values.authorizationUrl) {
            handleRequiredField({ name: "wellKnownUrl", value });
        } else {
            updateValues({ wellKnownUrl: value });
        }
    };

    const hasManualValues = ["authorizationUrl", "issuerUrl", "jwksUrl", "tokenUrl", "userInfoUrl"].find(
        (val) => !!values[val as keyof OIDCIdpWithoutId]
    );

    useEffect(() => {
        onChange && onChange(values);
    }, [onChange, values]);

    return (
        <>
            <ConnectionNameInput
                onChange={(value) => handleRequiredField({ name: "idpName", value })}
                disabled={disabled}
                value={values.idpName}
            />
            <ProviderDropdown
                onChange={(value: string) => updateValues({ provider: value })}
                value={values.provider}
                disabled={disabled}
            />
            {!disabled && (
                <>
                    <ClientIdField onChange={(value) => handleRequiredField({ name: "clientId", value })} required />
                    <ClientSecretField
                        onChange={(value) => handleRequiredField({ name: "clientSecret", value })}
                        required
                    />
                </>
            )}
            {!disabled && (
                <Typography type="subhead" tag="div" className="push-triple--top">
                    AND
                </Typography>
            )}
            {(!disabled || idp?.wellKnownUrl) && (
                <WellKnownMetadataURLField onChange={handleWellKnownChange} disabled={!!hasManualValues} />
            )}
            {!disabled && (
                <>
                    <Typography type="subhead" tag="div" className="push-triple--top">
                        OR
                    </Typography>
                </>
            )}
            <AuthorizationURLField
                value={values.authorizationUrl}
                onChange={(value) => updateValues({ authorizationUrl: value })}
                disabled={!!values.wellKnownUrl || !!idp?.authorizationUrl || disabled}
            />
            <IssuerURLField
                value={values.issuerUrl || ""}
                onChange={(value) => updateValues({ issuerUrl: value })}
                disabled={!!values.wellKnownUrl || !!idp?.issuerUrl || disabled}
                hideHelperText={!!values.wellKnownUrl || !!idp?.issuerUrl}
            />
            <JsonWebKeySetURLField
                value={values.jwksUrl}
                onChange={(value) => updateValues({ jwksUrl: value })}
                disabled={!!values.wellKnownUrl || !!idp?.jwksUrl || disabled}
            />
            <TokenURLField
                value={values.tokenUrl}
                onChange={(value) => updateValues({ tokenUrl: value })}
                disabled={!!values.wellKnownUrl || !!idp?.tokenUrl || disabled}
            />
            <UserInfoURLField
                onChange={(value) => updateValues({ userInfoUrl: value })}
                disabled={!!values.wellKnownUrl || !!idp?.userInfoUrl || disabled}
                value={values.userInfoUrl}
            />
        </>
    );
};

SSOOIDCFormFields.displayName = "SSOOIDCFormFields";
