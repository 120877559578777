import React, { BaseSyntheticEvent, useRef, useState } from "react";
import { Button, Typography } from "@optimizely/axiom";

interface IUploadButtonProps {
    className?: string;
    disabled?: boolean;
    children?: any;
    onSelection: (file: File) => void;
    extensions?: string;
}

export const UploadButton = ({
    className,
    disabled = false,
    onSelection,
    children = "Select a File",
    extensions
}: IUploadButtonProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const triggerFileClick = () => {
        const button = inputRef.current;

        if (button) {
            button.click();
        }
    };

    const handleFileSelection = (e: BaseSyntheticEvent) => {
        const [file] = e.target.files;
        const { name } = file;
        setFileName(name);
        onSelection(file);
    };

    return (
        <>
            <input
                ref={inputRef}
                accept={extensions}
                style={{ position: "absolute", left: "-1000px" }}
                type="file"
                onChange={handleFileSelection}
            />
            <div className="flex flex-align--center">
                <Button
                    // eslint-disable-next-line react/style-prop-object
                    style="outline"
                    className={className}
                    isDisabled={disabled}
                    onClick={triggerFileClick}
                >
                    {children}
                </Button>
                <Typography className="push--left" type="body">
                    {fileName}
                </Typography>
            </div>
        </>
    );
};
