import React from "react";
import { Table } from "@optimizely/axiom";

export const ActivationStatusesTable = () => {
    return (
        // eslint-disable-next-line
        <Table className="push-double--bottom" density="loose" style="rule-no-bottom-border">
            <Table.THead>
                <Table.TR>
                    <Table.TH width="35%">Status</Table.TH>
                    <Table.TH>Description</Table.TH>
                </Table.TR>
            </Table.THead>
            <Table.TBody>
                <Table.TR>
                    <Table.TD>Staged</Table.TD>
                    <Table.TD>
                        Accounts have a staged status when they are first created, before the activation flow is
                        initiated, or if there is a pending admin action.
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Pending User Action</Table.TD>
                    <Table.TD>
                        Accounts have a provisioned status when they are provisioned, but the user has not provided
                        verification by clicking through the activation email or provided a password
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Active</Table.TD>
                    <Table.TD>
                        Accounts have an active status when:
                        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                            <li>
                                An admin adds a user (Add person) on the People page and sets the user password without
                                requiring email verification.
                            </li>
                            <li>
                                An admin adds a user (Add person) on the People page, sets the user password, and
                                requires the user to set their password when they first sign-in.
                            </li>
                            <li>
                                A user self-registers into a custom app or the Okta Homepage and email verification is
                                not required.
                            </li>
                            <li>An admin explicitly activate user accounts.</li>
                        </ul>
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Password Reset</Table.TD>
                    <Table.TD>
                        Accounts have a recovery status when a user requests a password reset or an admin initiates one
                        on their behalf.
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Password Expired</Table.TD>
                    <Table.TD>
                        Accounts have a password expired status when the password has expired and the account requires
                        an update to the password before a user is granted access to applications.
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Locked Out</Table.TD>
                    <Table.TD>
                        Accounts have a locked out status when the user exceeds the number of login attempts defined in
                        the login policy.
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Suspended</Table.TD>
                    <Table.TD>
                        Accounts have a suspended status when an admin explicitly suspends them. The user cannot access
                        applications, the Admin Console, or the Okta End-User Dashboard. Application assignments are
                        unaffected and the user profile can be updated.
                    </Table.TD>
                </Table.TR>
                <Table.TR>
                    <Table.TD>Deactivated</Table.TD>
                    <Table.TD>
                        Accounts have a deprovisioned status when an admin explicitly deactivates or deprovisions them.
                        All application assignments are removed and the password is permanently deleted.
                    </Table.TD>
                </Table.TR>
            </Table.TBody>
        </Table>
    );
};

ActivationStatusesTable.displayName = "ActivationStatusesTable";
