import React, { Dispatch, SetStateAction, useState } from "react";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { Attention, Button, Typography } from "@optimizely/axiom";
import { useForm } from "react-hook-form";
import { Customer } from "../../../domain/Customer";
import { Invitation } from "../../../domain/Invitation";
import { CustomerSelectionDropdown } from "../CustomerSelectionDropdown/CustomerSelectionDropdown";
import { emitToast } from "../../../lib/toaster-utils";

export interface ISupportFormValues {
    organization: string;
}

type SupportAccessFormProps = {
    invitation?: Invitation | null;
    onClose: () => void;
    onOrgSelect: Dispatch<SetStateAction<string>>;
    onSubmit: ({ invitation, organizationId }: { invitation?: Invitation | null; organizationId?: string }) => any;
    isLoading: boolean;
};
export const SupportAccessForm = ({
    invitation,
    onClose,
    onOrgSelect,
    onSubmit,
    isLoading
}: SupportAccessFormProps) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { register, handleSubmit, getValues, setValue } = useForm<ISupportFormValues>({
        mode: "onChange"
    });

    const handleOrganizationSelection = ({ customer }: { customer: Customer }) => {
        onOrgSelect(customer.id);
        setValue("organization", customer.name);
    };

    const handleFormSubmission = () => {
        setSaving(true);
        if (!!invitation) {
            onSubmit({ invitation });
            return;
        }
        onSubmit({ invitation })
            .then(() => {
                const refetchEvent = new Event("@opti-product-switcher:refetch");
                window.dispatchEvent(refetchEvent);
                emitToast({ message: "Organization access has been created." });
                onClose();
            })
            .catch((error: any) => {
                let errorMessage = "Organization access is not able to be added at this time. Please try again later.";
                if (Array.isArray(error)) {
                    errorMessage = error[0]?.message || errorMessage;
                } else {
                    errorMessage = error?.message || errorMessage;
                }
                console.error(error);
                setError(errorMessage);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmission)}>
            <div className="push-quad--sides">
                {!!error && (
                    <Attention alignment="left" className="push--top push--bottom" type="bad-news">
                        {error}
                    </Attention>
                )}

                {!invitation && (
                    <Typography tag="div" type="body" className="push-double--bottom">
                        Support an organization by adding yourself to their Admin Center.
                    </Typography>
                )}

                <div className="flex flex--column">
                    <div>Organization</div>
                    {!!invitation && (
                        <Typography type="body" className="label--disabled">
                            {invitation?.organizationName}
                        </Typography>
                    )}
                    {!invitation && (
                        <CustomerSelectionDropdown
                            className="push-double--top"
                            fullWidth
                            register={register}
                            onSelect={handleOrganizationSelection}
                        />
                    )}
                    {!!invitation && (
                        <>
                            <div className="push-double--top">Created</div>
                            <Typography type="body" className="label--disabled">
                                {new Date(invitation?.created).toUTCString()}
                            </Typography>
                        </>
                    )}
                </div>
            </div>
            <SidebarFooter onCancel={onClose}>
                {!invitation ? (
                    <Button
                        key="save-user-button"
                        isLoading={saving}
                        isDisabled={isLoading || !getValues("organization")}
                        loadingText="Saving"
                        /* eslint-disable-next-line react/style-prop-object */
                        style="highlight"
                        isSubmit
                    >
                        Save
                    </Button>
                ) : (
                    <Button
                        key="remove-access-button"
                        loadingText="Loading"
                        /* eslint-disable-next-line react/style-prop-object */
                        style="danger"
                        isSubmit
                    >
                        Remove access...
                    </Button>
                )}
            </SidebarFooter>
        </form>
    );
};

SupportAccessForm.displayName = "SupportAccessForm";
