import { ADMINCENTER_ITEM_UPDATED_EVENT_NAME } from "../../constants/constants";
import { ChangeEventData } from "../../lib/sync-updaters";

export const useUpdateSync = () => {
    const fireChangeEvent = ({ type, data }: { type: string; data: ChangeEventData }) => {
        window.dispatchEvent(
            new CustomEvent(ADMINCENTER_ITEM_UPDATED_EVENT_NAME, {
                detail: {
                    type,
                    data
                }
            })
        );
    };

    return {
        fireChangeEvent
    };
};
