import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

// import Axiom styles first so we can override them with the same level of specificity
import "@optimizely/axiom/dist/styles.css";
import "@optimizely/design-tokens/dist/css/colors.css";
import "@optimizely/design-tokens/dist/css/typography.css";
import "./axiom-overrides.scss";
import { App } from "./App";

const container = document.getElementById("root");

if (process.env.REACT_APP_MSW_ENABLED === "true") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require("./mocks/browser");
    worker.start();
}

const params = new URLSearchParams(window.location.search);

const debug = params.get("debug");

if (debug) {
    console.log("User Management Version:", process.env.REACT_APP_DEPLOYMENT_VERSION);
}

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
