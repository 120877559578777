import React, { createContext, useCallback, useContext, useRef, useState } from "react";
import {
    CUSTOM_GROUP_ACCESS_ACTION,
    UNSAVED_CHANGES_CONFIRMATION_DO_NOT_SHOW_AGAIN
} from "../../../../constants/constants";
import { AccessDetailsByProductId } from "../AccessDetailsTable/AccessDetailsTable";
import { UserGroup } from "../../../../domain/UserGroup";

export type AvailableFields = null | "name" | "something";

export type CustomGroupAccess = {
    action?: `${CUSTOM_GROUP_ACCESS_ACTION}`;
    id?: string;
    name?: string;
    userGroup?: UserGroup;
    accessDetails?: AccessDetailsByProductId;
};

interface AccessFlowUserFormEditingContextType {
    activeField: null | { name: AvailableFields; hasChanged: boolean }; // add alternatives
    loading: boolean;
    onConfirm: () => void;
    setActiveField: ({ name, hasChanged }: { hasChanged?: boolean; name: null | string }) => void;
    setLoadingState: (loading: boolean) => void;
    setShowConfirmation: (show: boolean) => void;
    setUpdateCustomGroupAccess: React.Dispatch<React.SetStateAction<CustomGroupAccess | undefined>>;
    setUserGroupIds: (userGroups: string[]) => void;
    showConfirmation: boolean;
    updateCustomGroupAccess: CustomGroupAccess | undefined;
    userGroupIds: string[];
}

// Create a context for the form editing state
const AccessFlowUserFormEditingContext = createContext<AccessFlowUserFormEditingContextType>({
    activeField: null,
    loading: false,
    onConfirm: () => null,
    setActiveField: () => null,
    setLoadingState: () => null,
    setShowConfirmation: () => null,
    setUpdateCustomGroupAccess: () => null,
    setUserGroupIds: () => null,
    showConfirmation: false,
    updateCustomGroupAccess: undefined,
    userGroupIds: []
});

// Create a custom hook to use the form editing context
export const useAccessFlowUserFormContext = () => useContext(AccessFlowUserFormEditingContext);

// Define the provider component
export const AccessManagementUserFormProvider = ({ children }: { children: React.ReactNode }) => {
    const [activeField, setActiveFormPart] = useState<{ name: AvailableFields; hasChanged: boolean } | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    // State to track the loading state
    const [loading, setLoading] = useState(false);
    const [userGroupIds, setUserGroupIds] = useState<string[]>([]);
    const [updateCustomGroupAccess, setUpdateCustomGroupAccess] = useState<CustomGroupAccess | undefined>(undefined);
    const nextField = useRef<AvailableFields | null>(null);

    // Function to set the active part of the form
    const setActiveField = useCallback(
        ({ name, hasChanged }: { name: null | string; hasChanged?: boolean }) => {
            const disabledConfirm = localStorage.getItem(UNSAVED_CHANGES_CONFIRMATION_DO_NOT_SHOW_AGAIN);
            if (name !== activeField?.name && hasChanged !== false && activeField?.hasChanged && !disabledConfirm) {
                nextField.current = name as AvailableFields;
                setShowConfirmation(true);
            } else {
                setActiveFormPart({ name: name as AvailableFields, hasChanged: !!hasChanged });
            }
        },
        [activeField?.hasChanged, activeField?.name]
    );

    const onConfirm = useCallback(() => {
        const discardChangesEvent = new CustomEvent(`confirmation-dialog:${activeField!.name}:discard-changes`, {
            detail: "User chose to discard changes."
        });
        setActiveFormPart({ name: nextField.current, hasChanged: false });
        window.document.dispatchEvent(discardChangesEvent);
        nextField.current = null;
        setShowConfirmation(false);
    }, [activeField]);

    // Function to set the loading state
    const setLoadingState = (isLoading: boolean) => {
        setLoading(isLoading);
    };

    // Memoized context value to prevent unnecessary re-renders
    const contextValue = React.useMemo(
        () => ({
            activeField,
            loading,
            onConfirm,
            setActiveField,
            setLoadingState,
            setShowConfirmation,
            setUpdateCustomGroupAccess,
            setUserGroupIds,
            showConfirmation,
            updateCustomGroupAccess,
            userGroupIds
        }),
        [
            activeField,
            loading,
            userGroupIds,
            onConfirm,
            setActiveField,
            showConfirmation,
            updateCustomGroupAccess,
            setUpdateCustomGroupAccess
        ]
    );

    // Render the provider with the children components and provide the context value
    return (
        <AccessFlowUserFormEditingContext.Provider value={contextValue}>
            {children}
        </AccessFlowUserFormEditingContext.Provider>
    );
};
