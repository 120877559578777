import React from "react";
import { Dropdown, Icon } from "@optimizely/axiom";

import styles from "./more-menu.module.scss";

export const MoreMenu = ({ children }: { children: any }) => {
    return (
        <Dropdown
            buttonContent={<Icon name="ellipsis-solid" size="small" />}
            className={styles["more-menu"]}
            testSection="more-menu"
        >
            <Dropdown.Contents>{children}</Dropdown.Contents>
        </Dropdown>
    );
};

MoreMenu.displayName = "MoreMenu";
