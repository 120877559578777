/* eslint-disable react/style-prop-object */
import React from "react";
import { PaginationControls, Table } from "@optimizely/axiom";
import { Role } from "../../../domain/Role";
import { RolesTableRow } from "./RolesTableRow";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";
import { ProductInstance } from "../../../domain/ProductInstance";

type RoleTableProps = {
    instances?: ProductInstance[];
    onDeleteClick: ({ role }: { role: Role }) => void;
    onRoleClick: ({ role }: { role: Role }) => void;
    onPagination?: Function;
    page?: number;
    pageSize?: number;
    products?: OrganizationProduct[];
    roles: Role[];
    total?: number;
};

export const RolesTable = (props: RoleTableProps) => {
    const {
        instances,
        onPagination,
        onDeleteClick,
        onRoleClick,
        page = 1,
        pageSize = 1,
        products,
        roles,
        total = 1
    } = props;

    const handlePagination = (page: number) => {
        if (onPagination) {
            onPagination({ page });
        }
    };

    if (!(roles || []).length) {
        return null;
    }

    const totalPages = Math.ceil(total / pageSize);
    const showPagination = totalPages >= 2;

    return (
        <>
            <div className="user-manager__table">
                <Table className="push-double--bottom" density="loose" style="rule-no-bottom-border">
                    <Table.THead>
                        <Table.TR>
                            <Table.TH width="30%">Name</Table.TH>
                            <Table.TH width="10%">Type</Table.TH>
                            <Table.TH width="15%">Product</Table.TH>
                            <Table.TH width="30%">Instance</Table.TH>
                            <Table.TH width="15%">Modified</Table.TH>
                            <Table.TH width="60px" />
                        </Table.TR>
                    </Table.THead>
                    <Table.TBody>
                        {roles?.map((role) => {
                            const { name: productName = "" } = products?.find((p) => p.id === role.productId) || {};
                            const roleInstances =
                                role.roleType === "ProductDefined"
                                    ? "All"
                                    : role.targetIds.reduce((instanceNames: string, targetId) => {
                                          const matchingInstance: ProductInstance | undefined = instances?.find(
                                              (i: any) => i.instanceId === targetId
                                          );
                                          const name = matchingInstance?.nickname || matchingInstance?.instanceName;
                                          if (name) {
                                              return instanceNames ? instanceNames + ", " + name : name;
                                          }
                                          return instanceNames;
                                      }, "");
                            return (
                                <RolesTableRow
                                    key={role.id}
                                    role={role}
                                    roleInstances={roleInstances}
                                    productName={productName}
                                    onDeleteClick={onDeleteClick}
                                    onRoleClick={onRoleClick}
                                />
                            );
                        })}
                    </Table.TBody>
                </Table>
            </div>
            {showPagination && (
                <PaginationControls
                    className="anchor--bottom"
                    currentPage={page}
                    goToPage={handlePagination}
                    totalPages={totalPages}
                />
            )}
        </>
    );
};

RolesTable.displayName = "RolesTable";
