import React from "react";

export const handleFieldChange = ({
    e,
    onChange
}: {
    e: React.ChangeEvent<HTMLInputElement>;
    onChange: (value: string) => void;
}) => {
    const { value } = e.target;
    onChange(value);
};
