import React, { useState } from "react";
import useSWR, { BareFetcher, KeyedMutator } from "swr";
import { Collection } from "../../domain/Collection";

export function usePaginatedList<T>({ key, fetcher }: { key: string | null; fetcher: BareFetcher<Collection<T>> }): {
    pageData: Collection<T> | undefined;
    error: Error | undefined;
    isLoading: boolean;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    mutate: KeyedMutator<Collection<T>>;
} {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const buildUrl = (url: string, currentPage: number) => {
        const baseUrl = url.split("?")[0]; // Extract the base URL
        const existingParams = new URLSearchParams(url.split("?")[1] || ""); // Get existing params
        existingParams.set("page", `${currentPage}`); // Update or add the page param

        return `${baseUrl}?${existingParams.toString()}`;
    };

    const { data, error, mutate, isValidating } = useSWR<Collection<T>, Error>(
        key ? buildUrl(key, currentPage) : null,
        fetcher
    );

    return {
        pageData: data,
        error,
        isLoading: isValidating || (!error && !data),
        currentPage,
        setCurrentPage,
        mutate
    };
}
