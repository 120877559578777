import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { useParams } from "react-router-dom";
import { AccessManagementUsers } from "../../components/Access/AccessManagementUsers";
import { Typography, Badge } from "@optimizely/axiom";
import { useState, useEffect, useCallback } from "react";
import { IProductAttribute } from "../../../domain/ProductAttribute.interface";

export const AccessManagementProjectUsers = () => {
    const { projectId } = useParams();
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);
    const { getAttribute } = useAttributes({});
    const getAttributeCallback = useCallback(getAttribute, [getAttribute]);
    const [project, setProject] = useState<IProductAttribute>();

    useEffect(() => {
        const getProject = async () => {
            try {
                if (projectId && !project) {
                    const attribute = await getAttributeCallback(projectId);
                    setProject(attribute);
                }
            } catch (err) {
                console.error("error getting attribute ", err);
            }
        };

        if (projectId) {
            getProject();
        }
    }, [projectId, project, getAttributeCallback]);

    if (!accessManagementEnabled) return <Unauthorized />;

    return (
        <>
            <Typography className="layout-header" tag="h1" type="header1">
                {project?.name}
                <Badge color="default" className="push--left">
                    {project?.type?.toUpperCase() || ""}
                </Badge>
            </Typography>
            <Typography type="body">{project?.description} </Typography>
            <AccessManagementUsers />
        </>
    );
};

AccessManagementProjectUsers.displayName = "ProjectUsersPage";
