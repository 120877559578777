export const config = {
    SERVICE_URL: process.env.REACT_APP_IDENTITY_SERVICE_URL,
    ISSUER: process.env.REACT_APP_OIDC_ISSUER, // https://optimizelyapp.oktapreview.com/oauth2/default
    CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID, // User Management app: 0oa1aupcsrm9rTnP10h8
    REDIRECT_URI: window.location.origin + process.env.REACT_APP_OIDC_CALLBACK_PATH,
    SCOPE: JSON.parse(process.env.REACT_APP_OIDC_SCOPE || "['']"),
    PKCE: Boolean(process.env.REACT_APP_OIDC_PKCE),
    RESPONSE_TYPE: JSON.parse(process.env.REACT_APP_OIDC_RESPONSE_TYPE || "['']"),
    DATADOG_APP_ID: process.env.REACT_APP_DATADOG_APP_ID,
    APP_ENVIRONMENT: process.env.REACT_APP_ENV || "local",
    DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN
};
