import { ProductInstance } from "../domain/ProductInstance";
import { IOrganizationProductInstance } from "../domain/OrganizationProduct";
import { ProductInstance as RestProductInstance } from "../rest-model/ProductInstance";
import { IOrganizationProductInstance as RestIOrganizationProduct } from "../rest-model/OrganizationProductInstances";

export class ProductInstanceAdapter {
    public adaptToDomain(restProductInstance: RestProductInstance): ProductInstance {
        const { permissions: roles } = restProductInstance;

        return new ProductInstance({
            roles,
            ...restProductInstance
        });
    }

    public adaptToRest(productInstance: Partial<IOrganizationProductInstance>): Partial<RestIOrganizationProduct> {
        const { optiIdEnabled, ...restOfInstance } = productInstance;

        const restOrgProduct: Partial<RestIOrganizationProduct> = restOfInstance;
        // only send over optiIdEnabled field if set to true
        if (optiIdEnabled) restOrgProduct.optiIdEnabled = optiIdEnabled.toString();

        return restOrgProduct;
    }
}
