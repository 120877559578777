import React from "react";
import UserList from "../../../pages/UserList/UserList";
import { AccessManagementUsers } from "../../Access/AccessManagementUsers";
import { Flags } from "../../../../feature-flags/flags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";

export const UsersPageRouting = () => {
    const { enabled: showSeparateUsersPage } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);

    if (showSeparateUsersPage) {
        return <AccessManagementUsers disableDeleteFromOptiId />;
    } else {
        return <UserList />;
    }
};
