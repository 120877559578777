import { Dropdown, Input, Spinner, Typography } from "@optimizely/axiom";
import React, { useState } from "react";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import styles from "../FilterDropdown/FilterDropdown.module.scss";
import classnames from "classnames";

type ProjectsDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    onChange: ({ value }: { value: ProductAttribute | ProductAttribute[] | null }) => void;
    placeholder?: string;
    filterPlaceholder?: string;
    projects: ProductAttribute[] | undefined;
    value?: string | string[];
    white?: boolean;
};

export const ProjectsDropdown = ({
    className,
    disabled = false,
    fullWidth = false,
    loading,
    onChange,
    placeholder = "Select Project...",
    filterPlaceholder = "Search Projects...",
    projects = [],
    value,
    white = false
}: ProjectsDropdownProps) => {
    const [filterString, setFilterString] = useState("");

    const filteredProjects = !!filterString
        ? projects?.filter((p) => p.name.toLowerCase().includes(filterString.toLowerCase()))
        : projects;

    const showFilterInput = (projects?.length || 0) > 10;
    const selectedProject = projects?.find((p) => p.id === value);
    let buttonContent: string | React.ReactNode = selectedProject?.name || placeholder;
    const noOptions = !projects.length;
    if (loading) {
        buttonContent = (
            <>
                <Spinner size="small" /> Loading projects...
            </>
        );
    } else if (noOptions) {
        buttonContent = "No projects available.";
    }
    return (
        <div className={className}>
            <Typography type="body" className="oui-label">
                Project
            </Typography>

            <Dropdown
                arrowIcon={loading ? null : "down"}
                buttonContent={buttonContent}
                className={className}
                fullWidth={fullWidth}
                isDisabled={disabled || noOptions || loading}
                style={white ? "outline" : undefined}
                whiteBackground={white}
            >
                <Dropdown.Contents style={{ width: "100%" }}>
                    {showFilterInput && (
                        <div className="oui-dropdown__item admin-center__dropdown-search-list-item">
                            <Input
                                isFilter
                                onClick={(e) => e.stopPropagation()}
                                onInput={(e) => {
                                    setFilterString(e.target.value);
                                }}
                                placeholder={filterPlaceholder}
                                type="search"
                            />
                        </div>
                    )}
                    {filteredProjects?.map((project, index) => {
                        const { id, name } = project;
                        const listItemClasses = classnames({
                            [styles["filter-dropdown__search-list-first-item"]]: index === 0 && showFilterInput
                        });
                        return (
                            <Dropdown.ListItem key={id} role="option" className={listItemClasses}>
                                <Dropdown.BlockLink isLink={value !== id} onClick={() => onChange({ value: project })}>
                                    <Dropdown.BlockLinkText text={name} />
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};

ProjectsDropdown.displayName = "ProjectsDropdown";
