import { BaseFieldProps } from "./BaseFieldProps";
import classnames from "classnames";
import React, { useState } from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";
import { InputFieldError } from "../../../InputFieldError/InputFieldError";

export const ClientIdField = ({ disabled, onChange, required = false }: BaseFieldProps) => {
    const [fieldError, setFieldError] = useState("");
    // TODO: Create InputField component that handles this and labels and notes
    const inputClasses = classnames("oui-text-input", {
        "oui-form-bad-news": !!fieldError
    });

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (required && !value) {
            setFieldError("Client ID is required.");
        } else {
            setFieldError("");
        }
        handleFieldChange({ e, onChange });
    };

    return (
        <>
            <label className="oui-label push-double--top" htmlFor="oidc-client-id">
                Client ID
                {required && <span aria-label="(required)" className="oui-label--required" />}
            </label>
            <input
                aria-describedby="oidc-client-id"
                className={inputClasses}
                id="oidc-client-id"
                type="text"
                disabled={disabled}
                onChange={onValueChange}
            />
            {!!fieldError && (
                <InputFieldError className="oui-form-note color--bad-news" id="oidc-client-id-error">
                    {fieldError}
                </InputFieldError>
            )}
        </>
    );
};

ClientIdField.displayName = "ClientIdField";
