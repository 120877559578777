import React from "react";
import { Typography } from "@optimizely/axiom";

import { NavLinks } from "../../components/NavLinks/NavLinks";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import "./Layout.css";
import "../../../scrollable-table.scss";
import { UserGroupProvider } from "../../components/UserGroupForm/UserGroupFormContext/UserGroupContext";
import { FormProvider } from "../../components/UserForm/UserFormContext/UserFormContext";

interface Links {
    title: string;
    route: string;
}

const Layout = ({
    children,
    headerText,
    pageDescription,
    tabs
}: {
    children: React.ReactNode;
    headerText: string;
    pageDescription?: string;
    tabs: Links[];
}) => {
    return (
        <div className="layout-container flex flex--column">
            <Breadcrumbs />
            {headerText && (
                <Typography className="layout-header" tag="h1" type="header1">
                    {headerText}
                </Typography>
            )}
            {pageDescription && (
                <div style={{ maxWidth: "980px" }}>
                    <Typography type="caption" tag="p" className="push-double--left push-double--bottom">
                        {pageDescription}
                    </Typography>
                </div>
            )}
            <UserGroupProvider>
                <FormProvider>
                    {tabs && <NavLinks links={tabs} />}
                    {children}
                </FormProvider>
            </UserGroupProvider>
        </div>
    );
};

export default Layout;
