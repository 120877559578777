import React from "react";
import { Skeleton } from "./Skeleton";

// TODO: Should these all be full width? (I think so)
export const TableSkeleton = ({ className = "", fullWidth = false }: { className?: string; fullWidth?: boolean }) => {
    const width = fullWidth ? "100%" : "240px";
    return (
        <div className={`flex flex--column flex-align--start ${className}`}>
            <Skeleton height="20px" width={width} />
            <div className="push--bottom" />
            <Skeleton height="20px" width={width} />
            <div className="push--bottom" />
            <Skeleton height="20px" width={width} />
        </div>
    );
};

TableSkeleton.displayName = "TableSkeleton";
