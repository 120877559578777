import React, { useState } from "react";
import { SSODomainsSheet } from "../../components/SSOForm/SSODomains/SSODomainsSheet";
import { useUserContext } from "../../../providers/UserProvider";
import { useOrganizationIdp } from "../../../hooks/useOrganizationIdp/useOrganizationIdp";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { Typography } from "@optimizely/axiom";
import { IdentityProviderDropdown } from "../../components/SSOForm/SSODomains/IdentityProviderDropdown";
import { IdentityProvider } from "../../../domain/IdentityProvider";
import { DomainsDisclosure } from "../../components/SSOForm/SSODomains/DomainsDisclosure";

export const DomainsPage = () => {
    const { organizationId } = useUserContext();
    const { identityProviders, updateIdpDomains, isLoadingIdp } = useOrganizationIdp(organizationId);
    const [domainsIdp, setDomainsIdp] = useState<IdentityProvider | null>(null);

    if (isLoadingIdp) return <LoadingIndicator height="100%" type="spinner" />;

    const idpsWithDomains = identityProviders?.filter((idp) => !!idp.domains?.length);
    const idpsWithoutDomains = identityProviders?.filter((idp) => !idp.domains?.length);

    return (
        <div className="flex flex--column flex-align--start push-double--top" style={{ maxWidth: "500px" }}>
            <>
                <Typography type="header4">Domains / Dynamic Provisioning</Typography>
                <Typography tag="p" type="caption">
                    Specify an optional list of domains for each SSO connection for your organization.
                </Typography>
                <Typography tag="p" type="caption">
                    Adding one or more domains for your organization allows users from your organization matching one of
                    the domains to be provisioned dynamically to OptiId.
                </Typography>
                <IdentityProviderDropdown
                    identityProviders={idpsWithoutDomains || []}
                    onChange={({ identityProvider }) => setDomainsIdp(identityProvider)}
                />
                {!identityProviders?.length && (
                    <Typography type="caption" className="push--top">
                        SSO needs to be connected before adding SSO Domains.
                    </Typography>
                )}
                {!!idpsWithDomains?.length && (
                    <Typography type="header4" className="push-double--ends">
                        Existing Domains
                    </Typography>
                )}
                {idpsWithDomains?.map((idp) => {
                    return <DomainsDisclosure identityProvider={idp} onEdit={() => setDomainsIdp(idp)} key={idp.id} />;
                })}
                {!!domainsIdp && (
                    <SSODomainsSheet
                        identityProvider={domainsIdp}
                        onClose={() => setDomainsIdp(null)}
                        onSave={async ({ domains }) => {
                            return await updateIdpDomains({ domains, identityProviderId: domainsIdp.id });
                        }}
                    />
                )}
            </>
        </div>
    );
};

DomainsPage.displayName = "DomainsPage";
