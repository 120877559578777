import React, { useEffect, useState } from "react";
import { useProducts } from "../../../../hooks/useProducts/useProducts";
import { useUserContext } from "../../../../providers/UserProvider";
import { TableSkeleton } from "../../Skeleton/TableSkeleton";
import { useAccessList } from "../../../../hooks/useAccessList/useAccessList";
import { AccessDetailsByProductId, AccessDetailsTable } from "../AccessDetailsTable/AccessDetailsTable";
import { updateAccessWithProjectDetails, getAccessByProduct } from "../../../../lib/access-helpers";
import {
    CustomGroupAccess,
    useAccessFlowUserFormContext
} from "../AccessManagementUserForm/AccessManagementUserFormProvider";

type CustomGroupAccessDetailsProps = {
    groupId: string | undefined;
    groupName?: string | undefined;
};

export const CustomGroupAccessDetails = ({ groupId = "", groupName = "" }: CustomGroupAccessDetailsProps) => {
    const { organizationId = "" } = useUserContext();
    const { getUserGroupInstances } = useProducts({ organizationId });
    const { organizationGroups: availableOrgAccess } = useAccessList({ organizationId });
    const [productList, setProductList] = useState<AccessDetailsByProductId>({});
    const [accessDetails, setAccessDetails] = useState<AccessDetailsByProductId>({});
    const [groupDetailsLoading, setGroupDetailsLoading] = useState(true);
    const { setUpdateCustomGroupAccess } = useAccessFlowUserFormContext();

    useEffect(() => {
        if (!!groupId) {
            getUserGroupInstances({ userGroupId: groupId })
                .then((response) => {
                    const productInstances = response.items;
                    const accessByProduct = getAccessByProduct({
                        productInstances,
                        groupId,
                        groupName,
                        isCustomGroup: true
                    });
                    setProductList(accessByProduct || {});
                })

                .catch((error) => console.error("Failed to fetch product instances: ", error))
                .finally(() => setGroupDetailsLoading(false));
        }
    }, [groupId, groupName, getUserGroupInstances]);

    useEffect(() => {
        const isProductListEmpty = Object.keys(productList).length === 0;
        let accessDetails = { ...productList };

        const hasExperimentationProductInGroupAccess =
            !isProductListEmpty && productList[process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID!];

        const hasAvailableOrgAccess = (availableOrgAccess || []).length > 0;

        const needsProjectDetails = hasExperimentationProductInGroupAccess && hasAvailableOrgAccess && !!groupId;

        if (needsProjectDetails) {
            setGroupDetailsLoading(true);
            accessDetails = updateAccessWithProjectDetails({ productList, availableOrgAccess, groupId });
            setGroupDetailsLoading(false);
        }

        setAccessDetails(accessDetails);
        setUpdateCustomGroupAccess((prev): CustomGroupAccess => {
            return { ...prev, accessDetails };
        });
    }, [productList, availableOrgAccess, groupId, setUpdateCustomGroupAccess]);

    if (groupDetailsLoading) {
        return <TableSkeleton className="push-double--top push-double--bottom push--left" />;
    }

    return <AccessDetailsTable accessDetails={accessDetails} />;
};

CustomGroupAccessDetails.displayName = "CustomGroupAccessDetails";
