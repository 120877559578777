export class Collection<T> {
    constructor(result: { items: T[]; totalCount?: number; totalItemCount?: number }) {
        this.items = result.items;
        // @TODO: Fix this "totalItemCount" should be the key used as that is what the api returns and makes sense
        this.totalCount = (result.totalCount || result.totalItemCount) as number;
    }

    items: T[];
    totalCount: number;
}
