export interface IBaseIdentityProvider {
    domains: string[];
    id: string;
    idpId?: string;
    idpName: string;
    issuerUrl: string | undefined;
    type: "SAML" | "OIDC"; // This is the discriminator
}

export interface ISAMLIdp extends IBaseIdentityProvider {
    acsUrl: string;
    audienceUrl: string;
    ssoUrl: string | null;
    type: "SAML";
}

export interface IOIDCIdp extends IBaseIdentityProvider {
    authorizationUrl?: string;
    clientId: string;
    clientSecret: string;
    jwksUrl?: string;
    provider: string;
    tokenUrl?: string;
    type: "OIDC";
    userInfoUrl?: string;
    wellKnownUrl?: string;
}

export type IIdentityProvider = IOIDCIdp | ISAMLIdp;
export type OIDCIdpWithoutId = Omit<IOIDCIdp, "id" | "domains">;
export type SAMLIdpWithoutId = Omit<ISAMLIdp, "id" | "domains">;
export type IdpWithoutId = Omit<IIdentityProvider, "id" | "domains">;

export class IdentityProvider implements IBaseIdentityProvider {
    constructor({ issuerUrl, ...rest }: IIdentityProvider) {
        this.issuerUrl = issuerUrl;
        this.type = rest.type;
        this.id = rest?.id;
        this.idpName = rest?.idpName || "Connection";
        this.idpId = rest?.idpId;
        this.domains = rest?.domains || [];

        if (this.type === "SAML") {
            // saml
            const { acsUrl, audienceUrl, ssoUrl } = rest as ISAMLIdp;
            this.audienceUrl = audienceUrl;
            this.acsUrl = acsUrl;
            this.ssoUrl = ssoUrl;
        } else {
            // oidc
            const { authorizationUrl, clientId, clientSecret, jwksUrl, provider, tokenUrl, userInfoUrl, wellKnownUrl } =
                rest as IOIDCIdp;
            this.authorizationUrl = authorizationUrl;
            this.clientId = clientId;
            this.clientSecret = clientSecret;
            this.jwksUrl = jwksUrl;
            this.provider = provider;
            this.tokenUrl = tokenUrl;
            this.userInfoUrl = userInfoUrl;
            this.wellKnownUrl = wellKnownUrl;
        }
    }

    audienceUrl?: string;
    authorizationUrl?: string;
    acsUrl?: string;
    clientId?: string;
    clientSecret?: string;
    domains: string[]; // todo: non-optional
    id: string;
    idpId?: string;
    idpName: string;
    issuerUrl: string | undefined;
    jwksUrl?: string;
    provider?: string;
    ssoUrl?: string | null;
    tokenUrl?: string;
    type: "OIDC" | "SAML";
    userInfoUrl?: string;
    wellKnownUrl?: string;
}
