import React, { useEffect, useReducer, useState } from "react";
import { Dropdown, Typography } from "@optimizely/axiom";
import styles from "./RoleForm.module.scss";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../domain/OrganizationProduct";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { IProductAttribute } from "../../../domain/ProductAttribute.interface";
import { ATTRIBUTE_SCOPES, NO_RESTRICTIONS } from "../../../constants/constants";
import { Skeleton } from "../Skeleton/Skeleton";

type SelectedScopes = {
    product?: OrganizationProduct;
    instances: IOrganizationProductInstance[];
    projects: IProductAttribute[];
};

export const AdminCenterScopeDropdowns = ({
    products,
    scopes = [],
    onSelectionsChanged
}: {
    products: OrganizationProduct[];
    scopes?: { name: string; value: string }[];
    onSelectionsChanged?: any;
}) => {
    const [loading, setLoading] = useState(false);
    const reducer = (
        state: SelectedScopes,
        action: {
            target?: string;
            value?: OrganizationProduct | IOrganizationProductInstance[] | IProductAttribute[];
            initializer?: SelectedScopes;
        }
    ): SelectedScopes => {
        const { target, value, initializer } = action;
        if (initializer) {
            return initializer;
        }

        return {
            ...state,
            [target!]: value
        };
    };
    const [selectedScopes, updateSelectedScopes] = useReducer<
        (
            state: SelectedScopes,
            action: { target?: string; value?: any; initializer?: SelectedScopes }
        ) => SelectedScopes
    >(reducer, {
        product: undefined,
        instances: [],
        projects: []
    });

    const { attributes: projects, getExperimentationProjects } = useAttributes({
        productId: selectedScopes.product?.id,
        isActive: true
    });

    const isExp = selectedScopes.product?.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    useEffect(() => {
        let scopeList: { name: string; value: string }[] = [];

        const hasInstanceRestrictions = !!selectedScopes.instances.length;
        const hasProjectRestrictions = !!selectedScopes.projects.length;

        if (hasInstanceRestrictions || hasProjectRestrictions) {
            if (hasProjectRestrictions) {
                selectedScopes.projects
                    ?.filter((p) => !!p)
                    .forEach((project) => {
                        scopeList.push({ name: ATTRIBUTE_SCOPES.PROJECT, value: project.id });
                    });
            } else {
                selectedScopes.instances?.forEach((instance: IOrganizationProductInstance) => {
                    scopeList.push({ name: ATTRIBUTE_SCOPES.INSTANCE, value: instance.id });
                });
            }
        } else if (selectedScopes.product) {
            scopeList.push({ name: ATTRIBUTE_SCOPES.PRODUCT, value: selectedScopes.product.id });
        }

        if (onSelectionsChanged) {
            onSelectionsChanged(scopeList);
        }
    }, [selectedScopes.product, selectedScopes.instances, selectedScopes.projects, onSelectionsChanged]);
    const getProductFromProjects = async ({
        projectScopes
    }: {
        projectScopes: { name: string; value: string | number }[];
    }) => {
        const expProduct = products?.find((p) => p.id === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID);
        const experimentationInstanceProjects = expProduct?.instances?.map(async (i) => {
            return await getExperimentationProjects({ instanceId: i.id });
        });

        // @ts-ignore
        const results = await Promise.all(experimentationInstanceProjects);
        const flattenedResults = results.flatMap((i: any) => i);
        const projectsForScopes = projectScopes
            ?.map((s) => {
                return flattenedResults.find((a: any) => a.id === s.value);
            })
            ?.filter((p) => !!p);

        // omit any projects we couldn't find
        const affectedInstanceIds = projectsForScopes?.map((s) => s?.instanceId).filter((s) => !!s);
        const instancesInScopes = expProduct?.instances?.filter((i) => {
            return i && affectedInstanceIds.indexOf(i.id) > -1;
        });
        updateSelectedScopes({
            initializer: {
                product: expProduct,
                instances: instancesInScopes || [],
                projects: projectsForScopes
            }
        });
    };

    useEffect(() => {
        const scopeForProduct = scopes.find((s) => s.name === ATTRIBUTE_SCOPES.PRODUCT);
        const instanceScopes = scopes.filter((s) => s.name === ATTRIBUTE_SCOPES.INSTANCE);
        const projectScopes = scopes.filter((s) => s.name === ATTRIBUTE_SCOPES.PROJECT);
        if (projectScopes?.length && !scopeForProduct) {
            setLoading(true);
            getProductFromProjects({ projectScopes }).finally(() => {
                setLoading(false);
            });
            return;
        }

        // look up the product based on the scopes if necessary
        const selectedProduct = products?.find((p) => {
            // scopes with instance id
            if (!scopeForProduct && instanceScopes?.length) {
                return p.instances.find((instance) => instance.id === instanceScopes[0].value);
            }
            return p.id === scopeForProduct?.value;
        });

        let instances: IOrganizationProductInstance[] = [];
        instanceScopes.forEach((s) => {
            const instance = selectedProduct?.instances.find((i) => i.id === s.value);
            if (instance) instances.push(instance);
        });

        let projList: IProductAttribute[] = [];
        if (isExp) {
            projectScopes?.forEach((s) => {
                const project = projects?.find((p) => p.id === s.value);
                if (project) projList.push(project);
            });
        }

        updateSelectedScopes({
            initializer: { product: selectedProduct, instances, projects: projList }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scopes]);

    const handleAllProductsSelected = () => {
        updateSelectedScopes({ target: "product", value: undefined });
    };
    const handleProductSelected = (product: OrganizationProduct) => {
        updateSelectedScopes({ target: "product", value: product });
        updateSelectedScopes({ target: "instances", value: [] });
        updateSelectedScopes({ target: "projects", value: [] });
    };

    const handleAllInstancesSelected = () => {
        updateSelectedScopes({ target: "instances", value: [] });
        updateSelectedScopes({ target: "projects", value: [] });
    };

    const handleInstanceSelected = (instance: IOrganizationProductInstance) => {
        let updatedInstances = [...selectedScopes.instances];

        if (updatedInstances.find((i) => i.id === instance.id)) {
            updatedInstances = updatedInstances.filter((i) => i.id !== instance.id);
        } else {
            updatedInstances.push(instance);
        }

        updateSelectedScopes({ target: "instances", value: updatedInstances });
    };

    const handleAllProjectsSelected = () => {
        updateSelectedScopes({ target: "projects", value: [] });
    };

    const handleProjectSelected = (project: IProductAttribute) => {
        let updatedProjects = [...selectedScopes.projects];

        const existingIndex = updatedProjects.findIndex((p) => p.id === project.id);
        if (existingIndex > -1) {
            updatedProjects.splice(existingIndex, 1);
        } else {
            updatedProjects.push(project);
        }

        updateSelectedScopes({
            target: "projects",
            value: updatedProjects
        });
    };

    return (
        <div className="push-quad--top">
            <Typography type="header4">
                <span style={{ fontWeight: 300 }}>
                    Restrict Admin User Access <span aria-label="(required)" className="oui-label--required" />
                </span>
            </Typography>

            <span className="oui-label push--top">
                Select the product entities accessible to an Admin user with this role. Access is restricted to this
                selection.
            </span>

            <span className="oui-label push-double--top">
                Product <span aria-label="(required)" className="oui-label--required" />
            </span>
            {loading && !selectedScopes?.product?.name ? (
                <Skeleton height="32px" width="100%" />
            ) : (
                <Dropdown
                    buttonContent={selectedScopes.product?.name || NO_RESTRICTIONS}
                    arrowIcon="down"
                    fullWidth
                    className={styles["role-form__dropdown"]}
                >
                    <Dropdown.Contents>
                        <Dropdown.ListItem key="no-restrictions-product">
                            <Dropdown.BlockLink
                                onClick={handleAllProductsSelected}
                                isItemSelected={!selectedScopes.product}
                            >
                                {NO_RESTRICTIONS}
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                        {products?.map((product) => {
                            return (
                                <Dropdown.ListItem key={product.id}>
                                    <Dropdown.BlockLink
                                        isItemSelected={selectedScopes.product?.id === product.id}
                                        onClick={() => handleProductSelected(product)}
                                    >
                                        {product.name}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
            )}

            <span className="oui-label push-double--top">
                Instance <span aria-label="(required)" className="oui-label--required" />
            </span>
            {loading && !selectedScopes?.instances?.length ? (
                <Skeleton height="32px" width="100%" />
            ) : (
                <Dropdown
                    isDisabled={!selectedScopes.product}
                    arrowIcon="down"
                    fullWidth
                    buttonContent={
                        selectedScopes.instances?.map((i) => i.nickname || i.name).join(", ") || NO_RESTRICTIONS
                    }
                    className={styles["role-form__dropdown"]}
                    shouldHideChildrenOnClick={false}
                >
                    <Dropdown.Contents>
                        <Dropdown.ListItem key="no-restrictions-instance">
                            <Dropdown.BlockLink
                                onClick={handleAllInstancesSelected}
                                isMultiSelect
                                isItemSelected={!selectedScopes.instances.length}
                            >
                                {NO_RESTRICTIONS}
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                        {selectedScopes.product?.instances?.map((instance: IOrganizationProductInstance) => {
                            const { id, name, nickname } = instance;
                            return (
                                <Dropdown.ListItem key={id}>
                                    <Dropdown.BlockLink
                                        onClick={() => handleInstanceSelected(instance)}
                                        isMultiSelect
                                        isItemSelected={!!selectedScopes.instances?.find((i) => i.id === id)}
                                    >
                                        {nickname || name}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
            )}
            {isExp && (
                <>
                    <span className="oui-label push-double--top">
                        Project <span aria-label="(required)" className="oui-label--required" />
                    </span>
                    <Dropdown
                        isDisabled={!selectedScopes.instances?.length}
                        arrowIcon="down"
                        fullWidth
                        buttonContent={selectedScopes.projects?.map((i) => i.name).join(", ") || NO_RESTRICTIONS}
                        className={styles["role-form__dropdown"]}
                        shouldHideChildrenOnClick={false}
                    >
                        <Dropdown.Contents>
                            <Dropdown.ListItem key="no-restrictions-project">
                                <Dropdown.BlockLink
                                    onClick={handleAllProjectsSelected}
                                    isMultiSelect
                                    isItemSelected={!selectedScopes.projects.length}
                                >
                                    {NO_RESTRICTIONS}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                            {projects
                                ?.filter((p) => p.name)
                                .map((project: IProductAttribute) => {
                                    const { id, name } = project;
                                    return (
                                        <Dropdown.ListItem key={id}>
                                            <Dropdown.BlockLink
                                                onClick={() => handleProjectSelected(project)}
                                                isMultiSelect
                                                isItemSelected={!!selectedScopes.projects?.find((i) => i.id === id)}
                                            >
                                                {name}
                                            </Dropdown.BlockLink>
                                        </Dropdown.ListItem>
                                    );
                                })}
                        </Dropdown.Contents>
                    </Dropdown>
                </>
            )}
        </div>
    );
};

AdminCenterScopeDropdowns.displayName = "AdminCenterScopeDropdowns";
