import { DEFAULT_PAGE_SIZE, EXP_PERMISSIONS_KEY } from "../constants/constants";
import { InstanceRole } from "../domain/InstanceRole.interface";
import { Role } from "../domain/Role";

export const listIsDirty = (previousList: string[], newList: string[]) => {
    const sortedList = newList.sort();
    return !(previousList.length === newList.length && previousList.sort().every((v, i) => v === sortedList[i]));
};

/**
 * Compares object1 key values to object 2 key values.
 * NOTE: This means that keys in object 2 do not necessarily need to be in object 1.
 * @param object1
 * @param object2
 * @param keysToCheck: array of keys to check instead of checking all the object keys
 */
export const deepEquals = (object1: any, object2: any, keysToCheck: string[] = []) => {
    if (!(object1 && object2)) {
        return false;
    }

    // If no keys are provided just go through object1's keys
    if (!keysToCheck.length) {
        return Object.keys(object1).every((itemKey) => {
            return object1[itemKey] === object2[itemKey];
        });
    }

    // look through provided keys and check equality
    return keysToCheck.every((key) => {
        if (Array.isArray(object1[key])) {
            return !listIsDirty(object1[key], object2[key]);
        } else {
            return object1[key] === object2[key];
        }
    });
};

export const arrayObjectsDeepEquals = ({
    list1,
    list2,
    key = "id",
    keysToCheck = []
}: {
    list1: any[];
    list2: any[];
    key?: string;
    keysToCheck?: string[];
}) => {
    // if array lengths aren't equal we already know the items are not equal, return early
    if (list1.length !== list2.length) {
        return false;
    }

    return list1.every((listItem) => {
        // find object in second list
        const item2 = list2.find((i) => i[key] === listItem[key]);
        return deepEquals(listItem, item2, keysToCheck);
    });
};

export const splitIntoChunks = ({ list, size = DEFAULT_PAGE_SIZE }: { list: any[]; size?: number }) => {
    return list?.reduce((stored: any[], current: any, currentIndex: number) => {
        if (currentIndex % size === 0) {
            stored.push([current]);
        } else {
            stored[stored.length - 1].push(current);
        }
        return stored;
    }, []);
};

export const paginateArray = ({
    list,
    size = DEFAULT_PAGE_SIZE,
    filter
}: {
    list: any[];
    size?: number;
    filter?: { query: string; fields: string[] };
}) => {
    let results = list;
    if (filter) {
        const { fields, query } = filter;
        results = list.filter((i) => {
            return fields.some((f) => {
                return i[f]?.toLowerCase().includes(query.toLowerCase());
            });
        });
    }
    return {
        total: results?.length || 0,
        totalPages: Math.ceil((results?.length || 0) / size),
        pages: splitIntoChunks({ list: results, size })
    };
};

/**
 * Turn search parameters to a map
 */
export const getQueryParams = (search: string) => {
    if (!search) return null;
    const params = new URLSearchParams(search);
    const queryParams: { [key: string]: string } = {};

    // @ts-ignore
    for (const [key, value] of params.entries()) {
        queryParams[key] = value;
    }

    return queryParams;
};

export const transformMSWKeys = (items: any[]): any[] => {
    return items.map((item) => {
        return Object.fromEntries(
            Object.entries(item).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), value])
        );
    });
};

// Format role names for tables / user readability
export const getRoleNames = (roles: InstanceRole[]) => {
    return (
        roles
            .map((r) => r.name || "")
            .filter((n) => !!n)
            .join(", ") || "N/A"
    );
};

export const getExpRoleName = (role: Role) => {
    const [roleName] = role.attributes?.find((r: any) => r.key === EXP_PERMISSIONS_KEY)?.values || [];
    return roleName;
};

export const capitalizeText = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const EMAIL_REGEX = new RegExp(
    "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"
);

export const isValidEmail = (email?: string | undefined | null) => {
    if (!email) {
        return false;
    }
    return (email.match(EMAIL_REGEX) || []).length > 0;
};

// @ts-ignore
export const diff = (set1: Set<string>, set2: Set<string>) => new Set([...set1].filter((x) => !set2.has(x)));

export const difference = (arr1: string[], arr2: string[]): string[] => Array.from(diff(new Set(arr1), new Set(arr2)));
