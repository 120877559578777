/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from "@optimizely/axiom";
import React, { ReactNode, useEffect, useState } from "react";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { UserGroup } from "../../../domain/UserGroup";
import { Role } from "../../../domain/Role";
import { getExpRoleName } from "../../../lib/utils";

export const ProjectRoleRow = ({
    projects,
    roles,
    actionButtons,
    onGroupSelected
}: {
    projects?: ProductAttribute[];
    roles?: { group: UserGroup; project?: ProductAttribute; role: Role }[];
    actionButtons: ReactNode[];
    onGroupSelected: (group: UserGroup) => void;
}) => {
    const [selectedProject, setSelectedProject] = useState<ProductAttribute | undefined>();
    const [selectedGroupRole, setSelectedGroupRole] = useState<
        { group: UserGroup; project?: ProductAttribute; role: Role } | undefined
    >();

    useEffect(() => {
        if (selectedGroupRole) {
            onGroupSelected(selectedGroupRole.group);
        }
    }, [selectedGroupRole]);

    const rolesForProject = roles?.filter((role) => role.project && role.project.key === selectedProject?.key);

    return (
        <div className="flex push-double--top">
            <div className="width--2-5 soft--right">
                <Dropdown
                    arrowIcon="down"
                    fullWidth
                    className="admin-center__form-dropdown"
                    buttonContent={selectedProject?.name || "Select a project..."}
                >
                    <Dropdown.Contents>
                        {projects?.map((project) => {
                            return (
                                <Dropdown.ListItem key={project.id}>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            setSelectedProject(project);
                                        }}
                                    >
                                        {project.name}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
            </div>
            <div className="width--2-5 soft--right">
                <Dropdown
                    isDisabled={!selectedProject}
                    arrowIcon="down"
                    fullWidth
                    className="admin-center__form-dropdown"
                    buttonContent={selectedGroupRole ? getExpRoleName(selectedGroupRole.role) : "Select a role..."}
                >
                    <Dropdown.Contents>
                        {rolesForProject?.map((groupRole) => {
                            return (
                                <Dropdown.ListItem key={groupRole.group.id}>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            setSelectedGroupRole(groupRole);
                                        }}
                                    >
                                        {getExpRoleName(groupRole.role)}
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            );
                        })}
                    </Dropdown.Contents>
                </Dropdown>
            </div>
            <div className="width--1-5">{actionButtons?.map((button) => button)}</div>
        </div>
    );
};

ProjectRoleRow.displayName = "ProjectRoleRow";
