import React from "react";
import { Input } from "@optimizely/axiom";

type SearchInputProps = {
    disabled?: boolean;
    error?: string;
    label?: string;
    placeholder?: string;
    onChange: ({ value }: { value: string }) => void;
    onClick?: (...args: any[]) => any;
    onBlur?: (...args: any[]) => any;
    onSubmit?: () => void;
    type?: "number" | "search" | "time" | "text" | "password" | "date" | "email" | "url" | "tel";
    value?: string | undefined;
    ariaLabel?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    className?: string;
};

export const SearchInput = ({
    disabled = false,
    error,
    label,
    placeholder = "Search",
    onChange,
    onClick,
    onBlur,
    onSubmit,
    type = "search",
    value = "",
    ariaLabel,
    inputRef,
    className
}: SearchInputProps) => {
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange({ value: e.currentTarget.value });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSubmit && onSubmit();
        }
    };

    return (
        <div className={className}>
            <Input
                isDisabled={disabled}
                ref={inputRef}
                aria-label={ariaLabel}
                displayError={!!error}
                label={label}
                leftIconName="search"
                note={error}
                placeholder={placeholder}
                type={type}
                value={value}
                onBlur={onBlur}
                onChange={handleChange}
                onClick={onClick}
                onKeyDown={handleKeyDown}
                disabled={disabled}
            />
        </div>
    );
};

SearchInput.displayName = "SearchInput";
