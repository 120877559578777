import React, { ChangeEvent, useState } from "react";
import { Typography } from "@optimizely/axiom";
import classnames from "classnames";
import { InputFieldError } from "../../InputFieldError/InputFieldError";

type ConnectionNameInputProps = {
    disabled?: boolean;
    error?: string;
    onChange?: (name: string) => void;
    value?: string;
};

const MAX_CHAR_COUNT = 67;
export const ConnectionNameInput = ({
    disabled,
    error: parentError,
    onChange,
    value = ""
}: ConnectionNameInputProps) => {
    const [error, setError] = useState<string | undefined>(parentError);
    const characterCount = value?.length || 0;
    const overLimit = characterCount > MAX_CHAR_COUNT;
    const countClasses = classnames("flex justify-content-end align-items-center oui-form-note", {
        "justify-content-between": !!error,
        "color--bad-news": !!error || overLimit
    });
    const inputClasses = classnames("oui-text-input", {
        "oui-form-bad-news": !!error,
        "push-double--bottom": disabled
    });

    const verifyConnectionName = (e: ChangeEvent) => {
        const { value } = e.target as HTMLInputElement;
        const allWhiteSpace = value.replace(/\s/g, "").length === 0;
        if (!value || allWhiteSpace) {
            setError("Connection Name is required.");
        } else if (value?.length >= MAX_CHAR_COUNT) {
            setError("Connection name cannot be more than 67 characters.");
        } else {
            setError("");
        }
        onChange && onChange(allWhiteSpace ? "" : value);
    };

    return (
        <div>
            <label className="oui-label push-double--top" htmlFor="connection-name">
                Connection Name
                {!disabled && <span aria-label="(required)" className="oui-label--required" />}
            </label>

            <input
                aria-describedby="connection-name"
                className={inputClasses}
                disabled={disabled}
                id="connection-name"
                placeholder="Connection Name"
                type="text"
                value={value}
                onChange={verifyConnectionName}
            />
            {!disabled && (
                <Typography type="caption" className={countClasses}>
                    {error && <InputFieldError>{error}</InputFieldError>}
                    {value?.length || 0} / {MAX_CHAR_COUNT}
                </Typography>
            )}
        </div>
    );
};

ConnectionNameInput.displayName = "ConnectionNameInput";
