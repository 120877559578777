import { Button, Icon, Spinner } from "@optimizely/axiom";
import styles from "./AccessManagementUserForm.module.scss";
import { AllIcons } from "components/Icon/icon-types";
import classNames from "classnames";

export const AccessManagementUserFormIconButton = ({
    className = "",
    icon,
    loading,
    disabled,
    onClick,
    title = "",
    white = false
}: {
    className?: string;
    icon: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
    title?: string;
    white?: boolean;
}) => {
    return (
        <Button
            className={classNames(styles["icon-button"], styles[`${className}`], className)}
            title={title}
            // eslint-disable-next-line react/style-prop-object
            style={white ? "outline" : "plain"}
            onClick={onClick}
            size="small"
            isDisabled={disabled || loading}
            whiteBackground={white}
        >
            {loading ? <Spinner size="small" /> : <Icon name={icon as AllIcons} size="small" />}
        </Button>
    );
};
