/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Invitation } from "../../../domain/Invitation";
import { useInvitations } from "../../../hooks/useInvitations/useInvitations";
import { useUserContext } from "../../../providers/UserProvider";
import { ATTRIBUTE_ROLES, CONTEXT_SCOPES } from "../../../constants/constants";
import styles from "../../pages/Invitations/Invitations.module.scss";
import { Button } from "@optimizely/axiom";
import { Sidebar } from "../Sidebar/Sidebar";
import { AccessManagementInvitationForm } from "../Access/AccessManagementInvitationForm";
import { useParams, useLocation } from "react-router-dom";
import { Flags } from "../../../feature-flags/flags";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";

export const InviteUserButtonAndSidebar = () => {
    const { organizationId, accessContext, canUserDoAction } = useUserContext();
    const [showInvitationForm, setShowInvitationForm] = useState(false);
    const { productId, instanceId, projectId } = useParams();
    const { enabled: showSeparateUsersPage } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { pathname } = useLocation();

    const { createInvitation } = useInvitations({
        organizationId: organizationId
    });

    const handleFormCancel = () => {
        setShowInvitationForm(false);
    };
    const handleCreateInvitation = async ({
        newInvitation,
        inviteAnother
    }: {
        newInvitation: Invitation | null;
        inviteAnother: boolean;
    }) => {
        if (!newInvitation) {
            return false;
        }

        await createInvitation(newInvitation);
        if (!inviteAnother) {
            setShowInvitationForm(false);
        }
        return true;
    };

    const canAccessInvite =
        canUserDoAction({
            action: ATTRIBUTE_ROLES.INVITATIONS.CREATE,
            context: { AttributeId: projectId }
        }) ||
        canUserDoAction({
            action: ATTRIBUTE_ROLES.INVITATIONS.CREATE,
            context: { InstanceId: instanceId }
        }) ||
        canUserDoAction({
            action: ATTRIBUTE_ROLES.INVITATIONS.CREATE,
            context: { ProductId: productId }
        });

    const usersPage =
        showSeparateUsersPage && (pathname === "/user-management/users" || pathname === "/user-management/users/");

    const usersFlow = pathname === "/user-management/users";

    const showInviteUser = canAccessInvite && (usersPage || accessContext?.scope !== CONTEXT_SCOPES.ORGANIZATION);

    return (
        <>
            {showInviteUser && (
                <>
                    <div className={styles["invitations__add-invitation"]}>
                        <Button style="highlight" onClick={() => setShowInvitationForm(true)}>
                            Invite User...
                        </Button>
                    </div>
                    <Sidebar heading="Invite User" isOpen={showInvitationForm} onCancel={handleFormCancel} noTransform>
                        <AccessManagementInvitationForm
                            onCancel={handleFormCancel}
                            onCreate={handleCreateInvitation}
                            usersFlow={usersFlow}
                        />
                    </Sidebar>
                </>
            )}
        </>
    );
};
