import { IIdentityRepository } from "../../repositories/IdentityRepository";
import { IdentityProvider } from "../../domain/IdentityProvider";
import { OrganizationAdapter } from "../OrganizationAdapter";

export interface IIdpService {
    addSAMLProvider({
        idpName,
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        idpName: string;
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<IdentityProvider>;
    addOIDCProvider({
        authorizationUrl,
        clientId,
        clientSecret,
        idpName,
        issuerUrl,
        jwksUrl,
        organizationId,
        provider,
        tokenUrl,
        userInfoUrl,
        wellKnownUrl
    }: {
        authorizationUrl?: string;
        clientId: string;
        clientSecret: string;
        idpName: string;
        issuerUrl?: string;
        jwksUrl?: string;
        organizationId: string;
        provider: string;
        tokenUrl?: string;
        userInfoUrl?: string;
        wellKnownUrl?: string;
    }): Promise<IdentityProvider>;
    deleteOrganizationIdentityProviders({ organizationId }: { organizationId: string }): Promise<void>;
    deleteOrganizationIdentityProvider({ id, organizationId }: { id: string; organizationId: string }): Promise<void>;
    getOrganizationIdentityProviders({
        organizationId
    }: {
        organizationId: string;
    }): Promise<IdentityProvider[] | null>;
    getOrganizationDomains({ organizationId }: { organizationId: string }): Promise<string[]>;
    getProviders(): Promise<string[]>;
    updateIdentityProviderDomains({
        domains,
        identityProviderId,
        organizationId
    }: {
        domains: string[];
        identityProviderId: string;
        organizationId: string;
    }): Promise<IdentityProvider>;
    updateOrganizationDomains({
        organizationId,
        domains
    }: {
        organizationId: string;
        domains: string[];
    }): Promise<string[]>;
}

export class IdpService implements IIdpService {
    identityRepository: IIdentityRepository;
    organizationAdapter: OrganizationAdapter;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.organizationAdapter = new OrganizationAdapter();
    }

    public async getProviders(): Promise<string[]> {
        return await this.identityRepository.getProviders();
    }

    public async addOIDCProvider({
        authorizationUrl,
        clientId,
        clientSecret,
        idpName,
        issuerUrl,
        jwksUrl,
        organizationId,
        provider,
        tokenUrl,
        userInfoUrl,
        wellKnownUrl
    }: {
        authorizationUrl?: string;
        clientId: string;
        clientSecret: string;
        idpName: string;
        issuerUrl?: string;
        jwksUrl?: string;
        organizationId: string;
        provider: string;
        tokenUrl?: string;
        userInfoUrl?: string;
        wellKnownUrl?: string;
    }): Promise<IdentityProvider> {
        const idp = await this.identityRepository.addOIDCIdentityProvider({
            authorizationUrl,
            clientId,
            clientSecret,
            idpName,
            issuerUrl,
            jwksUrl,
            organizationId,
            provider,
            tokenUrl,
            userInfoUrl,
            wellKnownUrl
        });
        return this.organizationAdapter.adaptIdpToDomain(idp);
    }

    public async addSAMLProvider({
        idpName,
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        idpName: string;
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<IdentityProvider> {
        const idp = await this.identityRepository.addSAMLIdentityProvider({
            idpName,
            organizationId,
            issuer,
            ssoUrl,
            certificate
        });

        return this.organizationAdapter.adaptIdpToDomain(idp, issuer, ssoUrl);
    }

    public async deleteOrganizationIdentityProvider({
        id,
        organizationId
    }: {
        id: string;
        organizationId: string;
    }): Promise<void> {
        await this.identityRepository.deleteOrganizationIdentityProvider({
            id,
            organizationId
        });
    }

    public async deleteOrganizationIdentityProviders({ organizationId }: { organizationId: string }): Promise<void> {
        await this.identityRepository.deleteOrganizationIdentityProviders({
            organizationId
        });
    }

    public async getOrganizationIdentityProviders({
        organizationId
    }: {
        organizationId: string;
    }): Promise<IdentityProvider[] | null> {
        const idps = await this.identityRepository.getOrganizationIdentityProviders({
            organizationId
        });

        return idps?.map((idp) => this.organizationAdapter.adaptIdpToDomain(idp || null)) || null;
    }

    public async getOrganizationDomains({ organizationId }: { organizationId: string }): Promise<string[]> {
        const domains = await this.identityRepository.getOrganizationDomains({
            organizationId
        });

        if (!domains) {
            return [];
        }

        return domains;
    }

    public async updateOrganizationDomains({
        organizationId,
        domains
    }: {
        organizationId: string;
        domains: string[];
    }): Promise<string[]> {
        return await this.identityRepository.updateOrganizationDomains({
            organizationId,
            domains
        });
    }

    public async updateIdentityProviderDomains({
        domains,
        identityProviderId,
        organizationId
    }: {
        domains: string[];
        identityProviderId: string;
        organizationId: string;
    }): Promise<IdentityProvider> {
        const idp = await this.identityRepository.updateIdentityProviderDomains({
            domains,
            identityProviderId,
            organizationId
        });
        return this.organizationAdapter.adaptIdpToDomain(idp);
    }
}
