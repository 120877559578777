import { OptimizelyDecision, useDecision } from "@optimizely/react-sdk";
import { Flags } from "../../feature-flags/flags";

// Returns the full decision object with all the details.
// https://docs.developers.optimizely.com/experimentation/v4.0.0-full-stack/docs/optimizelydecision-react
//
// Properties include:
//   variationKey: string | null;
//   enabled: boolean;
//   variables: { [variableKey: string]: unknown };
//   ruleKey: string | null;
//   flagKey: string;
//   userContext: {
//       id: string | null;
//       attributes ?: { [name: string]: any };
//   };
//   reasons: string[];

export const useFeatureFlag = (feature: Flags): OptimizelyDecision => {
    const [decision] = useDecision(feature);
    return decision;
};
