import { BaseFieldProps } from "./BaseFieldProps";
import React from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";

export const WellKnownMetadataURLField = ({ disabled = false, onChange }: BaseFieldProps) => {
    return (
        <>
            <label className="oui-label push-double--top" htmlFor="well-known">
                Well Known Metadata URL
            </label>
            <input
                aria-describedby="well-known-description"
                className="oui-text-input"
                id="well-known"
                type="text"
                disabled={disabled}
                onChange={(e) => handleFieldChange({ e, onChange })}
            />
            <span className="oui-form-note" id="well-known-description">
                Publicly accessible endpoint that serves its OpenID configuration document
            </span>
        </>
    );
};

WellKnownMetadataURLField.displayName = "WellKnownMetadataURLField";
