import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Flags } from "../../../feature-flags/flags";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";

export const CustomRouting = ({ featureFlag }: { featureFlag: Flags }) => {
    const { enabled } = useFeatureFlag(featureFlag);

    if (!enabled) {
        return <Navigate to="/404" replace />;
    }

    return <Outlet />;
};
