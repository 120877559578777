import React, { useEffect } from "react";
import { Spinner, Typography } from "@optimizely/axiom";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";
import { CheckboxList, CheckboxListCheckbox } from "../CheckboxList/CheckboxList";

type SelectRoleAttributesProps = {
    onChange: ({
        preMappedValues,
        id,
        key,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => void;
    productId?: string;
    selectedAttributes?: RoleAttribute[];
};
export const SelectRoleAttributes = ({ onChange, productId, selectedAttributes = [] }: SelectRoleAttributesProps) => {
    const { attributes, isLoadingInitialData: isLoading } = useAttributes({
        isActive: true,
        productId
    });

    useEffect(() => {
        if (!isLoading && !attributes?.length) {
            // simulate a value to indicate there are no attributes to select from.
            onChange({ values: ["N/A"] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes, isLoading]);

    if (!productId) {
        return (
            <div className="push-triple--top">
                <Typography type="body">Select a product to see possible attributes.</Typography>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="push-triple--top flex justify-content-center">
                <Spinner />
            </div>
        );
    }

    if (!attributes?.length) {
        return (
            <div className="push-double--top">
                <Typography type="body">This product doesn't support role attributes.</Typography>
            </div>
        );
    }

    const emptyAttribute: { values: string[] } = {
        values: []
    };

    const handleSelectionChange = ({
        filterKey,
        id,
        key,
        values
    }: {
        filterKey: boolean;
        id: string;
        key: string;
        values: string[];
    }) => {
        if (filterKey) {
            onChange({ id, key, values: values.filter((v) => v !== key) });
        } else {
            onChange({ id, key, values });
        }
    };

    return (
        <>
            {attributes.map((attribute) => {
                const { id, key, values } = attribute;
                const correspondingSelection = selectedAttributes.find((attr) => attr.id === id) || emptyAttribute;
                const noValuesOrOnlyKeyValue = values.length <= 1;
                const selected =
                    correspondingSelection?.values?.filter((selection: any) => {
                        // only filter out the values when it's not one of the key / value scenarios. PS: I hate this.
                        return noValuesOrOnlyKeyValue ? selection : selection !== key;
                    }) || [];
                let checkboxes: CheckboxListCheckbox[] = [];
                const heading = {
                    heading: true,
                    text: key,
                    value: key,
                    checked: !!selected.length && (selected.length === values.length || selected.indexOf(key) > -1)
                };
                // handle child checkboxes
                if (values.length > 1 || (values.length === 1 && values[0] !== key)) {
                    checkboxes = values?.map((val) => {
                        return {
                            value: val,
                            text: val,
                            checked: selected.indexOf(val) > -1
                        };
                    });
                }
                checkboxes.unshift(heading);
                return (
                    <CheckboxList
                        items={checkboxes}
                        key={id}
                        onChange={({ values }) =>
                            handleSelectionChange({ filterKey: values.length > 1, values, id, key })
                        }
                    />
                );
            })}
        </>
    );
};

SelectRoleAttributes.displayName = "SelectRoleAttributes";
