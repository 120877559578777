import React, { useState } from "react";
import { Dropdown, Input, Typography } from "@optimizely/axiom";
import styles from "./FilterDropdown.module.scss";
import classnames from "classnames";

type FilterDropdownProps = {
    disabled?: boolean;
    dropdownPlaceholder: string;
    filterPlaceholder: string;
    items: { label: string; description?: string; key: string }[];
    label?: React.ReactNode;
    selectedText?: string;
    onItemSelected?: ({ item }: { item: { label: string; description?: string; key: string } }) => void;
};

export const FilterDropdown = ({
    items,
    disabled,
    dropdownPlaceholder,
    filterPlaceholder,
    label,
    selectedText,
    onItemSelected
}: FilterDropdownProps) => {
    const [filterString, setFilterString] = useState("");

    const filteredItems = !!filterString
        ? items?.filter(
              (g) =>
                  g.label.toLowerCase().includes(filterString.toLowerCase()) ||
                  g.description?.toLowerCase().includes(filterString.toLowerCase())
          )
        : items;

    const showFilterInput = (items?.length || 0) > 10;

    return (
        <div>
            {label && (
                <Typography type="body" className="oui-label">
                    {label}
                </Typography>
            )}
            <Dropdown
                className="admin-center__form-dropdown width--1-1"
                isDisabled={disabled}
                buttonContent={selectedText ? selectedText : dropdownPlaceholder}
                arrowIcon="down"
                fullWidth
            >
                <Dropdown.Contents>
                    {showFilterInput && (
                        <div className="oui-dropdown__item admin-center__dropdown-search-list-item">
                            <Input
                                isFilter
                                onClick={(e) => e.stopPropagation()}
                                onInput={(e) => {
                                    setFilterString(e.target.value);
                                }}
                                placeholder={filterPlaceholder}
                                type="search"
                            />
                        </div>
                    )}
                    {!filteredItems?.length && (
                        <Dropdown.ListItem
                            className={styles["filter-dropdown__search-list-first-item"]}
                            onClick={() => setFilterString("")}
                        >
                            <Typography type="body">
                                No results matching your search, please try a new search term
                            </Typography>
                        </Dropdown.ListItem>
                    )}
                    {filteredItems?.map((item, itemIndex) => {
                        const { description, label, key } = item;

                        const listItemClasses = classnames({
                            [styles["filter-dropdown__search-list-first-item"]]: itemIndex === 0 && showFilterInput
                        });

                        return (
                            <Dropdown.ListItem key={key} className={listItemClasses}>
                                <Dropdown.BlockLink
                                    onClick={() => {
                                        setFilterString("");

                                        onItemSelected && onItemSelected({ item });
                                    }}
                                >
                                    <Dropdown.BlockLinkText text={label} />
                                    <Dropdown.BlockLinkSecondaryText secondaryText={description} />
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};

FilterDropdown.displayName = "FilterDropdown";
