import { InstancePermission } from "./InstancePermission.interface";
import { INSTANCE_GROUP_PROPERTY_NAME } from "../constants/property-constants";
export class UserGroup {
    constructor(restUserGroup: any) {
        this.id = restUserGroup.Id;
        this.name = restUserGroup.Name;
        this.description = restUserGroup.Description;
        this.organizationId = restUserGroup.OrganizationId;
        this.groupOwner = restUserGroup.GroupOwner;
        this.groupType = restUserGroup.GroupType?.toLowerCase();
        this.userCount = restUserGroup.UserCount;
        this.properties = restUserGroup.Properties;
        this.instancePermissions = restUserGroup.InstancePermissions || [];
        this.created = restUserGroup.Created;
        this.modified = restUserGroup.Modified;
        this.users = restUserGroup.Users;
        this.isInstanceGroup =
            restUserGroup.Properties?.find((p: { name: string; value: any }) => p.name === INSTANCE_GROUP_PROPERTY_NAME)
                ?.value === "true";
    }

    id: string;
    isInstanceGroup: boolean;
    name: string;
    description: string;
    organizationId: string;
    groupOwner: string;
    groupType?: string; // "Custom" | "Product";
    userCount: number;
    properties: any[];
    instancePermissions: InstancePermission[];
    created: string;
    modified: string;
    users: string[];
}
