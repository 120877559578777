import React from "react";
import { OptiCommonHeader } from "../../components/OptiCommonHeader/OptiCommonHeader";

export const NotFound = () => {
    return (
        <>
            <OptiCommonHeader />
            <opti-error-404></opti-error-404>
        </>
    );
};

NotFound.displayName = "NotFound";
