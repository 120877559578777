import React, { useState } from "react";
import { BaseFieldProps } from "./BaseFieldProps";
import { handleFieldChange } from "./oidc-form-field-helpers";
import { Typography } from "@optimizely/axiom";
import { InputFieldError } from "../../../InputFieldError/InputFieldError";
import classnames from "classnames";

interface IssuerURLFieldProps extends BaseFieldProps {
    hideHelperText?: boolean;
}

export const IssuerURLField = ({ disabled, hideHelperText, onChange, required, value = "" }: IssuerURLFieldProps) => {
    const [showRequiredError, setShowRequiredError] = useState(false);
    // TODO: Create InputField component that handles this and labels and notes
    const inputClasses = classnames("oui-text-input", {
        "oui-form-bad-news": showRequiredError
    });

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange({ e, onChange });
        if (required && !e.target.value) {
            setShowRequiredError(true);
        } else if (showRequiredError) {
            setShowRequiredError(false);
        }
    };

    return (
        <>
            <label className="oui-label push-double--top" htmlFor="issuer">
                Issuer URL / Entity ID
                {required && !disabled && <span aria-label="(required)" className="oui-label--required" />}
            </label>
            <input
                aria-describedby="issuer-error"
                className={inputClasses}
                disabled={disabled}
                placeholder="Add the provided issuer URL / entity ID"
                id="issuer"
                type="text"
                value={value}
                onChange={onValueChange}
            />
            <span className="oui-form-note" id="well-known-description">
                URL of the Issuer Identifier
            </span>
            {!hideHelperText && (
                <Typography type="xs">
                    <div>The issuer may be a URL or ID from your IDP (Identity Provider)</div>
                    <div>(e.g. Microsoft AD: https://sts.windows.net/&#123;unique_id&#125;/)</div>
                    <div>(e.g. Okta: https://www.okta.com/&#123;unique_id&#125;)</div>
                    <div>(e.g. Entity ID from your IDP / SAML application)</div>
                </Typography>
            )}
            {showRequiredError && (
                <InputFieldError id="issuer-error">Issuer URL / Entity ID is required.</InputFieldError>
            )}
        </>
    );
};

IssuerURLField.displayName = "IssuerURLField";
