import { IIdentityRepository } from "../../repositories/IdentityRepository";
import { BillingNotification } from "../../rest-model/BillingNotification";

export interface INotificationsService {
    getBillingNotifications({ organizationId }: { organizationId: string }): Promise<BillingNotification[]>;
    updateBillingNotification({
        notificationId,
        organizationId,
        updates
    }: {
        notificationId: string;
        organizationId: string;
        updates: Partial<Pick<BillingNotification, "enabled" | "recipients">>;
    }): Promise<BillingNotification>;
}
export class NotificationService implements INotificationsService {
    identityRepository: IIdentityRepository;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
    }
    public async getBillingNotifications({
        organizationId
    }: {
        organizationId: string;
    }): Promise<BillingNotification[]> {
        const result = await this.identityRepository.getBillingNotifications({ organizationId });
        return result.Settings.billingNotifications || [];
    }

    public async updateBillingNotification({
        notificationId,
        organizationId,
        updates
    }: {
        notificationId: string;
        organizationId: string;
        updates: Pick<BillingNotification, "enabled" | "recipients">;
    }): Promise<BillingNotification> {
        return await this.identityRepository.updateBillingNotification({ notificationId, organizationId, updates });
    }
}
