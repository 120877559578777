import React from "react";
import { useUserContext } from "../../../providers/UserProvider";
import { useSession } from "../../../hooks/useSession/useSession";

export const OptiCommonHeader = () => {
    const { instanceId } = useUserContext();
    const { accessToken } = useSession();
    return (
        <div style={{ display: "block", height: 40, background: "#080736" }}>
            {accessToken && (
                <opti-common-header auth-token={accessToken} instance-id={instanceId} sticky></opti-common-header>
            )}
        </div>
    );
};

OptiCommonHeader.displayName = "OptiCommonHeader";
