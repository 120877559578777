import { IOrganizationProductInstance } from "../domain/OrganizationProduct";
import { IIdentityRepository } from "../repositories/IdentityRepository";
import { ProductInstanceAdapter } from "./ProductInstanceAdapter";
import { ProductInstance } from "../domain/ProductInstance";

export interface IInstanceService {
    updateInstance({ instance }: { instance: Partial<ProductInstance> }): Promise<ProductInstance>;
}

export class InstanceService implements IInstanceService {
    identityRepository: IIdentityRepository;
    productInstanceAdapter: ProductInstanceAdapter;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.productInstanceAdapter = new ProductInstanceAdapter();
    }

    public async updateInstance({
        instance
    }: {
        instance: Partial<IOrganizationProductInstance>;
    }): Promise<ProductInstance> {
        const restInstance = this.productInstanceAdapter.adaptToRest(instance);
        const updatedInstance = await this.identityRepository.updateInstance({ instance: restInstance });
        return this.productInstanceAdapter.adaptToDomain(updatedInstance);
    }
}
