import { useEffect } from "react";
import { useSession } from "../../../hooks/useSession/useSession";

export const Logout = () => {
    const { signOut } = useSession();

    useEffect(() => {
        signOut();
    });

    return null;
};
