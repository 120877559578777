import { User } from "../../domain/User";
import useSWRInfinite from "swr/infinite";
import { useEffect, useState } from "react";
import { Collection } from "../../domain/Collection";

export function useInfiniteList<T>(
    key: string | null,
    fetcher: (key: string | null, page: number) => Promise<Collection<T>>
) {
    const getKey = (pageIndex: number, previousPageData: Collection<User>) => {
        if (!key) return null;
        if (previousPageData && !previousPageData.items.length) return null; //reached the end
        return [`${key}?page=${pageIndex + 1}`, pageIndex + 1];
    };

    const [allData, setAllData] = useState<T[] | undefined>(undefined);

    const { data, size, setSize, error, mutate } = useSWRInfinite<Collection<T>>(
        getKey,
        async (key, page) => {
            return await fetcher(key, page);
        },
        { initialSize: 2, revalidateFirstPage: false }
    );

    useEffect(() => {
        setAllData(data ? data.flatMap((d) => d.items.map((i) => i)) : []);
    }, [data]);

    const noMoreData = data && data[data.length - 1]?.items.length === 0;
    const isLoadingInitialData = key && !data && !error;
    const isLoadingMore =
        !noMoreData && (isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined"));

    const loadNext = async () => {
        if (!noMoreData) {
            await setSize((s) => s + 1);
        }
    };

    const revalidate = async () => {
        await mutate();
    };

    return {
        allData,
        size,
        loadNext,
        noMoreData,
        isLoadingInitialData,
        isLoadingMore: !!isLoadingMore,
        revalidate,
        error
    };
}
