import React from "react";
import { OptiCommonHeader } from "../../components/OptiCommonHeader/OptiCommonHeader";

export const UnauthorizedWithGlobalBar = () => {
    return (
        <>
            <OptiCommonHeader />
            <opti-no-access></opti-no-access>
        </>
    );
};

UnauthorizedWithGlobalBar.displayName = "Unauthorized with global bar";
