import { BaseFieldProps } from "./BaseFieldProps";
import { URL_PATTERN } from "../../../../../constants/constants";
import { Typography } from "@optimizely/axiom";
import React, { useState } from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";
import { InputFieldError } from "../../../InputFieldError/InputFieldError";
import classnames from "classnames";

interface SSOURLFieldProps extends BaseFieldProps {
    hideHelperText?: boolean;
}
export const SSOURLField = ({
    disabled,
    hideHelperText,
    onChange,
    required = false,
    value: initialValue = ""
}: SSOURLFieldProps) => {
    const [value, setValue] = useState(initialValue);
    const [fieldError, setFieldError] = useState("");
    // TODO: Create InputField component that handles this and labels and notes
    const inputClasses = classnames("oui-text-input", {
        "oui-form-bad-news": !!fieldError
    });
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (required && !value) {
            setFieldError("SSO URL is required.");
            handleFieldChange({ e, onChange });
        } else if (value && !value.match(URL_PATTERN)) {
            setFieldError("SSO URL is not a valid url.");
        } else {
            setFieldError("");
            handleFieldChange({ e, onChange });
        }
        setValue(value);
    };

    return (
        <>
            <label className="oui-label push-double--top" htmlFor="sso">
                SSO URL
                {required && !disabled && <span aria-label="(required)" className="oui-label--required" />}
            </label>

            <input
                aria-describedby="sso-error"
                className={inputClasses}
                disabled={disabled}
                id="sso"
                placeholder="Add the provided SSO URL"
                type="text"
                onChange={onValueChange}
                value={value}
            />
            {!hideHelperText && (
                <Typography type="xs">
                    <div>Endpoint URL that you received from your Identity Provider (IDP)</div>
                    <div>(i.e. https://idp.example.com/&#123;unique_id&#125;/saml)</div>
                    <div>(i.e. https://idp.example.com/&#123;unique_id&#125;/saml&#123;version&#125;)</div>
                </Typography>
            )}
            {!!fieldError && <InputFieldError>{fieldError}</InputFieldError>}
        </>
    );
};

SSOURLField.displayName = "SSOURLField";
