import { UsageProductTabs } from "../../pages/Usage/UsageProductTabs";
import styles from "../../pages/Usage/Usage.module.scss";
import { Dashboard } from "@episerver/looker-dashboard";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";
import { ADMINCENTER_PRODUCT_ID } from "../../../constants/constants";
import { Typography } from "@optimizely/axiom";
import { useState } from "react";
import { useSession } from "../../../hooks/useSession/useSession";

type ProductUsageDashboardsProps = {
    enableActivityLog?: boolean;
    organizationId?: string;
    products?: OrganizationProduct[];
};

export const ProductUsageDashboards = ({
    enableActivityLog = false,
    organizationId,
    products = []
}: ProductUsageDashboardsProps) => {
    const productsWithDashboards = products?.filter((p) =>
        enableActivityLog ? p.hasUsageDashboard : p.hasUsageDashboard && p.id !== ADMINCENTER_PRODUCT_ID
    );
    const [productId, setProductId] = useState<string>("");
    const { accessToken } = useSession();

    if (!accessToken) {
        return null;
    }

    if (!productsWithDashboards?.length) {
        return (
            <div className="center">
                <Typography type="body">
                    None of your products currently have Usage & Billing enabled. Please contact your sales
                    representative for more information.
                </Typography>
            </div>
        );
    }

    const handleProductTab = ({ id }: { id: string }) => {
        setProductId(id);
    };

    return (
        <>
            <UsageProductTabs
                products={productsWithDashboards}
                productId={productId}
                onProductClick={handleProductTab}
            />
            {!!productId ? (
                <div className={styles["usage-page__no-data"]}>
                    <Dashboard organizationId={organizationId} accessToken={accessToken} productId={productId} />
                </div>
            ) : (
                <div className={styles["usage-page__no-data"]}>No available products to display usage data.</div>
            )}
        </>
    );
};
