import { useCallback } from "react";
import { UserGroup } from "../../domain/UserGroup";
import { useServicesContext } from "../../providers/ServicesProvider";
import { useInfiniteList } from "../useInfiniteList/useInfiniteList";
import { DEFAULT_PAGE_SIZE, SWR_KEY_PREFIX } from "../../constants/constants";

export const useGroupsByUser = ({ organizationId, email }: { organizationId: string | null; email?: string }) => {
    const { userService } = useServicesContext();

    const { allData, loadNext, isLoadingInitialData, isLoadingMore, revalidate } = useInfiniteList<UserGroup>(
        email && organizationId
            ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups?organizationId=${organizationId}&users=${email}`
            : null,
        useCallback(
            (key: string | null, page: number) =>
                userService.getUserGroups({
                    organizationId,
                    emails: [email!],
                    size: DEFAULT_PAGE_SIZE,
                    page
                }),
            [organizationId, email, userService]
        )
    );

    return {
        groups: allData,
        loadNext,
        isLoadingInitialData,
        isLoadingMore,
        revalidate
    };
};
