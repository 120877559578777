import { Collection } from "../../domain/Collection";

import { Invitation } from "../../domain/Invitation";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, EVENT_TYPES, INVITATION_STATUS } from "../../constants/constants";
import { useAnalyticsTracking } from "../useAnalyticsTracking/useAnalyticsTracking";
import { useLocation } from "react-router-dom";
import {
    ANALYTICS_EVENT_NAMES,
    ANALYTICS_FLOWS,
    ANALYTICS_TRACKED_COMPONENTS
} from "../../constants/analytics-constants";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";

type InvitationParameters = {
    pageSize?: number;
    organizationId?: string;
    inviteeEmail?: string;
    createdBy?: string;
};
export const useInvitations = (params: InvitationParameters) => {
    const { invitationService, userService } = useServicesContext();
    const { pathname } = useLocation();
    const { sendTrackEvent } = useAnalyticsTracking();
    const { fireChangeEvent } = useUpdateSync();
    const { organizationId, inviteeEmail, createdBy, pageSize } = params;
    let swrKey = organizationId ? `/api/invitations?organizationId=${params?.organizationId}` : null;
    if (inviteeEmail || createdBy) {
        swrKey = `/api/invitations?inviteeEmail=${inviteeEmail}&createdBy=${createdBy}`;
    }
    const { pageData, error, currentPage, setCurrentPage, mutate } = usePaginatedList<Invitation>({
        key: swrKey,
        fetcher: (): Promise<Collection<Invitation>> => {
            return invitationService!.getInvitations({
                organizationId: params?.organizationId as string,
                ...params,
                size: pageSize || DEFAULT_PAGE_SIZE,
                page: currentPage
            });
        }
    });

    const resendInvitation = async ({ invitationId }: { invitationId: string }) => {
        await invitationService!.resendInvitation({ invitationId });

        //TODO: bring this into UpdateSynchronizer
        await mutate();
    };

    const revokeInvitation = async ({
        invitationId,
        requireAcceptance = true
    }: {
        invitationId: string;
        requireAcceptance?: boolean;
    }) => {
        await invitationService!.revokeInvitation({ invitationId, requireAcceptance });
        const revokedInvitation = pageData?.items?.find((invite) => invite.id === invitationId);

        const updateInvitations = () => {
            revokedInvitation && (revokedInvitation.status = INVITATION_STATUS.REVOKED);
            //TODO: bring this into UpdateSynchronizer
            pageData?.items && mutate({ items: [...pageData.items], totalCount: pageData.totalCount });
        };

        pageData && updateInvitations();
    };

    // GET request, not bound to SWR hook, should not be cached since we need the up to the second value
    const userExistsInOrg = async ({ email }: { email: string }): Promise<boolean> => {
        const collection = await userService.getUsers({
            organizationId: organizationId as string,
            email,
            includeExternalStatus: false
        });

        const [returnedUser] = collection.items;

        return !!returnedUser;
    };

    const createInvitation = async (invitation: Partial<Invitation>) => {
        const userExists = await userExistsInOrg({ email: invitation.email! });

        const newInvitation = await invitationService!.createInvitation(invitation);

        if (!userExists) {
            const isProductAccessFlow = pathname.includes("/access/");
            sendTrackEvent({
                name: ANALYTICS_EVENT_NAMES.ADD_USER,
                component: ANALYTICS_TRACKED_COMPONENTS.INVITATION_SIDEBAR,
                flow: isProductAccessFlow ? ANALYTICS_FLOWS.PRODUCT_ACCESS : ANALYTICS_FLOWS.USER_PAGE,
                data: { affectedUserId: invitation.email }
            });
        }

        if (newInvitation?.email) {
            fireChangeEvent({
                type: EVENT_TYPES.ADD_USER_TO_GROUP,
                data: {
                    users: [
                        {
                            email: newInvitation.email
                        }
                    ],
                    groups: newInvitation.userGroups?.map((group) => ({ id: group.id }))
                }
            });
        }

        return newInvitation;
    };

    return {
        invitations: pageData?.items,
        createInvitation,
        isLoading: !error && !pageData,
        error,
        mutate,
        currentPage,
        setCurrentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        totalCount: pageData?.totalCount || 0,
        resendInvitation,
        revokeInvitation
    };
};
