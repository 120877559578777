import React from "react";
import { Disclose, Table } from "@optimizely/axiom";
import { InstanceRole } from "../../../../domain/InstanceRole.interface";
import { AccessDetailsTableRow } from "./AccessDetailsTableRow";

export type AccessDetailsRow = {
    groupId?: string;
    groupName?: string;
    isCustomGroup: boolean;
    instanceName: string;
    nickname?: string;
    projectId?: string;
    projectName?: string;
    roles: InstanceRole[];
};

export type AccessDetailsByInstanceId = { [instanceId: string]: AccessDetailsRow[] };

export type ProductAccess = { productName: string; instanceAccess: AccessDetailsByInstanceId };

export type AccessDetailsByProductId = { [productId: string]: ProductAccess };

export const AccessDetailsTable = ({
    accessDetails,
    onRemove
}: {
    accessDetails: AccessDetailsByProductId;
    onRemove?: ({ accessDetailsRow }: { accessDetailsRow: AccessDetailsRow }) => void;
}) => {
    return (
        <div>
            {Object.keys(accessDetails).map((productId) => {
                const { productName, instanceAccess } = accessDetails[productId];
                const showProjectColumn = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

                return (
                    <Disclose
                        key={`disclose-header-${productId}`}
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                }}
                            >
                                <span>{productName}</span>
                            </div>
                        }
                    >
                        <div className="push-triple--left">
                            <Table
                                className=""
                                density="tight"
                                shouldAddHover={false}
                                // eslint-disable-next-line
                                style="rule-no-bottom-border"
                                tableLayoutAlgorithm="auto"
                                key={`table-${productId}`}
                            >
                                <Table.THead key={`table-head-${productId}`}>
                                    <Table.TR>
                                        <Table.TH width={showProjectColumn ? "30%" : "60%"}>Instance</Table.TH>
                                        {showProjectColumn && <Table.TH width="30%">Project</Table.TH>}
                                        <Table.TH width={"40%"}>Role(s)</Table.TH>
                                    </Table.TR>
                                </Table.THead>
                                <Table.TBody key={`table-body-${productId}`}>
                                    {Object.keys(instanceAccess).flatMap((instanceId) => {
                                        return instanceAccess[instanceId].map((instanceRow) => {
                                            return (
                                                <AccessDetailsTableRow
                                                    access={instanceRow}
                                                    showProjectColumn={showProjectColumn}
                                                    key={instanceId}
                                                    onRemove={onRemove}
                                                />
                                            );
                                        });
                                    })}
                                </Table.TBody>
                            </Table>
                        </div>
                    </Disclose>
                );
            })}
        </div>
    );
};

AccessDetailsTable.displayName = "AccessDetailsTable";
