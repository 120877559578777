import React, { useContext } from "react";

import { IUserService } from "../services/UserService";
import { IAttributeService } from "../services/AttributeService";
import { IInstanceService } from "../services/InstanceService";
import { IInvitationService } from "../services/InvitationService";
import { ICustomerService } from "../services/CustomerService";
import { IIdpService } from "../services/Idp/IdpService";
import { IOrganizationService } from "../services/Organization/OrganizationService";
import { INotificationsService } from "../services/Notifications/NotificationsService";

interface IServicesContext {
    attributeService?: IAttributeService;
    customerService?: ICustomerService;
    idpService?: IIdpService;
    instanceService?: IInstanceService;
    invitationService?: IInvitationService;
    notificationsService?: INotificationsService;
    organizationService?: IOrganizationService;
    userService: IUserService;
}

export const ServicesContext = React.createContext<IServicesContext | null>(null);

export function useServicesContext() {
    // TODO: is it possible to avoid the non-null assertion here?
    return useContext(ServicesContext)!;
}

export const ServicesProvider = ({ children, services }: { children: React.ReactNode; services: IServicesContext }) => {
    return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
};
