import { RoleAttribute as Attribute } from "./RoleAttribute.interface";
import { IOrganizationProductInstance, OrganizationProduct } from "./OrganizationProduct";

export type RoleCreationParameters = {
    attributes?: Attribute[];
    description?: string;
    id?: string;
    instances: IOrganizationProductInstance[];
    name: string;
    organizationId: string;
    product: OrganizationProduct;
    roleType?: string;
};

export class Role {
    constructor({
        id,
        isInstanceRole,
        created,
        name,
        displayName,
        description,
        productId,
        roleType,
        targetIds,
        attributes,
        modified,
        isActive,
        isSystemGeneratedRole
    }: {
        created: Date;
        isInstanceRole?: boolean;
        id: string;
        name: string;
        displayName: string;
        description: string;
        productId: string;
        roleType: string;
        targetIds: string[];
        attributes: Attribute[];
        modified: Date;
        isActive: boolean;
        isSystemGeneratedRole?: boolean;
    }) {
        this.created = created;
        this.isInstanceRole = isInstanceRole || false;
        this.id = id;
        this.name = name;
        this.displayName = displayName || name;
        this.description = description;
        this.productId = productId;
        this.roleType = roleType;
        this.targetIds = targetIds;
        this.attributes = attributes;
        this.modified = modified;
        this.isActive = isActive;
        this.isSystemGeneratedRole = !!isSystemGeneratedRole;
    }

    created: Date;
    id: string;
    isInstanceRole: boolean;
    name: string;
    displayName: string;
    description: string;
    productId: string;
    roleType: string;
    targetIds: string[];
    attributes: Attribute[];
    modified: Date;
    isActive: boolean;
    isSystemGeneratedRole?: boolean;
}
