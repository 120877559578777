import { InstanceRole } from "./InstanceRole.interface";

// flattened structure of a product instance
export class ProductInstance {
    constructor({
        instanceId,
        instanceName,
        nickname,
        optiIdEnabled,
        productId,
        productName,
        region = "N/A",
        roles,
        technicalContactEmail,
        technicalContactName
    }: {
        instanceId: string;
        instanceName: string;
        nickname: string;
        optiIdEnabled?: boolean;
        productId: string;
        productName: string;
        region?: string;
        roles: InstanceRole[];
        technicalContactEmail?: string;
        technicalContactName?: string;
    }) {
        this.instanceId = instanceId;
        this.instanceName = instanceName;
        this.nickname = nickname;
        this.optiIdEnabled = optiIdEnabled;
        this.productId = productId;
        this.productName = productName;
        this.region = region;
        this.roles = roles;
        this.technicalContactEmail = technicalContactEmail;
        this.technicalContactName = technicalContactName;
    }

    instanceId: string;
    instanceName: string;
    nickname: string | null | undefined;
    optiIdEnabled?: boolean;
    productId: string;
    productName: string;
    region?: string;
    roles: InstanceRole[];
    technicalContactEmail?: string;
    technicalContactName?: string;
}
