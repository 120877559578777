import React from "react";
import { Dropdown } from "@optimizely/axiom";
import { ALL, GROUP_TYPES } from "../../../constants/constants";
import { capitalizeText } from "../../../lib/utils";

type UserGroupFilterDropdownProps = {
    hideProductGroup?: boolean; // TODO: Remove when feature flag `hide_product_groups` is deleted
    selectedGroupType: string;
    selectGroupType: React.Dispatch<React.SetStateAction<string>>;
};

export const UserGroupFilterDropdown = ({
    hideProductGroup,
    selectedGroupType,
    selectGroupType
}: UserGroupFilterDropdownProps) => {
    const allowedFilter = hideProductGroup
        ? [GROUP_TYPES.CUSTOM, GROUP_TYPES.INTERNAL]
        : [GROUP_TYPES.CUSTOM, GROUP_TYPES.INTERNAL, GROUP_TYPES.PRODUCT];
    const groupTypes = allowedFilter.map((groupType) => capitalizeText(groupType));
    return (
        /* eslint-disable react/style-prop-object */
        <Dropdown
            buttonContent={{
                label: "Group Type",
                content: selectedGroupType
            }}
            style="plain"
            arrowIcon="down"
            width={350}
        >
            <Dropdown.Contents>
                <Dropdown.ListItem role="option" key={ALL}>
                    <Dropdown.BlockLink isLink={selectedGroupType !== ALL} onClick={() => selectGroupType(ALL)}>
                        <Dropdown.BlockLinkText text={ALL} />
                    </Dropdown.BlockLink>
                </Dropdown.ListItem>

                {groupTypes.map((groupType) => {
                    return (
                        <Dropdown.ListItem role="option" key={groupType}>
                            <Dropdown.BlockLink
                                isLink={selectedGroupType !== groupType}
                                onClick={() => selectGroupType(groupType)}
                            >
                                <Dropdown.BlockLinkText text={groupType} />
                            </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                    );
                })}
            </Dropdown.Contents>
        </Dropdown>
    );
};

UserGroupFilterDropdown.displayName = "UserGroupFilterDropdown";
