import React from "react";
import { UserRoleContext, useUserContext } from "../../../providers/UserProvider";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";

const LimitByRole = ({
    children,
    mode = "hide",
    action,
    context
}: {
    children: React.ReactNode;
    mode?: "hide" | "disable" | "unauthorized";
    action: string | string[];
    context?: UserRoleContext;
}) => {
    const { canUserDoAction } = useUserContext();

    const userCanDoAction = canUserDoAction({ action, context });

    if (mode === "disable" && !userCanDoAction) {
        const disabledChildren = React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                // @ts-ignore
                return React.cloneElement(child, { disabled: true });
            }
            return child;
        });

        return disabledChildren ? <>{disabledChildren}</> : <>{children}</>;
    } else if (mode === "hide") {
        return <>{userCanDoAction && children}</>;
    } else if (mode === "unauthorized" && !userCanDoAction) {
        return <Unauthorized />;
    } else {
        return <>{children}</>;
    }
};

export default React.memo(LimitByRole);

LimitByRole.displayName = "LimitByRole";
