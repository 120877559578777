import React, { ReactElement, useEffect, useRef, useState } from "react";

export const InfiniteScrollList = ({
    children,
    loadNext,
    isLoadingMore,
    loadingItem
}: {
    children: React.ReactNode;
    loadNext: () => void;
    isLoadingMore?: boolean;
    loadingItem: ReactElement;
}): React.ReactElement => {
    const [lastElement, setLastElement] = useState<HTMLElement | null>();

    const observer = useRef(
        new IntersectionObserver(async (entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                await loadNext();
            }
        })
    );

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    return (
        <>
            {React.Children.map(children, (child: any, index: number) => {
                if (index === React.Children.count(children) - 1) {
                    return React.cloneElement(child, { ref: setLastElement });
                } else {
                    return child;
                }
            })}
            {isLoadingMore && loadingItem}
        </>
    );
};
