import React from "react";
import classnames from "classnames";
import styles from "./Skeleton.module.scss";

type SkeletonProps = {
    height?: string;
    shape?: "rectangle";
    width?: string;
    className?: string;
};
export const Skeleton = ({
    shape = "rectangle",
    width = "120px",
    height = "40px",
    className = "display--block"
}: SkeletonProps) => {
    const classes = classnames(styles["skeleton"], {
        [styles["skeleton--rectangle"]]: shape === "rectangle"
    });
    return <span className={`${classes} ${className}`} style={{ width, height }}></span>;
};
