import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useServicesContext } from "./ServicesProvider";
import useSWR, { KeyedMutator } from "swr";
import { IUserInstancePermission } from "../domain/UserInstancePermission";
import { User } from "../domain/User";
import { useSession } from "../hooks/useSession/useSession";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { optimizelyInstance } from "../feature-flags/optimizelyInstance";
import { Flags } from "../feature-flags/flags";
import { IAccessContext } from "../services/UserService";
import { allowScope, getUserScopedAttributes, hasScopedAccess } from "../lib/scope-check";
import { Role } from "../domain/Role";
import { SWR_KEY_PREFIX } from "../constants/constants";
import { useOrganization } from "../hooks/useOrganization/useOrganization";

export type UserRoleContext = { [key: string]: string | undefined | null };
export type UserActionProps = {
    action: string | string[];
    context?: UserRoleContext;
    roles?: Role[];
};
interface IUserContext {
    currentInstance: IUserInstancePermission;
    profile: User | null | undefined;
    hasAdminAccess: boolean;
    isAdminToUserOrg: boolean;
    homeOrganizationId?: string;
    instanceId?: string;
    instancePermissions?: IUserInstancePermission[] | null | undefined;
    organizationId?: string;
    organizationName?: string;
    error: Error | null;
    isLoading: boolean;
    refreshPermissions: KeyedMutator<IUserInstancePermission[] | null>;
    canUserDoAction: (props: UserActionProps) => boolean;
    accessContext?: IAccessContext;
    setAccessContext: React.Dispatch<React.SetStateAction<IAccessContext | undefined>>;
}

export const UserContext = React.createContext<IUserContext | null>(null);

export function useUserContext() {
    // TODO: is it possible to avoid the non-null assertion here?
    return useContext(UserContext)!;
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchParams] = useSearchParams();
    const { userService } = useServicesContext();
    const { user } = useSession();

    const [accessContext, setAccessContext] = useState<IAccessContext | undefined>(undefined);
    const [organizationId, setOrganizationId] = useState<string>("");
    const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
    const { organization } = useOrganization({ id: organizationId });

    const urlOrgId = searchParams.get("orgId");
    const { data: profile, error: profileError } = useSWR<User | null, Error>(
        user?.email && `${SWR_KEY_PREFIX.USERS}/api/users/${user?.email}`,
        () => {
            if (user?.email) {
                return userService.getUserByEmail(user?.email);
            }
            return null;
        }
    );

    const { data: instancePermissions, mutate } = useSWR<IUserInstancePermission[] | null, Error>(
        user?.email ? `${SWR_KEY_PREFIX.INSTANCES}/api/users/${user?.email}/instancepermissions` : null,
        () => {
            if (!user?.email) {
                return null;
            }
            return userService.getUserInstancePermissions({ email: user?.email });
        }
    );

    const { data: adminCenterInstancePermissions, error: permissionsError } = useSWR<IUserInstancePermission[], Error>(
        user?.email && organizationId
            ? `${SWR_KEY_PREFIX.INSTANCES}/api/users/${user?.email}/instancepermissions/${organizationId}`
            : null,
        () => {
            // unlikely to happen since SWR won't fire this unless email and orgId are truthy,
            // but Typescript needs to know we're not passing an undefined email to the method below
            if (!user?.email) {
                return [];
            }

            return userService.getUserAdminCenterInstancePermissions({
                email: user.email,
                orgId: organizationId || undefined
            });
        }
    );

    const [currentInstance] = adminCenterInstancePermissions || [];
    const isAdminToUserOrg = currentInstance?.organizationId === profile?.homeOrganizationId;

    /**
     * @param {string}  action - A string or array of strings representing a potential action(s) (matching a key on a role attribute).
     * This is used to find and determine whether the action is allowed based on the user's roles.
     * @param {UserRoleContext=} context - Optional map that provides the context for the action being taken.
     * Available map keys: "Owner", "Role Owner", "ProductId", "InstanceId", "AttributeId", etc. as defined from the role attributes from the instance permission
     * @returns {boolean} Returns true when any of the actions is allowed in the provided scope for the user, otherwise false
     */
    const canUserDoAction = ({ action, context, roles = currentInstance?.roles }: UserActionProps): boolean => {
        const decision = optimizelyInstance.decide(Flags.USE_ATTRIBUTE_PERMISSIONS);
        const { enabled: useAttributeFlagEnabled }: { enabled: boolean } = decision;

        if (!useAttributeFlagEnabled) {
            return true;
        }

        if (Array.isArray(action)) {
            return action.some((a) => canUserDoAction({ action: a, context }));
        }

        const scopedAttributes = roles && getUserScopedAttributes(roles);

        if (!scopedAttributes[action]) return false;

        const { highestScope, allScopes } = scopedAttributes[action];

        if (context?.scopedAccess) return hasScopedAccess(highestScope, context.scopedAccess);

        // Do we really want to grant Super Admin level access for when the scopes property is undefined?
        // Could lead to security issues if a role is improperly configured
        const scopes = Array.from(allScopes).map((scope) => JSON.parse(scope));

        return context
            ? scopes.length === 0
                ? true
                : scopes.some((scope: { name: string; value: string }) =>
                      allowScope({ scope, context, email: profile?.email })
                  )
            : true;
    };

    useEffect(() => {
        setIsAppLoading(
            !(permissionsError || profileError) &&
                (!adminCenterInstancePermissions || !profile || !user || !organization)
        );
    }, [permissionsError, profileError, adminCenterInstancePermissions, profile, user, organization]);

    useEffect(() => {
        if (profile?.homeOrganizationId || urlOrgId) {
            const sessionOrg = sessionStorage.getItem("admincenter:orgId") || profile?.homeOrganizationId;

            if (urlOrgId && urlOrgId !== organizationId) {
                setOrganizationId(urlOrgId);
                sessionStorage.setItem("admincenter:orgId", urlOrgId);
            } else if (sessionOrg && sessionOrg !== organizationId) {
                setOrganizationId(sessionOrg);
            }
        }
    }, [urlOrgId, profile?.homeOrganizationId, organizationId]);

    const providerValue = {
        profile,
        hasAdminAccess: !!adminCenterInstancePermissions?.length,
        isAdminToUserOrg,
        homeOrganizationId: profile?.homeOrganizationId,
        instanceId: adminCenterInstancePermissions?.length ? adminCenterInstancePermissions[0].instanceId : undefined,
        currentInstance,
        instancePermissions,
        organizationId,
        organizationName: adminCenterInstancePermissions?.length
            ? adminCenterInstancePermissions[0].organizationName
            : "User does not have Admin Center access to this organization",
        error: permissionsError ?? profileError ?? null,
        isLoading: isAppLoading,
        refreshPermissions: mutate,
        canUserDoAction,
        accessContext,
        setAccessContext
    };

    return (
        <UserContext.Provider value={providerValue}>
            <OptimizelyProvider
                optimizely={optimizelyInstance}
                user={{
                    id: profile?.id || null,
                    attributes: {
                        organization_id: organizationId,
                        instance: currentInstance || null,
                        email: profile?.email || null
                    }
                }}
            >
                {children}
            </OptimizelyProvider>
        </UserContext.Provider>
    );
};
