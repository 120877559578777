import { ANALYTICS_EVENT_NAMES, ANALYTICS_FLOWS, ANALYTICS_TRACKED_COMPONENTS } from "../constants/analytics-constants";
import { TrackingComponent, TrackingFlow } from "../hooks/useAnalyticsTracking/useAnalyticsTracking";

export const getGrantPermissionsPayload = ({
    component = ANALYTICS_TRACKED_COMPONENTS.USER_SIDEBAR,
    email,
    flow,
    projectIds = [],
    projectNames = [],
    userGroupIds,
    userGroupNames
}: {
    component?: TrackingComponent; // TODO: Move type somewhere else outside of the hook...
    email: string;
    flow: TrackingFlow;
    projectIds?: string[];
    projectNames?: string[];
    userGroupIds: string[];
    userGroupNames: string[];
}) => {
    const data = {
        affectedUserId: email,
        projectIds,
        projectNames,
        userGroupIds: userGroupIds,
        userGroupNames: userGroupNames
    };
    return {
        name: ANALYTICS_EVENT_NAMES.GRANT_PERMISSIONS,
        component,
        flow,
        data
    };
};

export const getCreateCustomGroupPayload = ({
    instanceIds,
    instanceNames,
    roleIds,
    roleNames
}: {
    instanceIds: string[];
    instanceNames: string[];
    roleIds: string[];
    roleNames: string[];
}) => {
    const data = {
        instanceIds,
        instanceNames,
        roleNames,
        roleIds
    };

    return {
        name: ANALYTICS_EVENT_NAMES.CREATE_CUSTOM_GROUP,
        flow: ANALYTICS_FLOWS.GROUP_ACCESS,
        component: ANALYTICS_TRACKED_COMPONENTS.CREATE_GROUP_SIDEBAR,
        data
    };
};

export const getRemoveUserPayload = ({
    component = ANALYTICS_TRACKED_COMPONENTS.USERS_TAB,
    email,
    flow
}: {
    component?: TrackingComponent; // TODO: Move type somewhere else outside of the hook...
    email: string;
    flow: TrackingFlow;
}) => {
    const data = {
        affectedUserId: email
    };
    return {
        name: ANALYTICS_EVENT_NAMES.REMOVE_USER,
        component,
        flow,
        data
    };
};

export const getCreateCustomRolePayload = ({
    component = ANALYTICS_TRACKED_COMPONENTS.CREATE_CUSTOM_ROLE_SIDEBAR,
    flow = ANALYTICS_FLOWS.ROLES_PAGE,
    productId,
    roleProductName,
    instanceIds,
    instanceNames,
    roleId,
    roleName
}: {
    component?: TrackingComponent; // TODO: Move type somewhere else outside of the hook...
    flow: TrackingFlow;
    productId: string;
    roleProductName: string;
    instanceIds: string[];
    instanceNames: string[];
    roleName: string;
    roleId: string;
}) => {
    const data = {
        productId: productId,
        roleProductName: roleProductName,
        instanceIds: instanceIds,
        instanceNames: instanceNames,
        roleId: roleId,
        roleName: roleName
    };
    return {
        name: ANALYTICS_EVENT_NAMES.CREATE_CUSTOM_ROLE,
        component,
        flow,
        data
    };
};
