import { UserProperties } from "./UserProperties.interface";
export class User {
    constructor({
        id,
        firstName,
        lastName,
        email,
        externalStatus,
        homeOrganizationId,
        userGroupIds,
        created,
        lastLoggedIn,
        properties
    }: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        externalStatus: string;
        homeOrganizationId: string;
        userGroupIds: string[];
        created: Date;
        lastLoggedIn: Date;
        properties?: UserProperties[] | undefined;
    }) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.externalStatus = externalStatus;
        this.homeOrganizationId = homeOrganizationId;
        this.userGroupIds = userGroupIds;
        this.groupCount = this.userGroupIds.length;
        this.created = created;
        this.lastLoggedIn = lastLoggedIn;
        this.properties = properties;
    }

    id: string;
    firstName: string;
    lastName: string;
    email: string;
    externalStatus: string;
    homeOrganizationId: string;
    groupCount: number;
    userGroupIds: string[];
    created: Date;
    lastLoggedIn: Date;
    properties?: UserProperties[];
}
