import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import React, { useEffect, useState } from "react";
import { Skeleton } from "../Skeleton/Skeleton";

export const ProjectDisplayName = ({ attributeId }: { attributeId: string }) => {
    const { getAttribute } = useAttributes({});
    const [isLoading, setIsLoading] = useState(false);
    const [projectName, setProjectName] = useState<string>();

    useEffect(() => {
        if (projectName || isLoading) return;

        setIsLoading(true);
        getAttribute(attributeId)
            .then((att) => {
                setProjectName(att.name);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [attributeId, getAttribute, projectName, isLoading]);

    return isLoading ? (
        <Skeleton
            className="display--inline-block push--sides soft--top"
            key={attributeId}
            height="17px"
            width="100px"
        />
    ) : (
        <>{projectName}</>
    );
};

ProjectDisplayName.displayName = "ProjectDisplayName";
