import React, { ReactElement } from "react";
import styles from "./SidebarFooter.module.scss";
import classnames from "classnames";
import { Button, ButtonRow } from "@optimizely/axiom";

export const SidebarFooter = ({
    cancelStyle = "outline-reverse",
    className,
    children: childElements,
    leftSide,
    onCancel
}: {
    cancelStyle?: string;
    className?: string;
    children?: ReactElement | ReactElement[];
    leftSide?: ReactElement[];
    onCancel?: any;
}) => {
    const classes = classnames("soft-double--ends soft-quad--sides", styles["sidebar-footer"], className);

    let children = Array.isArray(childElements) ? childElements : [childElements].filter((e) => !!e);
    if (onCancel) {
        children = [
            <Button onClick={onCancel} key="cancel-button" style={cancelStyle}>
                Cancel
            </Button>,
            ...children
        ];
    }

    return <ButtonRow className={classes} rightGroup={children} leftGroup={leftSide} />;
};

SidebarFooter.displayName = "SidebarFooter";
