import { DUPLICATE_GROUP_ERROR_MESSAGE, DUPLICATE_EMAIL_ERROR_MESSAGE } from "../constants/constants";

export interface IApiError {
    field: string;
    code: string;
    message: string;
}

interface IFormError {
    field: string;
    message: string;
    name: string;
}

export const adaptApiErrors = (apiErrors: IApiError[]): IFormError[] => {
    const mappedErrors = (apiErrors || []).map((error) => {
        const { code, field, message: originalError } = error;
        const isDuplicate = code.toLowerCase() === "duplicate";
        const duplicateGroup = field.toLowerCase() === "name" && isDuplicate;
        const duplicateEmail = field.toLowerCase() === "email" && isDuplicate;

        if (duplicateGroup) {
            return {
                field: "name",
                name: "name",
                message: DUPLICATE_GROUP_ERROR_MESSAGE
            };
        }

        if (duplicateEmail) {
            return {
                field: "email",
                name: "apiError",
                message: DUPLICATE_EMAIL_ERROR_MESSAGE
            };
        }

        return {
            field,
            name: "apiError",
            message: originalError
        };
    });

    return mappedErrors;
};
