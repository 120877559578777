import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { BaseSyntheticEvent, useState } from "react";
import { Checkbox } from "@optimizely/axiom";
import { UNSAVED_CHANGES_CONFIRMATION_DO_NOT_SHOW_AGAIN } from "../../../constants/constants";
import { useAccessFlowUserFormContext } from "../Access/AccessManagementUserForm/AccessManagementUserFormProvider";

export const UnsavedChangesDialog = () => {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);
    const { onConfirm, showConfirmation, setShowConfirmation } = useAccessFlowUserFormContext();
    const toggleDoNotShowThisAgain = (change: BaseSyntheticEvent) => {
        setDoNotShowAgain(change.target.checked);
    };

    const handleConfirmation = () => {
        if (doNotShowAgain) {
            localStorage.setItem(UNSAVED_CHANGES_CONFIRMATION_DO_NOT_SHOW_AGAIN, "true");
        }
        onConfirm();
    };

    if (!showConfirmation) {
        return null;
    }

    return (
        <ConfirmationDialog
            additionalActions={<Checkbox onChange={toggleDoNotShowThisAgain} label="Do not show this again" />}
            title="Unsaved Changes"
            onConfirmation={handleConfirmation}
            onCancel={() => setShowConfirmation(false)}
        >
            You have some unsaved changes for this user. If you continue, you will lose these changes.
        </ConfirmationDialog>
    );
};
