import React from "react";
import { ProgressDots, Spinner } from "@optimizely/axiom";

type LoadingIndicatorProps = {
    height?: string;
    overlay?: boolean;
    type?: "dots" | "spinner";
    logo?: boolean;
};

export const LoadingIndicator = ({
    height = "100vh",
    overlay = false,
    type = "dots",
    logo = false
}: LoadingIndicatorProps) => {
    const position = overlay ? "absolute" : undefined;
    const width = overlay ? "100%" : undefined;
    const background = overlay ? "rgba(255, 255, 255, 0.75)" : undefined;
    const top = overlay ? "0" : undefined;
    const left = overlay ? "0" : undefined;

    return (
        <div
            className="flex flex--column flex-align--center flex-justified--center"
            role="alert"
            aria-busy="true"
            style={{ height, width, position, background, top, left }}
        >
            {logo && <img alt="Optimizely" src="/logo.png" />}
            {type === "dots" && <ProgressDots />}
            {type === "spinner" && <Spinner />}
        </div>
    );
};

LoadingIndicator.displayName = "LoadingIndicator";
