import React, { useEffect, useRef, useState } from "react";
import { Attention, Button, Typography } from "@optimizely/axiom";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useForm, useFormState } from "react-hook-form";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { IInstanceFormValues } from "./IInstanceFormValues";
import { OptiIDEnabledCheckbox } from "../OptiIDEnabledCheckbox/OptiIDEnabledCheckbox";
import { emitToast } from "../../../lib/toaster-utils";

type InstanceFormProps = {
    disableEditing?: boolean;
    instance: IOrganizationProductInstance;
    onClose: ({ updatedInstance }: { updatedInstance?: IOrganizationProductInstance | undefined }) => void;
    onSubmit: ({ instance }: { instance: IOrganizationProductInstance }) => any;
};

export const InstanceForm = ({ disableEditing, instance, onClose, onSubmit }: InstanceFormProps) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [optiEnabled, setOptiEnabled] = useState(false);
    const instanceIdRef = useRef<string | null>(null);
    const { register, handleSubmit, getValues, setValue, control } = useForm<IInstanceFormValues>({
        mode: "onChange",
        defaultValues: {
            nickname: instance?.nickname
        }
    });

    const [technicalContact, setTechnicalContact] = useState({
        email: instance.technicalContactEmail,
        name: instance.technicalContactName
    });

    useEffect(() => {
        if (instance.id !== instanceIdRef.current) {
            setEditing(false);
            instanceIdRef.current = instance.id;
        }
    }, [instance]);

    const { isDirty } = useFormState({
        control
    });

    const toggleOptiEnabled = () => {
        setOptiEnabled((curr) => {
            return !curr;
        });
    };

    const handleFormSubmission = async () => {
        if (!editing) {
            setEditing(true);
            setValue("nickname", instance?.nickname || "");
            return;
        }

        setLoading(true);
        const { nickname } = getValues();
        const updatedInstance: IOrganizationProductInstance = {
            ...instance,
            nickname,
            technicalContactEmail: technicalContact.email,
            technicalContactName: technicalContact.name
        };

        if (optiIdEnabled || optiEnabled) {
            updatedInstance["optiIdEnabled"] = true;
        } else {
            updatedInstance["optiIdEnabled"] = false;
        }
        onSubmit({ instance: updatedInstance })
            .then((_: any) => {
                setEditing(false);
                // close the dialog
                onClose({ updatedInstance });
                emitToast({ message: "This instance has been successfully updated." });
            })
            .catch((e: any) => {
                // TODO: Display Error
                // TODO: DD?
                console.error(e);
                setErrorMessage("An error occurred. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let actionText = editing ? "Save" : "Edit";

    const formDirty = isDirty || technicalContact.email !== instance?.technicalContactEmail || optiEnabled;

    const { optiIdEnabled, nickname, technicalContactName, technicalContactEmail, id } = instance || {};

    const bothTechnicalContactFields = technicalContactEmail && technicalContactName;
    const contact = technicalContactName || technicalContactEmail || "None";

    return (
        <form onSubmit={handleSubmit(handleFormSubmission)}>
            <div className="push-quad--sides">
                {!!errorMessage && (
                    <Attention
                        alignment="left"
                        className="push-triple--top"
                        type="bad-news"
                        onDismiss={() => setErrorMessage("")}
                    >
                        {errorMessage}
                    </Attention>
                )}
                <label className="oui-label push-double--top" htmlFor="role-description">
                    Name
                </label>
                {editing ? (
                    <input
                        aria-describedby="display-name"
                        className="oui-text-input"
                        placeholder="Display Name"
                        id="display-name"
                        disabled={loading}
                        type="text"
                        {...register("nickname")}
                    />
                ) : (
                    <Typography type="body" className="label--disabled">
                        {nickname}
                    </Typography>
                )}

                <label className="oui-label push-double--top" htmlFor="technical-contact-name">
                    Technical Contact
                </label>
                {editing ? (
                    <UserSearchSelector
                        placeholder={technicalContact.email}
                        onSelectUser={(user) => {
                            if (user) {
                                setTechnicalContact({
                                    email: user.email,
                                    name: `${user.firstName} ${user.lastName}`
                                });
                            }
                        }}
                        isUserDisabled={(user) => {
                            return user.email === technicalContactEmail;
                        }}
                    />
                ) : (
                    <>
                        <Typography type="body" className="label--disabled">
                            {bothTechnicalContactFields ? (
                                <>
                                    {technicalContactName} <br />
                                    {technicalContactEmail}
                                </>
                            ) : (
                                <>{contact}</>
                            )}
                        </Typography>
                    </>
                )}
                <OptiIDEnabledCheckbox
                    disabled={optiIdEnabled}
                    checked={optiIdEnabled || optiEnabled}
                    editing={editing}
                    onChange={toggleOptiEnabled}
                    showWarning={!optiIdEnabled}
                />
                <label className="oui-label push-double--top" htmlFor="instance-id">
                    Id
                </label>
                <Typography type="body" className="label--disabled">
                    {id}
                </Typography>
            </div>
            <SidebarFooter onCancel={onClose}>
                <Button
                    key="edit-instance"
                    // eslint-disable-next-line react/style-prop-object
                    style="highlight"
                    isLoading={editing && loading}
                    isDisabled={disableEditing || loading || (editing && !formDirty)}
                    loadingText="Saving"
                    isSubmit
                >
                    {actionText}
                </Button>
            </SidebarFooter>
        </form>
    );
};

InstanceForm.displayName = "InstanceForm";
