import { Collection } from "../domain/Collection";
import { User } from "../domain/User";
import { UserGroup } from "../domain/UserGroup";
import { Role } from "../domain/Role";
import { ProductInstance } from "../domain/ProductInstance";
import { Products } from "../domain/Products";
import { UserInstancePermission } from "../rest-model/UserInstancePermission";
import { OrganizationProduct } from "../domain/OrganizationProduct";

export type DataList = Collection<any> | Collection<any>[] | Products | UserInstancePermission[];
export type ChangeEventData = {
    user?: Pick<User, "email" | "firstName" | "lastName">;
    group?: Pick<UserGroup, "id" | "name" | "description">;
    instance?: Pick<
        ProductInstance,
        "instanceId" | "nickname" | "technicalContactEmail" | "technicalContactName" | "optiIdEnabled"
    >;
    role?: Pick<Role, "id" | "name" | "description">;
    users?: Pick<User, "email">[];
    groups?: Pick<UserGroup, "id">[];
    instances?: { id: string }[];
};

const update = (
    list: any[],
    eventData: ChangeEventData,
    matcher: (item: any, eventData: ChangeEventData) => boolean,
    updater: (item: any) => any
) => {
    let matchingItems = list.filter((item) => matcher(item, eventData)) || [];
    matchingItems.forEach(updater);
    return list;
};

const emailMatcher = (item: { email: string }, eventData: ChangeEventData) => {
    return item.email === eventData?.user?.email || !!(eventData?.users || []).find((u) => u.email === item.email);
};

const idMatcher = (item: { id: string }, eventData: ChangeEventData): boolean => {
    return (
        item.id === eventData.group?.id ||
        item.id === eventData.role?.id ||
        !!(eventData?.groups || []).find((g) => g.id === item.id)
    );
};

const updateDataListItems = (
    dataList: DataList,
    eventData: ChangeEventData,
    matcher: (item: any, eventData: ChangeEventData) => boolean,
    updater: (item: any) => any
) => {
    let updatedList: DataList = dataList;
    if ((dataList as Collection<any>).items) {
        updatedList = {
            ...dataList,
            items: update((dataList as Collection<any>).items || [], eventData, matcher, updater)
        };
    } else if ((dataList as Collection<any>[]).length) {
        //this block handles infinite list (list of collections)
        updatedList = (dataList as Collection<any>[]).map((list: Collection<any>) => {
            return {
                ...list,
                items: update(list.items || [], eventData, matcher, updater)
            };
        });
    }
    return updatedList;
};

export const updateGroup = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.group || !dataList) return dataList;

    const updater = (item: UserGroup) => {
        item.name = eventData.group!.name;
        item.description = eventData.group!.description;
    };

    return updateDataListItems(dataList, eventData, idMatcher, updater);
};

export const updateUser = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.user || !dataList) return dataList;

    let updater = (item: User) => {
        item.firstName = eventData.user!.firstName;
        item.lastName = eventData.user!.lastName;
    };

    return updateDataListItems(dataList, eventData, emailMatcher, updater);
};

export const updateRole = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.role || !dataList) return dataList;

    let updater = (item: Role) => {
        item.name = eventData.role!.name;
        item.description = eventData.role!.description;
    };

    return updateDataListItems(dataList, eventData, idMatcher, updater);
};

export const updateInstance = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData.instance || !dataList) return dataList;

    const products = dataList as Products;
    if (products.organizationProducts || products.productInstances) {
        return {
            ...dataList,
            organizationProducts: update(
                (dataList as Products).organizationProducts,
                eventData,
                (item: OrganizationProduct, eventData: ChangeEventData) => {
                    return item.instances.some((i) => i.id === eventData.instance?.instanceId);
                },
                (item: OrganizationProduct) => {
                    const inst = item.instances.find((i) => i.id === eventData.instance?.instanceId);
                    if (inst) {
                        inst.nickname = eventData.instance?.nickname || undefined;
                        inst.technicalContactEmail = eventData.instance?.technicalContactEmail;
                        inst.technicalContactName = eventData.instance?.technicalContactName;
                        inst.optiIdEnabled = eventData.instance?.optiIdEnabled;
                    }
                }
            ),
            productInstances: update(
                (dataList as Products).productInstances,
                eventData,
                (item: ProductInstance, eventData: ChangeEventData) => {
                    return item.instanceId === eventData.instance?.instanceId;
                },
                (item: ProductInstance) => {
                    item.nickname = eventData.instance?.nickname;
                    item.technicalContactEmail = eventData.instance?.technicalContactEmail;
                    item.technicalContactName = eventData.instance?.technicalContactName;
                    item.optiIdEnabled = eventData.instance?.optiIdEnabled;
                }
            )
        };
    } else if ((dataList as Array<UserInstancePermission>).length) {
        return update(
            dataList as UserInstancePermission[],
            eventData,
            (item: UserInstancePermission, eventData: ChangeEventData) => {
                return item.instanceId === eventData.instance?.instanceId;
            },
            (item: UserInstancePermission) => {
                item.nickname = eventData.instance?.nickname || undefined;
            }
        );
    } else {
        return dataList;
    }
};

export const removeGroup = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.groups) return dataList;
    let updatedList: DataList = dataList;
    if ((dataList as Collection<UserGroup>).items) {
        eventData.groups.forEach((group) => {
            updatedList = {
                ...dataList,
                items: (dataList as Collection<UserGroup>).items.filter((g) => g.id !== group.id),
                totalCount: (dataList as Collection<UserGroup>).totalCount - 1
            };
        });
    } else if ((dataList as Collection<UserGroup>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.groups.forEach((group) => {
            updatedList = (dataList as Collection<UserGroup>[]).map((list: Collection<UserGroup>) => {
                return {
                    ...list,
                    items: (list as Collection<UserGroup>).items.filter((g) => g.id !== group.id),
                    totalCount: (list as Collection<UserGroup>).totalCount - 1
                };
            });
        });
    }
    return updatedList;
};

export const removeUser = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.users || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<User>).items) {
        eventData.users.forEach((user) => {
            updatedList = {
                ...dataList,
                items: (dataList as Collection<User>).items.filter((u) => u.email !== user.email),
                totalCount: (dataList as Collection<User>).totalCount - 1
            };
        });
    } else if ((dataList as Collection<User>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.users.forEach((user) => {
            updatedList = (dataList as Collection<User>[]).map((list: Collection<User>) => {
                return {
                    ...list,
                    items: (list as Collection<User>).items.filter((u) => u.email !== user.email),
                    totalCount: (list as Collection<User>).totalCount - 1
                };
            });
        });
    }
    return updatedList;
};

export const removeRole = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.role || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<Role>).items) {
        updatedList = {
            ...dataList,
            items: (dataList as Collection<Role>).items.filter((r) => r.id !== eventData?.role?.id),
            totalCount: (dataList as Collection<Role>).totalCount - 1
        };
    } else if ((dataList as Collection<Role>[]).length) {
        //this block handles infinite list (list of collections)
        updatedList = (dataList as Collection<Role>[]).map((list: Collection<Role>) => {
            return {
                ...list,
                items: (list as Collection<Role>).items.filter((r) => r.id !== eventData?.role?.id),
                totalCount: (list as Collection<Role>).totalCount - 1
            };
        });
    }
    return updatedList;
};

export const addUserToGroup = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.users || !eventData?.groups || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<UserGroup>)?.items) {
        eventData.users.forEach((user) => {
            updatedList = {
                ...dataList,
                items: update(
                    (dataList as Collection<UserGroup>).items || [],
                    eventData,
                    idMatcher,
                    (item: UserGroup) => {
                        if (!item.users.find((e) => e === user.email)) {
                            item.users = [...item.users, user.email];
                            item.userCount = item.userCount + 1;
                        }
                    }
                )
            };
        });
    } else if ((dataList as Collection<UserGroup>[])?.length) {
        //this block handles infinite list (list of collections)
        eventData.users.forEach((user) => {
            updatedList = (dataList as Collection<UserGroup>[]).map((list: Collection<UserGroup>) => {
                return {
                    ...list,
                    items: update(
                        (list as Collection<UserGroup>).items || [],
                        eventData,
                        idMatcher,
                        (item: UserGroup) => {
                            if (!item.users.find((e) => e === user.email)) {
                                item.users = [...item.users, user.email];
                                item.userCount = item.userCount + 1;
                            }
                        }
                    )
                };
            });
        });
    }

    return updatedList;
};

export const addGroupToUser = (eventData: ChangeEventData, dataList: DataList): DataList | undefined => {
    if (!eventData?.users || !eventData?.groups || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<User>).items) {
        eventData.groups.forEach((group) => {
            updatedList = {
                ...dataList,
                items: update((dataList as Collection<User>).items || [], eventData, emailMatcher, (item: User) => {
                    // @ts-ignore
                    item.userGroupIds = [...new Set([...item.userGroupIds, group.id])];
                })
            };
        });
    } else if ((dataList as Collection<User>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.groups.forEach((group) => {
            updatedList = (dataList as Collection<User>[]).map((list: Collection<User>) => {
                return {
                    ...list,
                    items: update((list as Collection<User>).items || [], eventData, emailMatcher, (item: User) => {
                        // @ts-ignore
                        item.userGroupIds = [...new Set([...item.userGroupIds, group.id])];
                    })
                };
            });
        });
    }

    return updatedList;
};

export const invalidateUserGroupsForUser = (
    eventData: ChangeEventData,
    dataList: DataList,
    key?: String
): DataList | undefined => {
    if (!eventData?.users?.length || !dataList) return dataList;

    let updatedList: DataList = dataList;
    let shouldRevalidate = false;

    eventData?.users.forEach((user) => {
        shouldRevalidate = key?.includes(user.email) || shouldRevalidate || false;
    });

    return shouldRevalidate ? undefined : updatedList;
};

export const invalidateUsersForGroup = (
    eventData: ChangeEventData,
    dataList: DataList,
    key?: String
): DataList | undefined => {
    if (!eventData?.groups?.length || !dataList) return dataList;

    let updatedList: DataList = dataList;
    let shouldRevalidate = false;

    eventData?.groups.forEach((group) => {
        shouldRevalidate = key?.includes(group.id) || shouldRevalidate || false;
    });

    return shouldRevalidate ? undefined : updatedList;
};

export const removeUserFromGroup = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.users || !eventData?.groups || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<UserGroup>).items) {
        eventData.users.forEach((user) => {
            updatedList = {
                ...dataList,
                items: update(
                    (dataList as Collection<UserGroup>).items || [],
                    eventData,
                    idMatcher,
                    (item: UserGroup) => {
                        item.users = item.users.filter((email) => email !== user.email);
                        item.userCount = item.userCount - 1;
                    }
                )
            };
        });
    } else if ((dataList as Collection<UserGroup>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.users.forEach((user) => {
            updatedList = (dataList as Collection<UserGroup>[]).map((list: Collection<UserGroup>) => {
                return {
                    ...list,
                    items: update(
                        (list as Collection<UserGroup>).items || [],
                        eventData,
                        idMatcher,
                        (item: UserGroup) => {
                            item.users = item.users.filter((email) => email !== user.email);
                            item.userCount = item.userCount - 1;
                        }
                    )
                };
            });
        });
    }

    return updatedList;
};

export const removeGroupFromUser = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.users || !eventData?.groups || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<User>).items) {
        eventData.groups.forEach((group) => {
            updatedList = {
                ...dataList,
                items: update((dataList as Collection<User>).items || [], eventData, emailMatcher, (item: User) => {
                    item.userGroupIds = item.userGroupIds.filter((id) => id !== group.id);
                })
            };
        });
    } else if ((dataList as Collection<User>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.groups.forEach((group) => {
            updatedList = (dataList as Collection<User>[]).map((list: Collection<User>) => {
                return {
                    ...list,
                    items: update(list.items || [], eventData, emailMatcher, (item: User) => {
                        item.userGroupIds = item.userGroupIds.filter((id) => id !== group.id);
                    })
                };
            });
        });
    }

    return updatedList;
};

export const removeUserFromAllGroups = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.users || !dataList) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<UserGroup>).items) {
        eventData.users.forEach((user) => {
            if ((dataList as Collection<UserGroup>).items.find((ug) => ug.users.includes(user.email))) {
                updatedList = {
                    ...dataList,
                    totalCount: (dataList as Collection<UserGroup>).totalCount - 1,
                    items: update(
                        (dataList as Collection<UserGroup>).items || [],
                        eventData,
                        (item: any, eventData: ChangeEventData) => true,
                        (item: UserGroup) => {
                            if (item.users.find((email) => email === user.email)) {
                                item.users = item.users.filter((email) => email !== user.email);
                                item.userCount = item.userCount - 1;
                            }
                        }
                    )
                };
            }
        });
    } else if ((dataList as Collection<UserGroup>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.users.forEach((user) => {
            updatedList = (dataList as Collection<UserGroup>[]).map((list: Collection<UserGroup>) => {
                return {
                    ...list,
                    totalCount: (list as Collection<UserGroup>).totalCount - 1,
                    items: update(
                        (list as Collection<UserGroup>).items || [],
                        eventData,
                        (item: any, eventData: ChangeEventData) => true,
                        (item: UserGroup) => {
                            if (item.users.find((email) => email === user.email)) {
                                item.users = item.users.filter((email) => email !== user.email);
                                item.userCount = item.userCount - 1;
                            }
                        }
                    )
                };
            });
        });
    }

    return updatedList;
};

export const removeInstanceFromGroup = (eventData: ChangeEventData, dataList: DataList): DataList | undefined => {
    if (!eventData?.groups || !eventData.instances || !dataList) return dataList;

    let updatedList: DataList = dataList;

    if ((dataList as Collection<UserGroup>).items) {
        eventData.instances.forEach(({ id }) => {
            updatedList = {
                ...dataList,
                items: update(
                    (dataList as Collection<UserGroup>).items || [],
                    eventData,
                    idMatcher,
                    (item: UserGroup) => {
                        item.instancePermissions = (item.instancePermissions || []).filter(
                            (perm) => perm.instanceId !== id
                        );
                    }
                )
            };
        });
    } else if ((dataList as Collection<UserGroup>[]).length) {
        //this block handles infinite list (list of collections)
        eventData.instances.forEach(({ id }) => {
            updatedList = (dataList as Collection<UserGroup>[]).map((list: Collection<UserGroup>) => {
                return {
                    ...list,
                    items: update(
                        (list as Collection<UserGroup>).items || [],
                        eventData,
                        idMatcher,
                        (item: UserGroup) => {
                            item.instancePermissions = (item.instancePermissions || []).filter(
                                (perm) => perm.instanceId !== id
                            );
                        }
                    )
                };
            });
        });
    }

    return updatedList;
};

export const invalidateList = (): DataList | undefined => {
    return undefined;
};

export const invalidateGroup = (eventData: ChangeEventData, dataList: DataList): DataList | undefined => {
    if (!eventData?.groups) return dataList;

    const hasGroup = !!((dataList as Collection<UserGroup>).items || []).find(
        (g) => eventData.groups?.some((i) => i.id === g.id)
    );

    return hasGroup ? undefined : dataList;
};
