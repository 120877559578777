import { BaseFieldProps } from "./BaseFieldProps";
import classnames from "classnames";
import React, { useState } from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";
import { InputFieldError } from "../../../InputFieldError/InputFieldError";

export const ClientSecretField = ({ disabled, onChange, required }: BaseFieldProps) => {
    const [fieldError, setFieldError] = useState("");
    // TODO: Create InputField component that handles this and labels and notes
    const inputClasses = classnames("oui-text-input", {
        "oui-form-bad-news": !!fieldError
    });

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (required && !value) {
            setFieldError("Client Secret is required.");
        } else {
            setFieldError("");
        }
        handleFieldChange({ e, onChange });
    };

    return (
        <>
            <label className="oui-label push-double--top" htmlFor="client-secret">
                Client Secret
                {required && <span aria-label="(required)" className="oui-label--required" />}
            </label>
            <input
                aria-describedby="client-secret-error"
                className={inputClasses}
                id="client-secret"
                type="text"
                disabled={disabled}
                onChange={onValueChange}
            />
            {!!fieldError && <InputFieldError id="client-secret-error">{fieldError}</InputFieldError>}
        </>
    );
};

ClientSecretField.displayName = "ClientSecretField";
