import { Customer } from "../rest-model/Customer";
import { IIdentityRepository } from "../repositories/IdentityRepository";
import { Collection } from "../domain/Collection";

export interface ICustomerService {
    searchCustomers({ query }: { query: string }): Promise<Collection<Customer>>;
}

export class CustomerService implements ICustomerService {
    identityRepository: IIdentityRepository;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
    }

    public async searchCustomers({ query }: { query: string }): Promise<Collection<Customer>> {
        const { items = [], totalCount = 0 } = await this.identityRepository.searchCustomers({
            query
        });
        return {
            items,
            totalCount
        };
    }
}
