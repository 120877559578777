import React, { createContext, useContext, useReducer } from "react";

import { User } from "../../../../domain/User";
import { ProductInstance } from "../../../../domain/ProductInstance";
import { UserGroup } from "../../../../domain/UserGroup";

type trackedUserGroup = {
    description?: string;
    editing?: boolean;
    userGroup: UserGroup | null;
    productInstances: ProductInstance[];
    users: User[];
    initialUsers?: User[];
    loading?: boolean;
};

const contextInitialValues: trackedUserGroup = {
    description: "",
    editing: false,
    userGroup: null,
    productInstances: [],
    users: [],
    initialUsers: [],
    loading: false
};

const userGroupReducer = (state: any, updates: any) => {
    return {
        ...state,
        ...updates
    };
};

type UserGroupContextDefinition = {
    userGroupState: trackedUserGroup;
    updateUserGroupState: Function;
};

export const UserGroupContext = createContext<UserGroupContextDefinition>({
    userGroupState: contextInitialValues,
    updateUserGroupState: () => {
        return null;
    }
});

export const UserGroupProvider = ({ children }: { children: any }) => {
    const [userGroupState, updateUserGroupState] = useReducer(userGroupReducer, contextInitialValues);

    const value = {
        userGroupState,
        updateUserGroupState
    };

    return <UserGroupContext.Provider value={value}>{children}</UserGroupContext.Provider>;
};

export const useUserGroupContext = () => {
    return useContext(UserGroupContext);
};
