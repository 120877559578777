export class Customer {
    constructor(customer: any) {
        this.id = customer.id;
        this.name = customer.name;
        this.crmId = customer.crmId;
        this.properties = customer.properties;
        this.created = new Date(customer.created);
        this.modified = new Date(customer.modified);
    }

    id: string;
    name: string;
    crmId?: string;
    properties: any[];
    created: Date;
    modified: Date;
}
