/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonIcon, Dropdown } from "@optimizely/axiom";
import React, { useEffect, useState } from "react";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { ProjectRoleRow } from "./ProjectRoleRow";
import { UserGroup } from "../../../domain/UserGroup";
import { Role } from "../../../domain/Role";

export const ProjectRoleTable = ({
    projects,
    roles,
    onGroupsChanged
}: {
    projects?: ProductAttribute[];
    roles?: { group: UserGroup; role: Role; project?: ProductAttribute }[];
    onGroupsChanged?: (groups: UserGroup[]) => void;
}) => {
    const [expUserType, setExpUserType] = useState<string | undefined>();
    const [expRoleRows, setExpRoleRows] = useState<{ rowId: number; group?: UserGroup }[]>([{ rowId: 0 }]);

    const adminRole = roles?.find((role) => !role.project);

    const handleGroupSelected = (group: UserGroup, rowId: number) => {
        setExpRoleRows((prevRows) => {
            const matchingRow: { rowId: number; group?: UserGroup } | undefined = prevRows.find(
                (x) => x.rowId === rowId
            );
            if (matchingRow) {
                matchingRow.group = group;
            }
            return [...prevRows];
        });
    };

    useEffect(() => {
        onGroupsChanged && onGroupsChanged(expRoleRows.filter((row) => row.group).map((i) => i.group!));
    }, [expRoleRows]);

    return (
        <>
            <Dropdown
                arrowIcon="down"
                fullWidth
                className="admin-center__form-dropdown"
                buttonContent={expUserType || "Select a user type..."}
            >
                <Dropdown.Contents>
                    {["Admin", "User"].map((item) => {
                        return (
                            <Dropdown.ListItem key={item}>
                                <Dropdown.BlockLink
                                    onClick={() => {
                                        setExpUserType(item);

                                        if (item === "Admin" && adminRole) {
                                            onGroupsChanged && onGroupsChanged([adminRole.group]);
                                        }
                                    }}
                                >
                                    {item}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
            {expUserType === "User" && (
                <>
                    {expRoleRows?.map((row, rowIndex) => {
                        const plusButton = (
                            <ButtonIcon
                                iconName="plus"
                                key="add-row-button"
                                title="Add permissions"
                                size="small"
                                style="plain"
                                onClick={() => {
                                    setExpRoleRows((rows) => [
                                        ...rows,
                                        { rowId: Math.max(...rows.map((o) => o.rowId)) + 1 }
                                    ]);
                                }}
                            />
                        );
                        const trashButton = (
                            <ButtonIcon
                                iconName="trash-can"
                                key="trash-row-button"
                                title="Remove permission"
                                size="small"
                                style="plain"
                                onClick={() => {
                                    setExpRoleRows((rows) => [...rows.filter((r) => r.rowId !== row.rowId)]);
                                }}
                            />
                        );

                        const buttons = [];

                        if (rowIndex === expRoleRows.length - 1) buttons.push(plusButton);
                        if (expRoleRows.length > 1) buttons.push(trashButton);

                        return (
                            <ProjectRoleRow
                                key={row.rowId}
                                roles={roles}
                                projects={projects}
                                actionButtons={buttons}
                                onGroupSelected={(group) => handleGroupSelected(group, row.rowId)}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

ProjectRoleTable.displayName = "ProjectRoleTable";
