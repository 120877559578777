export class Organization {
    id: string;
    mfaEnabled: boolean;
    ssoEnabled: boolean;
    crmId?: string;

    constructor(restOrganization: any) {
        this.id = restOrganization?.Id;
        this.mfaEnabled = restOrganization.MfaEnabled || false;
        this.ssoEnabled = restOrganization.SsoEnabled || false;
        this.crmId = restOrganization?.CrmId;
    }
}
