import { Poptip, SidebarMenuItem } from "@optimizely/axiom";

type NavigationBarMenuItemProps = {
    className?: string;
    href: string;
    icon?: string;
    isActive?: boolean;
    onClick: () => void;
    showTooltip?: boolean;
    title: string;
};
export const NavigationBarMenuItem = ({
    className,
    href,
    icon,
    isActive,
    onClick,
    showTooltip,
    title
}: NavigationBarMenuItemProps) => {
    const DefaultMenuItem = () => (
        <SidebarMenuItem
            className={className}
            key={title}
            title={title}
            leftIcon={icon}
            href={href}
            onClick={onClick}
            isActive={isActive}
        />
    );
    if (showTooltip) {
        return (
            <Poptip isInline trigger="mouseenter" content={title} className="">
                <DefaultMenuItem />
            </Poptip>
        );
    }
    return <DefaultMenuItem />;
};

NavigationBarMenuItem.displayName = "NavigationBarMenuItem";
