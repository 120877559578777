export interface IOrganizationProductInstance {
    id: string;
    name: string;
    nickname: string;
    optiIdEnabled?: string;
    OptiIdEnabled?: string;
    region?: string;
    technicalContactEmail?: string;
    technicalContactName?: string;
}

export interface IOrganizationProduct {
    hasUsageDashboard?: boolean | undefined;
    id: string;
    name: string;
    instances: IOrganizationProductInstance[];
    isService: boolean;
}

export class OrganizationProductInstances {
    constructor(orgProdInstances: any) {
        this.id = orgProdInstances.id;
        this.name = orgProdInstances.name;
        this.products = orgProdInstances.products;
        this.aeEmail = orgProdInstances.aeEmail;
        this.aeName = orgProdInstances.aeName;
        this.aePhone = orgProdInstances.aePhone;
        this.csmEmail = orgProdInstances.csmEmail;
        this.csmName = orgProdInstances.csmName;
        this.csmPhone = orgProdInstances.csmPhone;
    }

    id: string;
    name: string;
    products: IOrganizationProduct[];

    aeEmail?: string;
    aeName?: string;
    aePhone?: string;

    csmEmail?: string;
    csmName?: string;
    csmPhone?: string;
}
