import React, { useEffect } from "react";
import { Button, Sidebar as AxSidebar, Typography, Icon } from "@optimizely/axiom";

import styles from "./Sidebar.module.scss";
import classnames from "classnames";

interface SidebarProps {
    children?: React.ReactElement<any, any> | React.ReactElement<any, any>[] | null;
    className?: string;
    heading: string;
    isOpen: boolean;
    noTransform?: boolean;
    onCancel: () => void;
}

export const Sidebar = ({ className, heading, isOpen, onCancel, children, noTransform = false }: SidebarProps) => {
    const sidebarClasses = classnames(styles["um-sidebar"], "um-sidebar", className, {
        [styles["um-sidebar--no-transform"]]: noTransform
    });

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("um-sidebar--open");
        } else {
            document.body.classList.remove("um-sidebar--open");
        }
    }, [isOpen]);

    return (
        <AxSidebar
            className={sidebarClasses}
            isOpen={isOpen}
            maxWidth={1000}
            minWidth={700}
            width={700}
            border
            boxShadow
            isResizable
        >
            <Button className={styles["um-sidebar-close"]} onClick={onCancel} ariaLabel="Close sidebar">
                <Icon name="xmark-thin" size="medium" />
            </Button>
            <Typography tag="h3" type="header3" className={styles["um-sidebar__heading"]}>
                {heading}
            </Typography>
            <div className={`${styles["um-sidebar-body"]} flex--1`}>{children}</div>
        </AxSidebar>
    );
};

Sidebar.displayName = "Sidebar";
