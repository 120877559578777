import { Dropdown, Table } from "@optimizely/axiom";
import React from "react";
import { DetailedInvitation } from "./SupportTable";
import { MoreMenu } from "../MoreMenu/MoreMenu";
import { Invitation } from "../../../domain/Invitation";

type SupportTableRowProps = {
    invitation: DetailedInvitation;
    onClick: ({ invitation }: { invitation: Invitation }) => void;
    onRemove: ({ invitation }: { invitation: Invitation }) => void;
};
export const SupportTableRow = (props: SupportTableRowProps) => {
    const { invitation, onClick, onRemove } = props;
    const { created, organizationName } = invitation;
    return (
        <Table.TR>
            <Table.TD>
                <button className="link button-as-link" type="button" onClick={() => onClick({ invitation })}>
                    {organizationName}
                </button>
            </Table.TD>
            <Table.TD>{new Date(created).toLocaleString()}</Table.TD>
            <Table.TD>
                <MoreMenu>
                    <Dropdown.ListItem>
                        <Dropdown.BlockLink onClick={() => onRemove({ invitation })}>
                            <span className="danger">Remove access...</span>
                        </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                </MoreMenu>
            </Table.TD>
        </Table.TR>
    );
};

SupportTableRow.displayName = "SupportTableRow";
