import { UserGroup as RestUserGroup } from "../rest-model/UserGroup";
import { UserGroup } from "../domain/UserGroup";
import { Role } from "../rest-model/Role";
import { INSTANCE_GROUP_PROPERTY_NAME } from "../constants/property-constants";

export class UserGroupAdapter {
    public adaptToDomain(restUserGroup: RestUserGroup): UserGroup {
        const { created, modified, instancePermissions, properties, ...rest } = restUserGroup;
        const isInstanceGroup = properties?.find((p) => p.name === INSTANCE_GROUP_PROPERTY_NAME)?.value === "true";

        return new UserGroup({
            ...rest,
            isInstanceGroup,
            created: new Date(created),
            modified: new Date(modified),
            instancePermissions: instancePermissions.map((ip) => {
                return {
                    instanceId: ip.InstanceId,
                    roleIds: ip?.Roles?.map((r) => r.Id) || [],
                    roles: ip.Roles?.map((r) => new Role(r)) || []
                };
            })
        });
    }

    public adaptToRest(userGroup: UserGroup): RestUserGroup {
        const data = {
            Id: userGroup.id,
            Name: userGroup.name,
            OrganizationId: userGroup.organizationId,
            UserCount: userGroup.userCount,
            GroupOwner: userGroup.groupOwner,
            Description: userGroup.description,
            Created: userGroup.created,
            Modified: userGroup.modified,
            InstancePermissions: userGroup.instancePermissions,
            Users: userGroup.users
        };

        return new RestUserGroup(data);
    }
}
