import styles from "../InstanceForm/InstanceForm.module.scss";
import { Attention, Checkbox, Icon, Typography } from "@optimizely/axiom";
import React from "react";

export const OptiIDEnabledCheckbox = ({
    checked,
    disabled,
    editing,
    onChange,
    showWarning
}: {
    checked?: boolean;
    disabled?: boolean;
    editing?: boolean;
    onChange: () => void;
    showWarning?: boolean;
}) => {
    const style = { color: "var(--axiom-brand-error-color)" };

    return (
        <>
            {editing ? (
                <div className={styles["instance-form__opti-id-checkbox"]}>
                    <Checkbox isDisabled={disabled} checked={checked} label="Opti ID Enabled" onChange={onChange} />
                    {showWarning && (
                        <Attention type="bad-news" className="flex push--top">
                            <Icon {...style} name="triangle-exclamation" size="medium" />
                            <Typography type="body" tag="span" className="push-triple--left">
                                Only select this when this instance has been set up on Opti Id. Once this is selected,
                                it cannot be changed.
                            </Typography>
                        </Attention>
                    )}
                </div>
            ) : (
                <Checkbox isDisabled checked={checked} label="Opti ID Enabled" />
            )}
        </>
    );
};

OptiIDEnabledCheckbox.displayName = "OptiIDEnabledCheckbox";
