import React from "react";

export const ResetFilterPrompt = ({
    onClick,
    prompt = "No results match your filter."
}: {
    onClick: () => void;
    prompt?: string;
}) => {
    return (
        <div>
            {prompt} Try&nbsp;
            <button className="link button-as-link" type="button" onClick={onClick}>
                resetting your filters.
            </button>
        </div>
    );
};

ResetFilterPrompt.displayName = "ResetFilterPrompt";
