import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import { AccessManagementUsers } from "../../components/Access/AccessManagementUsers";

export const AccessManagementInstanceUsers = () => {
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);

    if (!accessManagementEnabled) return <Unauthorized />;

    return <AccessManagementUsers />;
};

AccessManagementInstanceUsers.displayName = "InstanceUsersPage";
