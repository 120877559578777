import { User } from "../../../domain/User";
import { ButtonIcon, Poptip, Table } from "@optimizely/axiom";
import React from "react";

type UserTableRowProps = {
    onRemove?: () => void;
    removeTitle?: string;
    user: User;
};
export const UserTableRow = ({ onRemove, removeTitle = "Remove user", user }: UserTableRowProps) => {
    return (
        <Table.TR>
            <Table.TD className="truncated" colSpan={3}>
                {`${user.firstName} ${user.lastName}`} ({user.email})
            </Table.TD>
            {!!onRemove && (
                <Table.TD textAlign="right">
                    <Poptip isInline trigger="mouseenter" content={removeTitle} className="">
                        {/* eslint-disable react/style-prop-object */}
                        <ButtonIcon iconName="trash-can" size="small" style="plain" title="" onClick={onRemove} />
                    </Poptip>
                </Table.TD>
            )}
        </Table.TR>
    );
};

UserTableRow.displayName = "UserTableRow";
