export class InstanceRole {
    constructor(role: any) {
        this.id = role.Id;
        this.name = role.Name;
        this.displayName = role.DisplayName || role.Name;
        this.isInstanceRole = role.IsInstanceRole || false;
    }

    id: string;
    name: string;
    displayName: string;
    isInstanceRole: boolean;
}

export class ProductInstancePermission {
    constructor(permission: any) {
        this.roles = permission.Roles.map((role: any) => new InstanceRole(role));
    }

    roles: InstanceRole[];
}
