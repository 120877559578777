import React from "react";
import styles from "./CheckboxList.module.scss";
import { Checkbox } from "@optimizely/axiom";
import classnames from "classnames";

export type CheckboxListCheckbox = {
    value: string;
    heading?: boolean;
    checked?: boolean;
    text?: string;
};

type CheckboxListProps = {
    items?: CheckboxListCheckbox[];
    onChange: ({ values }: { values: string[] }) => void;
};

export const CheckboxList = ({ items: listItems = [], onChange }: CheckboxListProps) => {
    const nonHeadings = listItems.filter((i) => !i.heading);
    const hasSelections = nonHeadings.some((s) => s.checked);
    const heading = listItems.find((i) => i.heading);

    const toggleAll = () => {
        const updated = [...listItems];
        if (heading?.checked) {
            updated.forEach((checkbox) => (checkbox.checked = false));
        } else {
            updated.forEach((item) => (item.checked = true));
        }
        onChange({ values: updated.filter((s) => s.checked).map((s) => s.value) });
    };

    const handleCheckboxChange = ({ checkbox }: { checkbox: CheckboxListCheckbox }) => {
        const updatedSelections = [...listItems];
        const index = updatedSelections.findIndex((selection) => selection.value === checkbox.value);
        updatedSelections[index].checked = !checkbox.checked;
        onChange({ values: updatedSelections.filter((s) => s.checked).map((s) => s.value) });
    };
    const indeterminateHeading = hasSelections && !listItems.filter((i) => !i.heading)?.every((i) => i.checked);

    const listItemClasses = classnames("border--bottom", styles["checkbox-list__item"], {
        "push-triple--left": heading
    });

    return (
        <div className={styles["checkbox-list"]}>
            {!!heading && (
                <div className={"border--bottom " + styles["checkbox-list__heading"]}>
                    <Checkbox
                        className={styles["checkbox-list__checkbox"]}
                        checked={heading?.checked || (!!nonHeadings?.length && nonHeadings?.every?.((i) => i.checked))}
                        indeterminate={indeterminateHeading}
                        onChange={toggleAll}
                        label={heading.text || heading.value}
                    />
                </div>
            )}
            {listItems
                .filter((s) => !s.heading)
                .map((item, itemIndex) => {
                    const { checked, text, value } = item;
                    return (
                        <div className={listItemClasses} key={itemIndex}>
                            <Checkbox
                                checked={checked}
                                onChange={() => handleCheckboxChange({ checkbox: item })}
                                label={text || value}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
