import React from "react";
import { Typography } from "@optimizely/axiom";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { CustomGroupAccessDetails } from "./CustomGroupAccessDetails";
import { CUSTOM_GROUP_ACCESS_ACTION } from "../../../../constants/constants";

const determineActionText = (action: string | undefined) => {
    switch (action) {
        case CUSTOM_GROUP_ACCESS_ACTION.ADD:
            return {
                callToActionText: CUSTOM_GROUP_ACCESS_ACTION.ADD,
                style: "highlight",
                subtitleAction: "adding",
                title: "Adding Group Access"
            };
        case CUSTOM_GROUP_ACCESS_ACTION.REMOVE:
            return {
                callToActionText: CUSTOM_GROUP_ACCESS_ACTION.REMOVE,
                style: "danger",
                subtitleAction: "removing",
                title: "Removing Group Access"
            };
        default:
            return {
                callToActionText: "Confirm Access",
                style: "highlight",
                subtitleAction: "reviewing",
                title: "Reviewing Group Access"
            };
    }
};

type CustomGroupAccessConfirmationModalProps = {
    action: `${CUSTOM_GROUP_ACCESS_ACTION}` | undefined;
    errorMessage: string | null;
    isSaving: boolean;
    groupId: string | undefined;
    groupName: string | undefined;
    onClose: () => void;
    onSubmit: () => Promise<void>;
};

export const CustomGroupAccessConfirmationModal = ({
    action,
    errorMessage,
    isSaving,
    groupId,
    groupName,
    onClose,
    onSubmit
}: CustomGroupAccessConfirmationModalProps) => {
    const actionText = determineActionText(action);
    const { callToActionText, style, subtitleAction, title } = actionText;

    const subtitle = (
        <>
            <Typography className="weight--bold" type="body">
                {`You are ${subtitleAction} access from a group (${groupName}).`}
            </Typography>
            <div className="push--top">
                Please click to expand the following product(s) and review the respective access details before
                proceeding.
            </div>
        </>
    );

    return (
        <ConfirmationDialog
            callToActionText={callToActionText}
            loading={isSaving}
            error={errorMessage}
            onCancel={onClose}
            onConfirmation={onSubmit}
            // eslint-disable-next-line
            style={style}
            subtitle={subtitle}
            title={title}
        >
            <CustomGroupAccessDetails groupId={groupId} groupName={groupName} />
        </ConfirmationDialog>
    );
};

CustomGroupAccessConfirmationModal.displayName = "CustomGroupAccessConfirmationModal";
