import { useServicesContext } from "../../providers/ServicesProvider";
import { IAccessContext } from "../../services/UserService";
import { User } from "../../domain/User";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { Collection } from "../../domain/Collection";
import { EVENT_TYPES, SWR_KEY_PREFIX } from "../../constants/constants";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";

export const useContextUsers = ({
    organizationId,
    context,
    searchQuery = "",
    roleIdFilter,
    projectIdFilter,
    includeExternalStatus = false
}: {
    organizationId?: string;
    context?: IAccessContext;
    searchQuery?: string;
    roleIdFilter?: string;
    projectIdFilter?: string;
    includeExternalStatus?: boolean;
}) => {
    const { userService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const key =
        organizationId && context
            ? `${
                  SWR_KEY_PREFIX.USERS
              }/api/users/?organizationId=${organizationId}&query=${searchQuery}&roleIdFilter=${roleIdFilter}&includeExternalStatus=${includeExternalStatus}&projectIdFilter=${projectIdFilter}&scope=${
                  context.scope
              }&items=${context.items.map((i) => i.id).join(",")}&refresh=1`
            : null;

    const { currentPage, setCurrentPage, pageData, error } = usePaginatedList<User>({
        key,
        fetcher: (): Promise<Collection<User>> => {
            return userService.getUserListByContext({
                organizationId: organizationId as string,
                context: context as IAccessContext,
                roleId: roleIdFilter,
                projectId: projectIdFilter,
                includeExternalStatus,
                searchQuery,
                page: currentPage
            });
        }
    });

    const deleteUser = async ({ user }: { user: User }) => {
        if (organizationId) {
            const response = await userService.removeUser({ user, organizationId: organizationId || "" });

            fireChangeEvent({ type: EVENT_TYPES.DELETE_USER, data: { users: [{ email: user.email }] } });

            return response;
        } else {
            return Promise.reject("No organizationId provided to delete user");
        }
    };

    const removeUserAccess = async ({ user }: { user: User }) => {
        if (organizationId && context) {
            const response = await userService.removeUserAccess({
                organizationId,
                user,
                context
            });

            fireChangeEvent({ type: EVENT_TYPES.DELETE_USER, data: { users: [{ email: user.email }] } });

            return response;
        } else {
            return Promise.reject("No organizationId or context provided to remove user access");
        }
    };

    return {
        users: pageData?.items,
        isLoading: !(pageData || error),
        error,
        currentPage,
        setCurrentPage,
        totalCount: pageData?.totalCount || 0,
        deleteUser,
        removeUserAccess
    };
};
