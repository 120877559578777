/* eslint react/style-prop-object: 0 */
import { Button, Spinner, Switch, Typography } from "@optimizely/axiom";
import { BillingNotification } from "../../../rest-model/BillingNotification";
import { useMemo } from "react";
import { getNotificationTitle } from "../../../lib/notification-utils";

type BillingNotificationToggleProps = {
    disabled?: boolean;
    loading?: boolean;
    notification: BillingNotification;
    onManageClick: () => void;
    onToggle: () => void;
};
export const BillingNotificationToggle = ({
    disabled = false,
    loading = false,
    notification,
    onManageClick,
    onToggle
}: BillingNotificationToggleProps) => {
    const handleToggle = () => {
        onToggle();
    };

    const memoizedNotificationTitle = useMemo(() => {
        return getNotificationTitle(notification);
    }, [notification]);
    const { enabled, id, recipients } = notification;
    const recipientCount = recipients?.length || 0;

    return (
        <div className="flex flex-row push--ends align-items-center">
            <Switch
                elementId={id || memoizedNotificationTitle}
                isDisabled={disabled || loading}
                checked={enabled}
                onClick={handleToggle}
            />
            <div className="push-double--left flex flex-column align-items-start">
                {memoizedNotificationTitle}
                <Button size="small" style="unstyled" onClick={onManageClick} className="color--brand">
                    <Typography type="body">Manage Recipients ({recipientCount})</Typography>
                </Button>
            </div>
            {loading && <Spinner size="small" className="push-quad--left" />}
        </div>
    );
};

BillingNotificationToggle.displayName = "BillingNotificationToggle";
