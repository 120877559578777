import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { useForm, useFormState } from "react-hook-form";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { ATTRIBUTE_ROLES } from "../../../constants/constants";
import { Attention, Button, Typography } from "@optimizely/axiom";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { IInstanceFormValues } from "../InstanceForm/IInstanceFormValues";
import LimitByRole from "../LimitByRole/LimitByRole";
import { OptiIDEnabledCheckbox } from "../OptiIDEnabledCheckbox/OptiIDEnabledCheckbox";
import { emitToast } from "../../../lib/toaster-utils";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

export const AccessManagementInstanceDetails = () => {
    const { organizationId } = useUserContext();
    const { products, isLoading, error, updateInstance } = useProducts({ organizationId });
    const { instanceId, productId } = useParams();

    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [optiEnabled, setOptiEnabled] = useState(false);
    const [showOptiWarning, setShowOptiWarning] = useState(false);

    const instance = products?.flatMap((p) => p.instances).find((inst) => inst.id === instanceId);

    const { register, handleSubmit, getValues, setValue, control } = useForm<IInstanceFormValues>({
        mode: "onChange",
        defaultValues: {
            nickname: instance?.nickname
        }
    });

    const { isDirty } = useFormState({
        control
    });

    const [technicalContact, setTechnicalContact] = useState({
        email: instance?.technicalContactEmail,
        name: instance?.technicalContactName
    });

    if (isLoading) return <LoadingIndicator />;
    if (error) return <div>Error fetching instance details.</div>;
    if (!instance) return <div>Error: instance not found in organization.</div>;

    const toggleOptiEnabled = () => {
        if (!optiEnabled) {
            setShowOptiWarning(true);
        }
    };

    const handleFormSubmission = async () => {
        if (!editing) {
            setEditing(true);
            setValue("nickname", instance?.nickname || instance?.name || "");
            return;
        }

        if (!instance) return;

        setLoading(true);
        const { nickname } = getValues();
        const updatedInstance: IOrganizationProductInstance = {
            ...instance,
            nickname,
            technicalContactEmail: technicalContact.email,
            technicalContactName: technicalContact.name
        };

        updatedInstance["optiIdEnabled"] = !!(optiIdEnabled || optiEnabled);

        return updateInstance({ instance: updatedInstance })
            ?.then(() => {
                setEditing(false);
                emitToast({ message: "This instance has been successfully updated." });
            })
            .catch((e: any) => {
                console.error(e);
                setErrorMessage("An error occurred. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let actionText = editing ? "Save" : "Edit";

    const formDirty = isDirty || technicalContact.email !== instance?.technicalContactEmail || optiEnabled;

    const { optiIdEnabled, nickname, name, technicalContactName, technicalContactEmail, id } = instance || {};

    const bothTechnicalContactFields = technicalContactEmail && technicalContactName;
    const contact = technicalContactName || technicalContactEmail || "None";
    const disableEditing = false;

    let warningText = (
        <div>
            Enabling Opti ID can't be reversed. Please ensure all prerequisites are met and the instance has been set up
            on Opti ID. Do you want to proceed?
        </div>
    );

    if (process.env.REACT_APP_CMS_PRODUCT_ID === productId) {
        warningText = (
            <>
                <div>Enabling Opti ID can't be reversed. Do you want to proceed?</div>
                <div className="push-double--top">
                    Have you installed the nugget package? Optimizely Content Management System must have the nuget
                    package installed prior to enabling Opti ID. Do not proceed if the upgrade has not been completed.
                </div>
                <a
                    className="push-double--top"
                    href="https://support.optimizely.com/hc/en-us/articles/18127857412493-Configure-Opti-ID-for-CMS"
                >
                    Have you installed the nuget package?
                </a>
            </>
        );
    } else if (process.env.REACT_APP_B2B_PRODUCT_ID === productId) {
        warningText = (
            <>
                <div>Enabling Opti ID can't be reversed. Do you want to proceed?</div>
                <div className="push-double--top">
                    Optimizely Configured Commerce must be 5.2.2403 or later to use Opti ID. If not upgraded, please
                    cancel enabling Opti ID.
                </div>
            </>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmission)}>
                <div className="push-double--sides ">
                    {!!errorMessage && (
                        <Attention
                            alignment="left"
                            className="push-triple--top"
                            type="bad-news"
                            onDismiss={() => setErrorMessage("")}
                        >
                            {errorMessage}
                        </Attention>
                    )}
                    <label className="oui-label push-double--top" htmlFor="role-description">
                        Name
                    </label>
                    {editing ? (
                        <input
                            aria-describedby="display-name"
                            className="oui-text-input"
                            placeholder="Display Name"
                            id="display-name"
                            disabled={loading}
                            type="text"
                            {...register("nickname")}
                        />
                    ) : (
                        <Typography type="body" className="label--disabled">
                            {nickname || name}
                        </Typography>
                    )}

                    <label className="oui-label push-double--top" htmlFor="technical-contact-name">
                        Technical Contact
                    </label>
                    {editing ? (
                        <UserSearchSelector
                            placeholder={technicalContact.email}
                            onSelectUser={(user) => {
                                if (user) {
                                    setTechnicalContact({
                                        email: user.email,
                                        name: `${user.firstName} ${user.lastName}`
                                    });
                                }
                            }}
                            isUserDisabled={(user) => {
                                return user.email === technicalContactEmail;
                            }}
                        />
                    ) : (
                        <>
                            <Typography type="body" className="label--disabled">
                                {bothTechnicalContactFields ? (
                                    <>
                                        {technicalContactName} <br />
                                        {technicalContactEmail}
                                    </>
                                ) : (
                                    <>{contact}</>
                                )}
                            </Typography>
                        </>
                    )}
                    <div className="push-double--top">
                        <OptiIDEnabledCheckbox
                            checked={optiIdEnabled || optiEnabled}
                            disabled={optiIdEnabled}
                            editing={editing}
                            onChange={toggleOptiEnabled}
                        />
                    </div>

                    <label className="oui-label push-double--top" htmlFor="instance-id">
                        Id
                    </label>
                    <Typography type="body" className="label--disabled">
                        {id}
                    </Typography>
                </div>
                <div className="push-double--sides push-double--top">
                    <LimitByRole action={ATTRIBUTE_ROLES.USERS.UPDATE} mode="disable">
                        <Button
                            key="edit-instance"
                            className="push--right"
                            // eslint-disable-next-line react/style-prop-object
                            style="highlight"
                            isLoading={editing && loading}
                            isDisabled={disableEditing || loading || (editing && !formDirty)}
                            loadingText="Saving"
                            isSubmit
                        >
                            {actionText}
                        </Button>
                        {editing && (
                            // eslint-disable-next-line react/style-prop-object
                            <Button
                                key={0}
                                isDisabled={loading}
                                // eslint-disable-next-line react/style-prop-object
                                style="outline-reverse"
                                onClick={() => setEditing(false)}
                            >
                                Cancel
                            </Button>
                        )}
                    </LimitByRole>
                </div>
            </form>
            {showOptiWarning && (
                <ConfirmationDialog
                    callToActionText="Proceed"
                    onCancel={() => {
                        setShowOptiWarning(false);
                    }}
                    onConfirmation={() => {
                        setOptiEnabled(true);
                        setShowOptiWarning(false);
                    }}
                    title="Warning"
                >
                    {warningText}
                </ConfirmationDialog>
            )}
        </>
    );
};

AccessManagementInstanceDetails.displayName = "AccessManagementInstanceDetails";
