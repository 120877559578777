import { ProductInstance } from "./ProductInstance";
import { OrganizationProduct } from "./OrganizationProduct";
import { IOrgContacts } from "./OrgContacts.interface";

export class Products {
    constructor({
        contactDetails,
        productInstances,
        organizationProducts
    }: {
        contactDetails: IOrgContacts;
        productInstances: ProductInstance[];
        organizationProducts: OrganizationProduct[];
    }) {
        this.contactDetails = contactDetails;
        this.productInstances = productInstances;
        this.organizationProducts = organizationProducts;
    }

    contactDetails: IOrgContacts;
    productInstances: ProductInstance[];
    organizationProducts: OrganizationProduct[] = [];
}
