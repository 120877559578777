import React from "react";
import { ManagerSideNav } from "@optimizely/axiom";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { isChildPath } from "../../../lib/navigation-helpers";

export const NavigationBarSettingsSubMenu = () => {
    const location = useLocation();
    const pathname = location.pathname;

    if (!isChildPath({ pathname: location.pathname, path: "/settings/" })) {
        return null;
    }
    const menuItemClasses = (route: string) => {
        return classNames({
            "oui-manager-side-nav__custom-link": true
        });
    };

    return (
        <ManagerSideNav>
            <ManagerSideNav.Header hasBackLink={false} primaryTitle="Settings" projectName="" isFullHeight />
            <ManagerSideNav.NavList>
                <ManagerSideNav.NavItem
                    isActive={isChildPath({ pathname, path: "/settings/authentication" })}
                    leftContent={
                        <NavLink
                            className={menuItemClasses("/settings/authentication")}
                            to="/settings/authentication/sso"
                        >
                            Authentication
                        </NavLink>
                    }
                />
                <ManagerSideNav.NavItem
                    isActive={isChildPath({ pathname, path: "/settings/notifications" })}
                    leftContent={
                        <NavLink className={menuItemClasses("/settings/notifications")} to="/settings/notifications">
                            Notifications
                        </NavLink>
                    }
                />
            </ManagerSideNav.NavList>
        </ManagerSideNav>
    );
};

NavigationBarSettingsSubMenu.displayName = "NavigationBarSettingsSubMenu";
