import React, { Dispatch, SetStateAction } from "react";
import { Button, DialogNew } from "@optimizely/axiom";
import { ActivationStatusesTable } from "./ActivationStatusesTable";

export const ActivationStatusesModal = ({ showModal }: { showModal: Dispatch<SetStateAction<boolean>> }) => {
    return (
        <DialogNew
            footerButtonList={[
                // eslint-disable-next-line
                <Button style="highlight" onClick={() => showModal(false)}>
                    Cancel
                </Button>
            ]}
            title="Activation Statuses"
            hasCloseButton
            hasOverlay
            onClose={() => showModal(false)}
        >
            <ActivationStatusesTable />
        </DialogNew>
    );
};

ActivationStatusesModal.displayName = "ActivationStatusesModal";
