export interface IIdentityProvider {
    acsUrl?: string;
    audienceUri?: string;
    authorizationUrl?: string;
    certId?: string;
    clientId?: string;
    clientSecret?: string;
    domains: string[];
    id: string;
    idpId?: string;
    idpName?: string;
    issuerUrl: string;
    jwksUrl?: string;
    mfaEnabled?: boolean;
    name?: string;
    provider?: string;
    ssoUrl?: string;
    tokenUrl?: string;
    type: "OIDC" | "SAML";
    userInfoUrl?: string;
    wellKnownUrl?: string;
}

export class IdentityProvider implements IIdentityProvider {
    constructor(idp: any) {
        this.acsUrl = idp?.AssertionConsumerServiceUrl;
        this.audienceUri = idp?.AudienceUrl;
        this.authorizationUrl = idp?.AuthorizationUrl;
        this.certId = idp?.CertId;
        this.clientId = idp?.ClientId;
        this.clientSecret = idp?.ClientSecret;
        this.domains = idp?.Domains || [];
        this.id = idp?.Id;
        this.issuerUrl = idp?.IdpIssuerUri;
        this.jwksUrl = idp?.JwksUrl;
        this.mfaEnabled = idp?.MfaEnabled;
        this.idpId = idp?.IdpId;
        this.idpName = idp?.IdpName;
        this.name = idp?.IdpName; // remove this when idx fixes their things
        this.provider = idp?.Provider;
        this.ssoUrl = idp?.IdpSSOUrl;
        this.tokenUrl = idp?.TokenUrl;
        this.type = idp?.IdpType;
        this.userInfoUrl = idp?.UserInfoUrl;
        this.wellKnownUrl = idp?.WellknownMetadataUrl;
    }

    acsUrl?: string;
    audienceUri?: string;
    authorizationUrl?: string;
    certId?: string;
    clientId?: string;
    clientSecret?: string;
    domains: string[];
    idpId?: string;
    idpName?: string;
    id: string;
    issuerUrl: string;
    jwksUrl?: string;
    mfaEnabled?: boolean;
    name?: string;
    provider?: string;
    ssoUrl?: string;
    tokenUrl?: string;
    type: "OIDC" | "SAML";
    userInfoUrl?: string;
    wellKnownUrl?: string;
}
