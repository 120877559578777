import { InstanceRole } from "./ProductInstancePermission";

export class ProductInstance {
    constructor(productInstance: any) {
        this.instanceId = productInstance?.InstanceId;
        this.instanceName = productInstance?.InstanceName;
        this.nickname = productInstance?.InstanceNickname;
        this.allowCustomerDefinedRoles = productInstance?.AllowCustomerDefinedRoles || false;
        this.productId = productInstance?.ProductId;
        this.productName = productInstance?.ProductName;
        this.permissions = productInstance?.Roles?.map((role: any) => new InstanceRole(role));
    }

    allowCustomerDefinedRoles: boolean;
    instanceId: string;
    instanceName: string;
    nickname: string;
    productId: string;
    productName: string;
    permissions: InstanceRole[];
}
