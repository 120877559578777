import React from "react";
import { Table } from "@optimizely/axiom";
import { LoadingTable } from "../LoadingIndicator/LoadingTable";
import { Invitation } from "../../../domain/Invitation";
import { SupportTableRow } from "./SupportTableRow";

export interface DetailedInvitation extends Invitation {
    organizationName?: string;
}

type SupportTableProps = {
    invitations?: DetailedInvitation[];
    loading?: boolean;
    onClick: ({ invitation }: { invitation: Invitation }) => void;
    onRemove: ({ invitation }: { invitation: Invitation }) => void;
};
export const SupportTable = (props: SupportTableProps) => {
    const { invitations = [], loading, onClick, onRemove } = props;

    if (loading) {
        return <LoadingTable headers={["Organizations", "Added"]} />;
    }

    return (
        <Table
            className="push-double--bottom"
            density="loose"
            // eslint-disable-next-line react/style-prop-object
            style="rule-no-bottom-border"
        >
            <Table.THead>
                <Table.TR>
                    <Table.TH width="45%">Organizations</Table.TH>
                    <Table.TH width="45%">Added</Table.TH>
                    <Table.TH width="10%"></Table.TH>
                </Table.TR>
            </Table.THead>
            <Table.TBody>
                {invitations?.map((i: Invitation) => (
                    <SupportTableRow key={i.id} invitation={i} onClick={onClick} onRemove={onRemove} />
                ))}
            </Table.TBody>
        </Table>
    );
};

SupportTable.dislayName = "SupportTable";
