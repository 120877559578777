import React from "react";
import { Typography } from "@optimizely/axiom";
import { DuplicateRole } from "./DuplicateRole";
import { Role } from "../../../domain/Role";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";
import { SelectRoleProjects } from "./SelectRoleProjects";
import { SelectRoleAttributes } from "./SelectRoleAttributes";

type RoleAttributeSelectionsProps = {
    hideDuplicateRole?: boolean;
    onChange: ({
        preMappedValues,
        id,
        key,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => void;
    productId?: string;
    instanceId?: string;
    roles: Role[];
    selectedAttributes?: RoleAttribute[];
    rolePermissionValue?: string;
};
export const RoleAttributeSelections = ({
    hideDuplicateRole,
    onChange,
    productId,
    instanceId,
    roles,
    selectedAttributes = [],
    rolePermissionValue = ""
}: RoleAttributeSelectionsProps) => {
    const isExp = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
    const productRoles = roles.filter((r) => r.productId === productId);

    const handleDuplicateSelectionChange = ({ role }: { role?: Role }) => {
        if (!role) {
            onChange({ preMappedValues: true, values: [] });
        } else {
            onChange({ preMappedValues: true, values: role.attributes });
        }
    };

    return (
        <>
            {!hideDuplicateRole && !!roles.length && (
                <div className="push-triple--top">
                    <DuplicateRole roles={productRoles} onChange={handleDuplicateSelectionChange} />
                </div>
            )}
            <div className="push-triple--top">
                <Typography type="header4">
                    <span style={{ fontWeight: 300 }}>
                        Select Attributes <span aria-label="(required)" className="oui-label--required" />
                    </span>
                </Typography>
            </div>
            <div className="push--bottom">
                <Typography type="body" className="push--bottom">
                    Check all attributes to be assigned to this role.
                </Typography>
            </div>

            {isExp ? (
                <SelectRoleProjects
                    onChange={onChange}
                    productId={productId}
                    instanceId={instanceId}
                    selectedAttributes={selectedAttributes}
                    rolePermissionValue={rolePermissionValue}
                />
            ) : (
                <SelectRoleAttributes
                    onChange={onChange}
                    productId={productId}
                    selectedAttributes={selectedAttributes}
                />
            )}
        </>
    );
};

RoleAttributeSelections.displayName = "RoleAttributeSelections";
