import { Flags } from "../feature-flags/flags";

export const EVERYONE_GROUP_NAME = "Everyone";

export const ADMINISTRATOR = "Administrator";
export const ADMINCENTER_GROUP_NAME = "Admin Center Administrators";
export const ADMINCENTER_PRODUCT_NAME = "Optimizely Admin Center";
export const DUPLICATE_GROUP_ERROR_MESSAGE = "Group name already exists. Please choose another name.";
export const DUPLICATE_EMAIL_ERROR_MESSAGE = "Unable to add user at this time. Please contact your admin.";
export const INVITATION_ERROR_MESSAGE = "An error occurred while sending invitation.";
export const INVITATION_SUCCESS_MESSAGE = "Invitation successfully sent.";
export const UNLOCK_USER_SUCCESS_MESSAGE = "User successfully unlocked.";
export const ACTIVATE_USER_SUCCESS_MESSAGE = "User successfully activated.";
export const ADD_GROUP_ACCESS_SUCCESS_MESSAGE = "Group access successfully added.";
export const REMOVE_GROUP_ACCESS_SUCCESS_MESSAGE = "Group access successfully removed.";
export const ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE =
    "Unable to perform the requested operation, please try again later.";
export const ADMINCENTER_PRODUCT_ID = "404d1878bae04092a24d9f3a032769be";
export const DEFAULT_PAGE_SIZE = 20;
export const ALL_PAGES_SIZE = 10000;
export const DEFAULT_DEBOUNCE = 500;
export const ROLES_WARNING = "Managing roles is only available for certain products at this time";
export const ROLES_HIDE_WARNING_STORAGE_KEY = "admin-center.roles:hide-warning";
export const TOASTER_EVENT_NAME = "@admin-center:toast";
export const ADMINCENTER_ITEM_UPDATED_EVENT_NAME = "@admin-center:item-updated";
export const REFETCH_PERMISSIONS_EVENT_NAME = "@opti-product-switcher:refetch";
export const ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME = "@admin-center:custom-group-access-review";
export const ALL = "All";

export const ALL_PROJECTS_ID = "allProjects";
export const ALL_PROJECTS_NAME = "All Projects";
export const SAML = "SAML";
export const OIDC = "OIDC";

export const NONE = "None";
export const NO_RESTRICTIONS = "No restrictions";
export const SYSTEM_ROLE_TYPE = "ProductDefined";
export const UNEXPECTED_ERROR = "An unexpected error occurred. Please try again.";
export const CUSTOMER_DEFINED_PRODUCT_ROLE = "CustomerDefined_Product";
export const CUSTOMER_DEFINED_INSTANCE_ROLE = "CustomerDefined_Instance";
export const EXP_PERMISSIONS_KEY = "permissions";

export const SSO_CONNECTION_LIMIT = 5;
export const ALL_INSTANCES_OPTION = { name: ALL, id: "", nickname: "All instances (current and future)" };

export const TOGGLE_ALL_INSTANCES_OPTION = { name: "Select all instances", id: "", nickname: "" };

export const ROLE_MAPPINGS: { [index: string]: string } = {
    default: "Custom",
    CustomerDefined_Product: "Custom",
    ProductDefined: "System",
    CustomerDefined_Instance: "Custom"
};

export const enum CONTEXT_SCOPES {
    ORGANIZATION = "organization",
    PRODUCT = "product",
    INSTANCE = "instance",
    PROJECT = "project"
}

export const enum GROUP_TYPES {
    PRODUCT = "product",
    INTERNAL = "internal",
    CUSTOM = "custom"
}

export const enum ACCESS_TYPES {
    SUPER_ADMIN = "All",
    PRODUCT = "Product",
    INSTANCE = "Instance",
    PROJECT = "Project"
}

export const enum EVENT_TYPES {
    UPDATE_USER = "update-user",
    ADD_USER = "add-user",
    DELETE_USER = "delete-user",

    UPDATE_GROUP = "update-group",
    ADD_GROUP = "add-group",
    DELETE_GROUP = "delete-group",

    UPDATE_ROLE = "update-role",
    ADD_ROLE = "add-role",
    DELETE_ROLE = "delete-role",

    UPDATE_INSTANCE = "update-instance",

    ADD_USER_TO_GROUP = "add-user-to-group",
    REMOVE_USER_FROM_GROUP = "remove-user-from-group",

    REMOVE_INSTANCE_FROM_GROUP = "remove-instance-from-group",
    ADD_INSTANCE_TO_GROUP = "add-instance-to-group"
}

export const ATTRIBUTE_SCOPES = {
    PROJECT: "AttributeId",
    INSTANCE: "InstanceId",
    PRODUCT: "ProductId"
};
export const ATTRIBUTE_ROLES = {
    GROUPS: {
        CREATE: "Create Groups",
        READ: "Read Groups",
        UPDATE: "Update Groups",
        MANAGE: "Manage Group Members"
    },
    INVITATIONS: {
        CREATE: "Create Invitations",
        READ: "Read Invitations",
        UPDATE: "Update Invitations"
    },
    PRODUCTS: {
        READ: "Read Products"
    },
    ROLES: {
        CREATE: "Create Roles",
        READ: "Read Roles",
        UPDATE: "Update Roles"
    },
    USERS: {
        READ: "Read Users",
        UPDATE: "Update Users"
    },
    BILLING: {
        READ: "Read Billing Dashboard"
    },
    SETTINGS: {
        UPDATE: "Manage Settings"
    }
};

export const ROUTE_VIEW_MAPPINGS: {
    [index: string]: {
        name: string;
        actions?: string[];
        priorityOrder: number;
        featureFlag?: string;
        context?: { [key: string]: string | undefined };
    };
} = {
    "/entitlements": {
        name: "entitlements",
        actions: [ATTRIBUTE_ROLES.PRODUCTS.READ],
        priorityOrder: 1,
        featureFlag: Flags.ENABLE_ACCESS_MANAGEMENT
    },
    "/access/products": { name: "products", priorityOrder: 2, featureFlag: Flags.ENABLE_ACCESS_MANAGEMENT },
    "/user-management/users": {
        name: "users",
        actions: [ATTRIBUTE_ROLES.USERS.READ, ATTRIBUTE_ROLES.USERS.UPDATE],
        priorityOrder: 3,
        context: { scopedAccess: ACCESS_TYPES.PRODUCT }
    },
    "/user-management/roles": {
        name: "roles",
        actions: [ATTRIBUTE_ROLES.ROLES.CREATE, ATTRIBUTE_ROLES.ROLES.READ, ATTRIBUTE_ROLES.ROLES.UPDATE],
        priorityOrder: 4,
        context: { scopedAccess: ACCESS_TYPES.PRODUCT }
    },
    "/user-management/user-groups": {
        name: "groups",
        actions: [ATTRIBUTE_ROLES.GROUPS.CREATE, ATTRIBUTE_ROLES.GROUPS.READ, ATTRIBUTE_ROLES.GROUPS.UPDATE],
        priorityOrder: 5,
        context: { scopedAccess: ACCESS_TYPES.PRODUCT }
    },
    "/user-management/invitations": {
        name: "invitations",
        actions: [
            ATTRIBUTE_ROLES.INVITATIONS.CREATE,
            ATTRIBUTE_ROLES.INVITATIONS.READ,
            ATTRIBUTE_ROLES.INVITATIONS.UPDATE
        ],
        priorityOrder: 6,
        context: { scopedAccess: ACCESS_TYPES.PRODUCT }
    },
    "/usage": {
        name: "usage",
        actions: [ATTRIBUTE_ROLES.BILLING.READ],
        priorityOrder: 7,
        featureFlag: Flags.SHOW_USAGE_AND_BILLING,
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/support": {
        name: "support",
        priorityOrder: 8,
        featureFlag: Flags.ENABLE_SUPPORT_FEATURES,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/support/access": {
        name: "support-access",
        priorityOrder: 9,
        featureFlag: Flags.ENABLE_SUPPORT_FEATURES,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "support/user-migration": {
        name: "user-migration",
        priorityOrder: 13,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/account-management/sso": {
        name: "sso-settings",
        priorityOrder: 10,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/account-management/domains": {
        name: "domains",
        priorityOrder: 11,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/account-management/mfa": {
        name: "mfa",
        priorityOrder: 12,
        actions: [ATTRIBUTE_ROLES.SETTINGS.UPDATE],
        context: { scopedAccess: ACCESS_TYPES.SUPER_ADMIN }
    },
    "/access/users": { name: "users", priorityOrder: 13, featureFlag: Flags.ENABLE_ACCESS_MANAGEMENT }
};

export const INVITATION_STATUS: { [index: string]: string } = {
    EXPIRED: "Expired",
    REVOKED: "Revoked",
    PENDING: "Pending",
    ACCEPTED: "Accepted"
};

export const INVITATION_RESEND_ERROR_MESSAGE =
    "Invitation was unable to be resent at this time. Please try again later or contact your administrator.";
export const INVITATION_REVOKE_ERROR_MESSAGE =
    "Invitation was unable to be revoked at this time. Please try again later or contact your administrator.";

export const OPTIMIZELY_ORG_ID = process.env.REACT_APP_OPTIMIZELY_ORG_ID || "";

export const EXP_PROJECT_TYPES = { WEB: "Web", LEGACY: "Legacy Full Stack", FEATURE: "Feature Experimentation" };

export const EXP_ROLE_PERMISSION_MAPPING: { [index: string]: string[] } = {
    Publisher: [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY, EXP_PROJECT_TYPES.FEATURE],
    "Restricted Publisher": [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY],
    Editor: [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY, EXP_PROJECT_TYPES.FEATURE],
    "Restricted Editor": [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY],
    Viewer: [EXP_PROJECT_TYPES.WEB, EXP_PROJECT_TYPES.LEGACY, EXP_PROJECT_TYPES.FEATURE]
};

export const EXP_PROJECT_OWNER_PERMISSION = "Project Owner";

export const GUID_PATTERN = /\b[a-f\d]{32}\b/g;
export const URL_PATTERN =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const UNSAVED_CHANGES_CONFIRMATION_DO_NOT_SHOW_AGAIN = "admin-center:do-not-show-unsaved-confirmation";

export const USER_EXTERNAL_STATUS: { [index: string]: string } = {
    ACTIVE: "Active",
    DEPROVISIONED: "Deactivated",
    LOCKEDOUT: "Locked Out",
    PASSWORDEXPIRED: "Password Expired",
    PROVISIONED: "Pending User Action",
    RECOVERY: "Password Reset",
    STAGED: "Staged",
    SUSPENDED: "Suspended",
    NOTFOUND: "Not Found"
};

export const enum SWR_KEY_PREFIX {
    USERS = "list-of-users:",
    GROUPS = "list-of-groups:",
    ROLES = "list-of-roles:",
    INSTANCES = "list-of-instances:"
}

export const enum CUSTOM_GROUP_ACCESS_ACTION {
    ADD = "Add Access",
    REMOVE = "Remove Access"
}

export const CMS_SAAS_DASHBOARD_PRODUCT_TAB_NAME = "CMS SaaS";
export const CMS_PAAS_DASHBOARD_PRODUCT_TAB_NAME = "CMS PaaS";
