import { useEffect } from "react";
import { TabNav } from "@optimizely/axiom";

import styles from "./UsageProductTabs.module.scss";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";

type UsageProductTabsProps = {
    productId?: string;
    products?: OrganizationProduct[] | undefined;
    onProductClick: ({ id }: { id: string }) => void;
};

export const UsageProductTabs = ({ productId = "", products = [], onProductClick }: UsageProductTabsProps) => {
    useEffect(() => {
        !productId && !!products && onProductClick({ id: products[0]?.id || "" });
        // Only want to run this check upon mounting. Products will be defined by the time this component renders
        // eslint-disable-next-line
    }, []);

    if (!products?.length) {
        return null;
    }

    return (
        <TabNav
            className={styles["usage-product-tabs"]}
            activeTab={productId || products?.[0]?.id || ""}
            style={["sub"]}
        >
            {products?.map((product) => {
                return (
                    <TabNav.Tab
                        className={styles["usage-product-tabs__tab"]}
                        aria-label={product.name}
                        onClick={() => onProductClick({ id: product.id })}
                        role="tab"
                        key={product.id}
                        tabId={product.id}
                    >
                        {product.name}
                    </TabNav.Tab>
                );
            })}
        </TabNav>
    );
};

UsageProductTabs.displayName = "UsageProductTabs";
