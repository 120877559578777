/* eslint-disable react/style-prop-object */
import { Table, Typography } from "@optimizely/axiom";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";
import { IOrgContacts } from "../../../domain/OrgContacts.interface";
import { NavLink } from "react-router-dom";

interface ProductListProps {
    contactDetails: IOrgContacts | undefined;
    products: OrganizationProduct[];
    accessManagementEnabled?: boolean | false;
}

export const ProductList = ({ contactDetails, products, accessManagementEnabled }: ProductListProps) => {
    const displayRecord = (product: OrganizationProduct) => {
        const href = accessManagementEnabled
            ? `/access/products/${product.id}/instances`
            : `/entitlements/${product.id}`;
        return (
            <Table.TR key={product.id}>
                <Table.TD>
                    <NavLink to={href} className="link button-as-link" type="button">
                        {product.name}
                    </NavLink>
                </Table.TD>
                <Table.TD>{product.instances.length}</Table.TD>
            </Table.TR>
        );
    };

    const {
        accountExecutiveName,
        accountExecutivePhone,
        accountExecutiveEmail,
        supportManagerName,
        supportManagerPhone,
        supportManagerEmail
    } = contactDetails || {};

    return (
        <div className="products list-page">
            <div className="flex flex--row flex-justified--end push-double--bottom">
                {(accountExecutiveName || accountExecutivePhone || accountExecutiveEmail) && (
                    <div className="push--sides">
                        <Typography type="subhead">Account Executive</Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {accountExecutiveName}
                        </Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {accountExecutivePhone}
                        </Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {accountExecutiveEmail}
                        </Typography>
                    </div>
                )}
                {(supportManagerName || supportManagerPhone || supportManagerEmail) && (
                    <div className="push--sides">
                        <Typography type="subhead">Customer Support Manager</Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {supportManagerName}
                        </Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {supportManagerPhone}
                        </Typography>
                        <Typography tag="div" type="body" className="label--disabled">
                            {supportManagerEmail}
                        </Typography>
                    </div>
                )}
            </div>
            {products?.length > 0 && (
                <Table
                    className="entitlements__table push-double--bottom"
                    density="loose"
                    style="rule-no-bottom-border"
                >
                    <Table.THead>
                        <Table.TR>
                            <Table.TH width="80%">Name</Table.TH>
                            <Table.TH width="20%">Instances</Table.TH>
                        </Table.TR>
                    </Table.THead>
                    <Table.TBody>{products.map((product) => displayRecord(product))}</Table.TBody>
                </Table>
            )}
        </div>
    );
};
