import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../../feature-flags/flags";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";

/**
 * Routes the user to the new `/settings` routes if the feature flag is enabled.
 * This should be deleted and the `account-management` routes should be removed once the feature flag is disabled and this becomes the default behavior.
 * @constructor
 */
export const SettingsRouting = () => {
    const { enabled: settingsEnabled } = useFeatureFlag(Flags.ENABLE_BILLING_NOTIFICATION_SETTINGS);
    const { pathname } = useLocation();
    // handle account-management routes; if the flag is enabled redirect those routes to `settings/{route}`
    if (settingsEnabled && pathname.includes("/account-management/")) {
        const updatedPathname = pathname.replace("/account-management/", "/settings/authentication/");
        return <Navigate to={updatedPathname} replace />;
    }

    return <Outlet />;
};
