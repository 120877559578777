import { ISAMLIdp } from "../../../../../domain/IdentityProvider";
import React, { useEffect, useReducer, useRef } from "react";
import { ConnectionNameInput } from "../../ConnectionNameInput/ConnectionNameInput";
import { IssuerURLField } from "../../SSOOIDCForm/SSOOIDCFormFields/IssuerURLField";
import { SSOURLField } from "../../SSOOIDCForm/SSOOIDCFormFields/SSOURLField";
import { CertificateUploadButton } from "../../CertificateUploadButton/CertificateUploadButton";

export interface ISSOSamlFormFields {
    idpName: string;
    issuerUrl: string;
    ssoUrl: string;
    signatureCertificate: File | null;
}
const fieldReducer = (state: ISSOSamlFormFields, changes: { [key: string]: any }) => {
    return {
        ...state,
        ...changes
    };
};

type SSOSamlFormFieldProps = {
    certificateError?: string;
    disabled?: boolean;
    onChange?: (values: ISSOSamlFormFields) => void;
    idp?: ISAMLIdp;
};

export const emptySAMLForm = {
    idpName: "",
    issuerUrl: "",
    ssoUrl: "",
    signatureCertificate: null
};
export const SSOSamlFormFields = ({ certificateError, disabled, idp, onChange }: SSOSamlFormFieldProps) => {
    const [values, updateValues] = useReducer<
        (state: ISSOSamlFormFields, changes: { [key: string]: any }) => ISSOSamlFormFields
    >(fieldReducer, {
        ...emptySAMLForm,
        idpName: idp?.idpName || "",
        issuerUrl: idp?.issuerUrl || "",
        ssoUrl: idp?.ssoUrl || ""
    });
    const usedOnChange = useRef(onChange);

    useEffect(() => {
        if (!usedOnChange.current) {
            usedOnChange.current = onChange;
        }
    }, [onChange]);

    useEffect(() => {
        if (!usedOnChange.current) {
            return;
        }
        usedOnChange.current(values);
    }, [values]);

    return (
        <>
            <ConnectionNameInput
                disabled={disabled}
                onChange={(value) => updateValues({ idpName: value })}
                value={values.idpName}
            />
            <IssuerURLField
                disabled={disabled}
                hideHelperText={disabled}
                required
                onChange={(value) => updateValues({ issuerUrl: value })}
                value={values.issuerUrl}
            />
            <SSOURLField
                onChange={(value) => updateValues({ ssoUrl: value })}
                required
                hideHelperText={disabled}
                disabled={disabled}
                value={values.ssoUrl}
            />
            {!disabled && (
                <CertificateUploadButton
                    error={certificateError}
                    onChange={(file) => {
                        updateValues({ signatureCertificate: file });
                    }}
                    disabled={disabled}
                />
            )}
        </>
    );
};

SSOSamlFormFields.displayName = "SSOSamlFormFields";
