import { Typography } from "@optimizely/axiom";
import React from "react";

type AccessManagementUserFormAccessHeaderProps = {
    children?: React.ReactNode;
};

export const AccessManagementUserFormAccessHeader = ({ children }: AccessManagementUserFormAccessHeaderProps) => {
    return (
        <div className="flex push-double--top push--bottom justify-content-between align-items-center">
            <Typography type="header3" tag="div">
                Access
            </Typography>
            {children}
        </div>
    );
};

AccessManagementUserFormAccessHeader.displayName = "AccessManagementUserFormAccessHeader";
