import React, { useState } from "react";
import { LayoutGridCell } from "@optimizely/axiom";
import {
    ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
    CUSTOM_GROUP_ACCESS_ACTION,
    GROUP_TYPES
} from "../../../../constants/constants";
import { useGroupsByUser } from "../../../../hooks/useGroupsByUser/useGroupsByUser";
import { useOrgUserGroups } from "../../../../hooks/useOrgUserGroups/useOrgUserGroups";
import { FilterDropdown } from "../../FilterDropdown/FilterDropdown";
import { AccessManagementUserFormIconButton } from "../AccessManagementUserForm/AccessManagementUserFormIconButton";
import { UserGroup } from "../../../../domain/UserGroup";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";

import styles from "./AddGroupAccessForm.module.scss";
import { Flags } from "../../../../feature-flags/flags";
import { useUserGroups } from "../../../../hooks/useUserGroups/useUserGroups";
import { datadogRum } from "@datadog/browser-rum";
import { emitToast } from "../../../../lib/toaster-utils";

type userGroupDropdownItem = {
    key: string;
    label: string;
    description: string;
};

type AddGroupAccessFormProps = {
    customGroups?: boolean;
    email: string;
    onClose: () => void;
    onError?: React.Dispatch<React.SetStateAction<string | null>>;
    organizationId: string | undefined;
};

export const AddGroupAccessForm = ({
    customGroups = false,
    email,
    onClose,
    onError,
    organizationId
}: AddGroupAccessFormProps) => {
    const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup | undefined>(undefined);
    const { groups, revalidate } = useGroupsByUser({
        organizationId: organizationId || null,
        email
    });

    const { enabled: enableSeparateUsersPage } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const [savingGroups, setSavingGroups] = useState<boolean>(false);
    const { addUserGroupUsers } = useUserGroups({ organizationId });

    const { userGroups: orgUserGroups } = useOrgUserGroups({
        organizationId
    });

    const filterUserGroupDropdownList = () => {
        if (!orgUserGroups) return [];

        const groupIdsList = (groups || []).reduce((groupIds: { [id: string]: boolean }, { id }) => {
            groupIds[id] = true;
            return groupIds;
        }, {});

        return orgUserGroups.reduce((dropdownList: userGroupDropdownItem[], ug) => {
            const isNotInGroups = !groupIdsList[ug.id];
            const isValidGroupType = customGroups
                ? ug.groupType === GROUP_TYPES.CUSTOM
                : ug.groupType !== GROUP_TYPES.INTERNAL;

            if (isNotInGroups && isValidGroupType) {
                dropdownList.push({
                    key: ug.id,
                    label: ug.name,
                    description: ug.description
                });
            }

            return dropdownList;
        }, []);
    };

    const userGroupDropdownList = filterUserGroupDropdownList();

    const onGroupAccessSelect = () => {
        window.dispatchEvent(
            new CustomEvent(ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME, {
                detail: {
                    action: CUSTOM_GROUP_ACCESS_ACTION.ADD,
                    id: selectedUserGroup?.id,
                    name: selectedUserGroup?.name,
                    userGroup: selectedUserGroup
                }
            })
        );
    };

    const onGroupAccessSave = () => {
        setSavingGroups(true);

        addUserGroupUsers({
            userGroupId: selectedUserGroup?.id || "",
            userEmails: [email]
        })
            .then(() => {
                setSavingGroups(false);
                setSelectedUserGroup(undefined);
                onClose();
                emitToast({ message: "Group access successfully added." });
                revalidate();
            })
            .catch((e) => {
                setSavingGroups(false);
                !!onError && onError(`Failed to add new group access to user: ${e.message}`);
                datadogRum.addError(e);
                console.error("Failed to add new group access to user: ", e);
            });
    };

    return (
        <LayoutGridCell large={10} medium={8} small={4} xlarge={12}>
            <div className={`push-double--top push-double--bottom ${styles["add-group-access"]}`}>
                <span className={`push--right ${styles["add-group-access__group-dropdown"]}`}>
                    <FilterDropdown
                        label="Group"
                        filterPlaceholder="Search User Groups"
                        dropdownPlaceholder="Select a group..."
                        selectedText={selectedUserGroup?.name || undefined}
                        onItemSelected={({ item }) => {
                            setSelectedUserGroup((orgUserGroups || []).find((i) => i.id === item.key));
                        }}
                        items={userGroupDropdownList}
                    />
                </span>
                <span className={`${styles["add-group-access__icons"]}`}>
                    <span className="push--sides">
                        <AccessManagementUserFormIconButton
                            title="Save Group Access"
                            icon="check"
                            loading={savingGroups}
                            onClick={enableSeparateUsersPage ? onGroupAccessSelect : onGroupAccessSave}
                            disabled={!selectedUserGroup}
                            white
                        />
                    </span>
                    <AccessManagementUserFormIconButton
                        title="Cancel Group Access"
                        icon="xmark"
                        onClick={() => {
                            setSelectedUserGroup(undefined);
                            onClose();
                        }}
                        disabled={savingGroups}
                    />
                </span>
            </div>
        </LayoutGridCell>
    );
};

AddGroupAccessForm.displayName = "AddGroupAccessForm";
