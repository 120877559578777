export class ProductAttribute {
    constructor(attribute: any) {
        const { description, id, instanceId, isActive, key, name, type, values = [], scopes = [] } = attribute;
        this.description = description;
        this.id = id;
        this.instanceId = instanceId;
        this.isActive = isActive;
        this.key = key;
        this.name = name;
        this.type = type;
        this.values = values;
        this.scopes = scopes;
    }

    description: string;
    id: string;
    instanceId: string;
    isActive: boolean;
    key: string;
    name: string;
    type: string;
    values: string[];
    scopes?: { name: string; value: string }[];
}
