/* eslint react/style-prop-object: 0 */
import React, { useState } from "react";
import { Button, Typography } from "@optimizely/axiom";

import { IdentityProvider, ISAMLIdp } from "../../../../domain/IdentityProvider";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { datadogRum } from "@datadog/browser-rum";

export const SSOConnectionDetails = ({
    className = "",
    identityProvider: connectionIdp,
    onRemove
}: {
    className?: string;
    identityProvider: IdentityProvider | null;
    onRemove: () => Promise<void>;
}) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deletionError, setDeletionError] = useState("");
    const [deletionInProgress, setDeletionInProgress] = useState(false);

    const handleRemove = async () => {
        setDeletionInProgress(true);
        try {
            await onRemove();
            setShowConfirmation(false);
        } catch (error: any) {
            datadogRum.addError(error);
            setDeletionError("An error occurred while deleting your SSO Connection");
        } finally {
            setDeletionInProgress(false);
        }
    };

    if (!connectionIdp) {
        return null;
    }

    const isSAML = connectionIdp.type === "SAML";
    const samlProvider = connectionIdp as ISAMLIdp;

    return (
        <div className={`flex flex--column flex-align--start ${className}`}>
            <>
                <Typography type="header4">SSO Connection Details</Typography>
                <Typography tag="p" type="caption">
                    Use the following information for your SSO provider to complete setup.
                </Typography>
                {isSAML && (
                    <>
                        <Typography type="caption">Audience URL</Typography>
                        <Typography type="xs">
                            <span style={{ color: "#707070" }}>{samlProvider.audienceUrl}</span>
                        </Typography>

                        <Typography className="push-double--top" type="caption">
                            Assertion Consumer Service URL
                        </Typography>
                        <Typography type="xs">
                            <span style={{ color: "#707070" }}>{samlProvider.acsUrl}</span>
                        </Typography>
                    </>
                )}
                <Typography className="push-double--top" type="caption">
                    IDP-Initiated URL
                </Typography>
                <Typography type="xs">
                    <span
                        style={{ color: "#707070" }}
                    >{`${process.env.REACT_APP_HOME_APP_URL}/?idpId=${connectionIdp.idpId}`}</span>
                </Typography>
            </>
            <Button
                className="push-triple--top flex--1"
                style="danger-outline"
                isDisabled={showConfirmation || deletionInProgress}
                onClick={() => setShowConfirmation(true)}
            >
                Remove Connection
            </Button>
            {showConfirmation && (
                <>
                    <ConfirmationDialog
                        onCancel={() => setShowConfirmation(false)}
                        onConfirmation={handleRemove}
                        callToActionText="Remove"
                        error={deletionError}
                        loading={deletionInProgress}
                        title="Remove Connection"
                    >
                        <div className="push-double--bottom">
                            Your SSO connection is currently active and removing the setup details will cause a
                            disruption to services.
                        </div>
                        <div className="push-double--bottom">
                            <strong style={{ fontWeight: "700" }}>Warning:</strong> Your account will be reconfigured
                            for local authentication and all users will need to set up a new password from the
                            activation email that will sent.
                        </div>
                        <div>
                            <strong style={{ fontWeight: "700" }}>Note: </strong>Removing the SSO connection does{" "}
                            <strong style={{ fontWeight: "700" }}>not</strong> remove any existing Domains.
                        </div>
                    </ConfirmationDialog>
                </>
            )}
        </div>
    );
};

SSOConnectionDetails.displayName = "SSOConnectionDetails";
