import { InstancePermission } from "./InstancePermission";

export class UserGroup {
    constructor({
        id,
        isInstanceGroup,
        name,
        organizationId,
        description,
        userCount,
        groupOwner,
        groupType,
        created,
        modified,
        instancePermissions,
        users = []
    }: {
        id: string;
        isInstanceGroup?: boolean;
        name: string;
        organizationId: string;
        description: string;
        userCount: number;
        groupOwner: string | null;
        groupType?: string | undefined;
        created: Date;
        modified: Date;
        instancePermissions: InstancePermission[];
        users: string[];
    }) {
        this.id = id;
        this.isInstanceGroup = isInstanceGroup || false;
        this.name = name;
        this.organizationId = organizationId;
        this.description = description;
        this.userCount = userCount || (users || []).length;
        this.groupType = groupType;
        this.groupOwner = groupOwner;
        this.created = created;
        this.modified = modified;
        this.instancePermissions = instancePermissions;
        this.users = users;
    }
    id: string;
    isInstanceGroup: boolean;
    name: string;
    organizationId: string;
    description: string;
    userCount: number;
    groupOwner: string | null;
    groupType?: string | undefined;
    created: Date;
    modified: Date;
    instancePermissions: InstancePermission[];
    users: string[];
}
