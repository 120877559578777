export class Invitation {
    constructor(invitation: any) {
        this.id = invitation.Id;
        this.createdBy = invitation.CreatedBy;
        this.sentDates = invitation.SentDates;
        this.acceptedDate = invitation.AcceptedDate;
        this.expirationDate = invitation.ExpirationDate;
        this.revokedBy = invitation.RevokedBy;
        this.revokedDate = invitation.RevokedDate;
        this.created = invitation.Created;
        this.modified = invitation.Modified;
        this.firstName = invitation.FirstName;
        this.lastName = invitation.LastName;
        this.email = invitation.Email;
        this.requireAcceptance = invitation.RequireAcceptance;
        this.organizationId = invitation.OrganizationId;
        this.organizationName = invitation.OrganizationName;
        this.userGroups = invitation.UserGroups
            ? invitation.UserGroups.map((userGroup: { Id: string; Name: string }) => {
                  return { id: userGroup.Id, name: userGroup.Name };
              })
            : [];
        this.properties = invitation.Properties;
    }
    acceptedDate: string | null;
    created: string;
    createdBy: string;
    email: string;
    expirationDate: string | null;
    firstName: string;
    lastName: string;
    id: string;
    modified: string;
    organizationId: string;
    organizationName?: string;
    userGroups: { id: string; name: string }[];
    properties: any[];
    requireAcceptance: boolean;
    revokedBy: string | null;
    revokedDate: string | null;
    sentDates: string[];
}
