import useSWR from "swr";

import { IdentityProvider, OIDCIdpWithoutId } from "../../domain/IdentityProvider";
import { useServicesContext } from "../../providers/ServicesProvider";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

export const useOrganizationIdp = (organizationId?: string) => {
    const { idpService, organizationService } = useServicesContext();

    // todo: remove IdentityProvider type, switch to using the OIDC service SAML method
    const {
        mutate: mutateIdp,
        data: identityProviders,
        error
    } = useSWR<IdentityProvider[] | null, Error>(
        organizationId ? `/api/organizations/${organizationId}/identityproviders` : null,
        () => {
            if (!organizationId) {
                return null;
            }

            return idpService!.getOrganizationIdentityProviders({ organizationId });
        }
    );

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    const addSAMLProvider = async ({
        issuer,
        ssoUrl,
        certificate,
        idpName
    }: {
        issuer: string;
        ssoUrl: string;
        certificate: File;
        idpName: string;
    }): Promise<IdentityProvider> => {
        const idp = await idpService?.addSAMLProvider({
            organizationId: organizationId as string,
            issuer,
            ssoUrl,
            certificate,
            idpName
        });

        await mutateIdp([...(identityProviders || []), idp as IdentityProvider]);

        return idp as IdentityProvider;
    };

    const addOIDCProvider = async (oidcProvider: OIDCIdpWithoutId): Promise<IdentityProvider> => {
        const result = await idpService?.addOIDCProvider({
            ...oidcProvider,
            organizationId: organizationId as string
        });
        const idp = result as IdentityProvider;

        await mutateIdp([...(identityProviders || []), idp]);

        return idp;
    };

    const toggleMFA = async ({ enabled }: { enabled: boolean }) => {
        return await organizationService!.updateOrganization({
            id: organizationId as string,
            mfaEnabled: enabled
        });
    };

    const deleteOrganizationProviders = async () => {
        await idpService!.deleteOrganizationIdentityProviders({
            organizationId: organizationId as string
        });
        await mutateIdp(null);

        return null;
    };

    const deleteOrganizationProvider = async ({ id }: { id: string }) => {
        await idpService!.deleteOrganizationIdentityProvider({
            organizationId: organizationId as string,
            id: id
        });
        await mutateIdp(null);

        return null;
    };

    const updateIdpDomains = async ({
        domains,
        identityProviderId
    }: {
        domains: string[];
        identityProviderId: string;
    }) => {
        const updatedDomains = await idpService!.updateIdentityProviderDomains({
            identityProviderId,
            organizationId: organizationId as string,
            domains
        });
        const updatedIdps = [...(identityProviders || [])];
        const updateIdx = updatedIdps.findIndex((idp) => idp.id === identityProviderId);
        updatedIdps[updateIdx] = updatedDomains;

        await mutateIdp(updatedIdps);

        return updatedDomains;
    };

    return {
        addOIDCProvider,
        addSAMLProvider,
        deleteOrganizationProvider,
        deleteOrganizationProviders,
        updateIdpDomains,
        identityProviders: identityProviders || null,
        isLoadingIdp: !error && identityProviders === undefined,
        toggleMFA,
        mutateIdp,
        error
    };
};
