import { IProductAttribute } from "../domain/ProductAttribute.interface";
import { Collection } from "../domain/Collection";
import { ProductAttributeAdapter } from "./ProductAttributeAdapter";
import { IIdentityRepository } from "../repositories/IdentityRepository";
import { GetAttributeParameters } from "../rest-model/GetAttributeParameters";

export interface IAttributeService {
    getAttributes({
        isActive,
        key,
        offset = 0,
        productId,
        size = 100,
        types = [],
        name = ""
    }: GetAttributeParameters): Promise<Collection<IProductAttribute>>;

    getAttribute(id: string): Promise<IProductAttribute>;
}

export class AttributeService implements IAttributeService {
    attributeAdapter: ProductAttributeAdapter;
    identityRepository: IIdentityRepository;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.attributeAdapter = new ProductAttributeAdapter();
    }

    public async getAttributes(parameters: GetAttributeParameters): Promise<Collection<IProductAttribute>> {
        const attributes = await this.identityRepository.getAttributes(parameters);
        return {
            items: attributes?.items.map((attr) => this.attributeAdapter.adaptToDomain(attr)),
            totalCount: attributes.totalCount
        };
    }

    public async getAttribute(id: string): Promise<IProductAttribute> {
        const attribute = await this.identityRepository.getAttribute(id);
        return this.attributeAdapter.adaptToDomain(attribute);
    }
}
