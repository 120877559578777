import { useEffect } from "react";
import { useSession } from "../../../hooks/useSession/useSession";
import { useUserContext } from "../../../providers/UserProvider";

/**
 * Initiates the UserFlow session. Since the created date is one of the fields, a component was created so that
 * we can leverage the UserProvider and pull that information out.
 * @constructor
 */
export const UserFlow = () => {
    const { user } = useSession();
    const { profile } = useUserContext();

    const convertToISOString = (date: Date) => {
        try {
            const timezone = -date.getTimezoneOffset(),
                dif = timezone >= 0 ? "+" : "-",
                pad = function (num: number) {
                    return (num < 10 ? "0" : "") + num;
                };

            return (
                date.getFullYear() +
                "-" +
                pad(date.getMonth() + 1) +
                "-" +
                pad(date.getDate()) +
                "T" +
                pad(date.getHours()) +
                ":" +
                pad(date.getMinutes()) +
                ":" +
                pad(date.getSeconds()) +
                dif +
                pad(Math.floor(Math.abs(timezone) / 60)) +
                ":" +
                pad(Math.abs(timezone) % 60)
            );
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        const userflow = window.userflow;
        if (userflow && profile && user) {
            const signed_up_opti_id = convertToISOString(profile.created);
            const payload: { name?: string; email: string; signed_up_opti_id?: string } = {
                name: user.name, // using the okta name instead of the modifiable value
                email: profile.email
            };
            if (signed_up_opti_id) {
                payload["signed_up_opti_id"] = signed_up_opti_id;
            }

            userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
            userflow.identify(profile.email, payload);
        }
    }, [user, profile]);

    return <></>;
};

UserFlow.displayName = "UserFlow";
