import { Icon, Typography } from "@optimizely/axiom";
import { UploadButton } from "../../UploadButton/UploadButton";
import React from "react";
import classNames from "classnames";

type CertificateUploadButtonProps = {
    disabled?: boolean;
    error?: string;
    onChange: (file: File) => void;
};
export const CertificateUploadButton = ({ disabled, error, onChange }: CertificateUploadButtonProps) => {
    const certLabelClasses = classNames("oui-label push-double--top", {
        "color--bad-news": !!error
    });
    return (
        <>
            <Typography tag="p" type="body" className="push-double--top">
                <strong>Note:</strong> &#123;unique_id&#125; is the same for both SSO and Issuer URLs.
            </Typography>
            <label className={certLabelClasses} htmlFor="signature-certificate">
                Signature Certificate
                <span aria-label="(required)" className="oui-label--required" />
            </label>
            {!!error && (
                <span className="oui-form-note" id="signature-certificate-error">
                    {error}
                </span>
            )}
            <div>
                <UploadButton
                    disabled={disabled}
                    extensions="text/plain,.pem,.p12,.pfx,.crt,.cer,.ca-bundle,.p7b,.p7c,.p7s"
                    onSelection={onChange}
                >
                    <Icon name="upload" size="small" /> Select file...
                </UploadButton>
            </div>
        </>
    );
};

CertificateUploadButton.displayName = "CertificateUploadButton";
