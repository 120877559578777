import { useUserContext } from "../../../../providers/UserProvider";
import { useNotifications } from "../../../../hooks/useNotifications/useNotifications";
import { BillingNotification } from "../../../../rest-model/BillingNotification";
import { BillingNotificationToggle } from "../../../components/BillingNotificationToggle/BillingNotificationToggle";
import { useState } from "react";
import { emitToast } from "../../../../lib/toaster-utils";
import { Attention, Typography } from "@optimizely/axiom";
import { NotificationRecipientsSheet } from "../../../components/NotificationRecipientsSheet/NotificationRecipientsSheet";
import { getNotificationTitle } from "../../../../lib/notification-utils";
import { User } from "../../../../domain/User";
import { Flags } from "../../../../feature-flags/flags";
import { optimizelyInstance } from "../../../../feature-flags/optimizelyInstance";

export const BillingNotificationsPage = () => {
    const { organizationId } = useUserContext();
    const { billingNotifications = [], loading, updateBillingNotification } = useNotifications({ organizationId });
    const [updating, setUpdating] = useState<number | null>(null);
    const [error, setError] = useState(null);
    const [recipientNotification, setRecipientNotification] = useState<null | BillingNotification>(null);
    const enabledProductVariation = optimizelyInstance.decide(Flags.BILLING_NOTIFICATION_ENABLED_PRODUCTS);
    const enabledProducts = (enabledProductVariation?.variables?.enabled_products as { [key: string]: boolean }) || {};
    const allowedNotifications = billingNotifications.filter((notification) => {
        return enabledProducts[notification.productId];
    });

    const handleToggle = ({
        notification,
        notificationIndex
    }: {
        notification: BillingNotification;
        notificationIndex: number;
    }) => {
        setUpdating(notificationIndex);
        setError(null);
        updateBillingNotification({
            organizationId: organizationId!,
            notificationId: notification.id,
            updates: {
                ...notification,
                enabled: !notification.enabled
            }
        })
            .then(() => {
                emitToast({ message: "Billing notification successfully updated." });
            })
            .catch((error) => {
                setError(error?.message || error);
            })

            .finally(() => setUpdating(null));
    };

    const updateNotificationRecipients = ({ recipients }: { recipients: User[] }) => {
        const emails = recipients?.map((user) => user.email);
        return updateBillingNotification({
            organizationId: organizationId!,
            notificationId: recipientNotification!.id,
            updates: {
                ...recipientNotification,
                recipients: emails
            }
        }).then(() => {
            emitToast({ message: "Successfully saved billing recipients." });
            closeRecipientSheet();
        });
    };

    const closeRecipientSheet = () => {
        setRecipientNotification(null);
    };

    const hasExp = allowedNotifications?.find(
        (notification) => notification.productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID
    );

    return (
        <>
            {!!error && (
                <Attention type="bad-news" className="push-double--top">
                    {error}
                </Attention>
            )}
            <Typography type="header1" className="push-double--top">
                Billing Notifications
            </Typography>
            <Typography type="body" tag="div" className="push--top push-double--bottom width--3-5">
                <p>
                    Select which products should send billing notifications and use the "Manage Recipients" link to add
                    any individuals who should receive the notifications.
                </p>
                <p>
                    When enabled, you will receive notifications when your application usage reaches 25%, 50%, 75%, 100%
                    and then every 10% over 100% of your allotment.
                </p>
                <p>
                    These notifications are intended to help you monitor your usage throughout the month to avoid
                    exceeding your limits and incurring additional charges. If you expect to surpass your annual
                    allotment, consider adjusting your plan.
                </p>
            </Typography>
            {allowedNotifications?.map((notification: BillingNotification, notificationIndex) => {
                return (
                    <BillingNotificationToggle
                        loading={updating === notificationIndex}
                        disabled={loading || updating !== null}
                        key={notificationIndex}
                        notification={notification}
                        onManageClick={() => {
                            setRecipientNotification(notification);
                        }}
                        onToggle={() => {
                            handleToggle({ notification, notificationIndex });
                        }}
                    />
                );
            })}
            {hasExp && (
                <Attention type="brand" className="push-double--top width--3-5">
                    <div className="weight--bold">Note</div>
                    Optimizely Experimentation&apos;s billing notifications are managed at the instance level. All other
                    product notifications are managed at the product level.
                </Attention>
            )}
            {!!recipientNotification && (
                <NotificationRecipientsSheet
                    onClose={closeRecipientSheet}
                    onSave={updateNotificationRecipients}
                    searchHeading="Select users to receive billing notifications"
                    subTitle={getNotificationTitle(recipientNotification)}
                    title="Billing Notification Recipients"
                    recipientEmails={recipientNotification.recipients}
                />
            )}
        </>
    );
};

BillingNotificationsPage.displayName = "BillingNotificationsPage";
