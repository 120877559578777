import React from "react";
import { Dropdown, Spinner } from "@optimizely/axiom";

import { useUserContext } from "../../../providers/UserProvider";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import { useUsers } from "../../../hooks/useUsers/useUsers";
import { SearchInput } from "../SearchInput/SearchInput";
import { User } from "../../../domain/User";
import { DEFAULT_DEBOUNCE } from "../../../constants/constants";
import styles from "./UserSearchSelector.module.scss";

export const UserSearchSelector = ({
    clearOnSelect = true,
    disabled = false,
    isUserDisabled,
    onSelectUser,
    onChange,
    placeholder
}: {
    disabled?: boolean;
    clearOnSelect?: boolean;
    isUserDisabled?: (user: User) => boolean;
    onSelectUser?: (user?: User) => void;

    onChange?: (email: string) => void;
    placeholder?: string;
}) => {
    const { organizationId } = useUserContext();

    const {
        debouncedValue: debouncedUserQuery,
        value: userQuery,
        setValue: setUserQuery
    } = useDebounce("", DEFAULT_DEBOUNCE);

    const { userList, isSearching } = useUsers({
        organizationId,
        query: debouncedUserQuery.length >= 2 ? debouncedUserQuery : ""
    });

    const searchList = userList && debouncedUserQuery.length >= 2 && !isSearching ? userList : [];
    const noResults = searchList.length === 0 && debouncedUserQuery.length >= 2 && !isSearching;

    const searchUsers = (input: { value: string }) => {
        setUserQuery(input.value);
        onChange && onChange(input.value);
    };

    const renderNoResult = () => {
        return noResults ? <div className="soft">No matching users</div> : null;
    };

    const renderSearchInput = ({
        onBlur,
        onClick,
        ref
    }: {
        onBlur: (...args: any[]) => any;
        onClick: (...args: any[]) => any;
        ref: React.Ref<HTMLInputElement>;
    }) => (
        <SearchInput
            ariaLabel="Search for users..."
            inputRef={ref}
            disabled={disabled}
            placeholder={placeholder || "Search for users..."}
            value={userQuery}
            onBlur={onBlur}
            onChange={searchUsers}
            onClick={onClick}
        />
    );

    return (
        <Dropdown renderActivator={renderSearchInput} fullWidth>
            <Dropdown.Contents isLoading={isSearching} renderHeader={renderNoResult} style={{ width: "100%" }}>
                {searchList.map((user) => (
                    <Dropdown.ListItem key={user.id}>
                        {(isUserDisabled && !isUserDisabled(user)) || !isUserDisabled ? (
                            <Dropdown.BlockLink
                                onClick={() => {
                                    clearOnSelect ? setUserQuery("") : setUserQuery(user.email);
                                    onSelectUser && onSelectUser(user);
                                }}
                            >
                                <div className="truncated">
                                    {user.firstName} {user.lastName} ({user.email})
                                </div>
                            </Dropdown.BlockLink>
                        ) : (
                            <Dropdown.BlockLink
                                onClick={() => {
                                    onSelectUser && onSelectUser();
                                }}
                            >
                                <div className={styles["user-search-selector__userdisabled"]}>
                                    {user.firstName} {user.lastName} ({user.email})
                                </div>
                            </Dropdown.BlockLink>
                        )}
                    </Dropdown.ListItem>
                ))}
                {isSearching && <Spinner hasOverlay />}
            </Dropdown.Contents>
        </Dropdown>
    );
};

UserSearchSelector.displayName = "UserSearchSelector";
