/* eslint-disable react/style-prop-object */
import React from "react";
import { Dropdown } from "@optimizely/axiom";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../domain/OrganizationProduct";
import styles from "./RoleFilters.module.scss";

export interface IRoleFilters {
    instance: IOrganizationProductInstance | null;
    product: OrganizationProduct | null;
}

export const RoleFilters = ({
    filters,
    onChange,
    products = [],
    disableInstances
}: {
    filters: IRoleFilters;
    onChange: (filters: IRoleFilters) => void;
    products: OrganizationProduct[];
    disableInstances?: boolean;
}) => {
    const updateProductSelection = ({ product }: { product: OrganizationProduct | null }) => {
        // if the product is changed, make sure to reset the instance
        onChange({ product, instance: null });
    };

    const updateInstanceSelection = ({ instance }: { instance: IOrganizationProductInstance | null }) => {
        onChange({ ...filters, instance });
    };

    return (
        <div className={styles["role-filters"]}>
            <Dropdown
                buttonContent={{
                    label: "Product",
                    content: `${filters?.product?.name || "All"}`
                }}
                style="plain"
                arrowIcon="down"
                width={350}
            >
                <Dropdown.Contents>
                    <Dropdown.ListItem role="option">
                        <Dropdown.BlockLink
                            isLink={!!filters?.product}
                            onClick={() => updateProductSelection({ product: null })}
                        >
                            <Dropdown.BlockLinkText text="All" />
                        </Dropdown.BlockLink>
                    </Dropdown.ListItem>

                    {products.map((p) => {
                        return (
                            <Dropdown.ListItem key={p.id} role="option">
                                <Dropdown.BlockLink
                                    isLink={filters?.product?.id !== p.id}
                                    onClick={() => updateProductSelection({ product: p })}
                                >
                                    <Dropdown.BlockLinkText text={p.name} />
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
            <Dropdown
                isDisabled={disableInstances || !filters?.product}
                buttonContent={{
                    label: "Instance",
                    content: `${filters?.instance?.nickname || filters?.instance?.name || "All"}`
                }}
                style="plain"
                arrowIcon="down"
                width={350}
            >
                <Dropdown.Contents>
                    <Dropdown.ListItem role="option">
                        <Dropdown.BlockLink
                            isLink={!!filters?.instance}
                            onClick={() => updateInstanceSelection({ instance: null })}
                        >
                            <Dropdown.BlockLinkText text="All" />
                        </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                    {filters?.product?.instances?.map((productInstance) => {
                        const { id: instanceId, name, nickname } = productInstance;
                        return (
                            <Dropdown.ListItem key={instanceId} role="option">
                                <Dropdown.BlockLink
                                    isLink={filters?.instance?.id !== instanceId}
                                    onClick={() => updateInstanceSelection({ instance: productInstance })}
                                >
                                    <Dropdown.BlockLinkText text={nickname || name} />
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};

RoleFilters.displayName = "RoleFilters";
