import { ProductAttribute } from "./ProductAttribute";
import { ROLE_DISPLAYNAME_PROPERTY_NAME, INSTANCE_ROLE_PROPERTY_NAME } from "../constants/property-constants";

export class Role {
    constructor(restRole: any) {
        this.id = restRole.Id;
        this.isInstanceRole =
            restRole.Properties?.find((p: { name: string; value: any }) => p.name === INSTANCE_ROLE_PROPERTY_NAME)
                ?.value === "true";
        this.name = restRole.Name;
        this.displayName =
            restRole.Properties?.find((p: { name: string; value: any }) => p.name === ROLE_DISPLAYNAME_PROPERTY_NAME)
                ?.value || restRole.Name;
        this.description = restRole.Description;
        this.productId = restRole.ProductId;
        this.roleType = restRole.RoleType;
        this.targetIds = restRole.TargetIds;
        this.isActive = restRole.IsActive;
        this.attributes = restRole.Attributes?.map((att: any) => new ProductAttribute(att));
        this.properties = restRole.Properties;
        this.created = restRole.Created;
        this.modified = restRole.Modified;
    }

    id: string;
    isInstanceRole: boolean;
    name: string;
    displayName: string;
    description: string;
    productId: string;
    roleType: string;
    targetIds: string[];
    isActive: boolean;
    attributes: any[];
    properties: any[];
    created: string;
    modified: string;
}
