import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Flags } from "../../../../feature-flags/flags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";

export const UserManagementRouting = () => {
    const { enabled: showSeparateUsersPage } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { enabled: showRolesSidebarNav } = useFeatureFlag(Flags.SHOW_ROLES_SIDEBAR_NAVIGATION);

    const { pathname } = useLocation();

    // when feature flags are no longer used for view logic, remove routes in <App />
    const unregisterUserGroupsRoute =
        showSeparateUsersPage &&
        (pathname === "/user-management/user-groups" || pathname === "/user-management/user-groups/");
    const unregisterRolesRoute =
        showRolesSidebarNav && (pathname === "/user-management/roles" || pathname === "/user-management/roles/");

    // when feature flag is no longer used for view logic, replace this logic with route navigation in <App />
    const defaultToUserGroupsPage =
        !showSeparateUsersPage && (pathname === "/user-management" || pathname === "/user-management/");
    const defaultToUsersPage =
        showSeparateUsersPage && (pathname === "/user-management" || pathname === "/user-management/");

    if (unregisterUserGroupsRoute || unregisterRolesRoute) {
        return <Navigate to="/404" replace />;
    }

    if (defaultToUserGroupsPage) {
        return <Navigate to="/user-management/user-groups" replace />;
    }

    if (defaultToUsersPage) {
        return <Navigate to="/user-management/users" replace />;
    }

    return <Outlet />;
};
