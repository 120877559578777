import { useServicesContext } from "../../providers/ServicesProvider";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import useSWR from "swr";

export const useProviders = () => {
    const { idpService } = useServicesContext();

    const { data, error } = useSWR("/api/providers", () => {
        return idpService?.getProviders();
    });

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    return {
        providers: data,
        error
    };
};
