import React from "react";

export const InputFieldError = ({
    className,
    children,
    id
}: {
    className?: string;
    children: React.ReactNode;
    id?: string;
}) => {
    return (
        <span className={`axiom-typography--caption color--bad-news ${className}`} id={id}>
            {children}
        </span>
    );
};

InputFieldError.displayName = "InputFieldError";
