import React, { useEffect, useState } from "react";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../../feature-flags/flags";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../providers/UserProvider";
import { ADMINCENTER_GROUP_NAME, ALL_PAGES_SIZE, DEFAULT_PAGE_SIZE } from "../../../../constants/constants";
import { DetailedInvitation, SupportTable } from "../../../components/SupportTable/SupportTable";
import { useInvitations } from "../../../../hooks/useInvitations/useInvitations";
import { Button, PaginationControls } from "@optimizely/axiom";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { SupportAccessForm } from "../../../components/SupportAccessForm/SupportAccessForm";
import { Invitation } from "../../../../domain/Invitation";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { datadogRum } from "@datadog/browser-rum";
import { useUserGroups } from "../../../../hooks/useUserGroups/useUserGroups";
import { useUsers } from "../../../../hooks/useUsers/useUsers";
import { emitToast } from "../../../../lib/toaster-utils";

export const Access = () => {
    const { profile, instancePermissions, refreshPermissions } = useUserContext();
    const [targetOrgId, setTargetOrgId] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { userGroups: targetOrgUserGroups = [], isLoading: userGroupsLoading } = useUserGroups({
        organizationId: targetOrgId,
        query: ADMINCENTER_GROUP_NAME,
        useExactMatchSearch: true,
        pageSize: ALL_PAGES_SIZE
    });
    const { enabled: showSupportPage } = useFeatureFlag(Flags.ENABLE_SUPPORT_FEATURES);
    const { enabled: hideCsmOrgs, variables } = useFeatureFlag(Flags.HIDE_SUPPORT_ORGANIZATIONS);
    const [showForm, setShowForm] = useState(false);
    const [invitationToRevoke, setInvitationToRevoke] = useState<Invitation | null>(null);
    const [removalError, setRemovalError] = useState<string | null>(null);
    const [removalInProgress, setRemovalInProgress] = useState(false);
    const [sidebarInvitation, setSidebarInvitation] = useState<Invitation | null>(null);
    const navigate = useNavigate();
    const { removeUser } = useUsers({ organizationId: invitationToRevoke?.organizationId });

    const { invitations, isLoading, createInvitation, mutate } = useInvitations({
        createdBy: profile?.email,
        inviteeEmail: profile?.email,
        pageSize: ALL_PAGES_SIZE
    });

    useEffect(() => {
        if (!showSupportPage) {
            navigate("/404");
        }
    }, [navigate, showSupportPage]);

    const csmOrgIds: string[] = [];
    const csmOrgs: CsmOrgs = variables.csm_orgs as CsmOrgs;
    if (hideCsmOrgs) {
        csmOrgs.organizations?.map((o) => csmOrgIds.push(o.id));
    }

    const handlePagination = (page: number) => {
        setCurrentPage(page);
    };

    interface CsmOrgs {
        organizations: [
            {
                id: string;
                name: string;
            }
        ];
    }

    const mappedInvitations = invitations
        ?.filter(
            (i) =>
                i.status !== "Revoked" &&
                instancePermissions?.find(
                    (p) => !csmOrgIds.includes(p.organizationId) && p.organizationId === i.organizationId
                )
        )
        .map((i) => {
            return {
                ...i,
                organizationName:
                    i.organizationName ||
                    instancePermissions?.find((p) => p.organizationId === i.organizationId)?.organizationName
            };
        });
    const latestInvitations: { [key: number]: DetailedInvitation } = {};

    mappedInvitations?.forEach((item: any) => {
        if (!latestInvitations[item.organizationId] || item.created > latestInvitations[item.organizationId].created) {
            latestInvitations[item.organizationId] = item;
        }
    });

    const allPagesInvites = Object.values(latestInvitations); //all pages

    const totalPages = Math.ceil(allPagesInvites.length / DEFAULT_PAGE_SIZE);
    const showPagination = allPagesInvites.length > DEFAULT_PAGE_SIZE;

    const start = (currentPage - 1) * DEFAULT_PAGE_SIZE;
    const end = start + DEFAULT_PAGE_SIZE;
    const detailedInvitations = allPagesInvites.slice(start, end);

    const handleSupportInvitation = async ({ invitation }: { invitation?: Invitation | null }) => {
        if (invitation) {
            return setInvitationToRevoke(invitation);
        }

        const [targetOrgAdminGroup] = targetOrgUserGroups;
        await createInvitation({
            createdBy: profile!.email,
            email: profile!.email,
            firstName: profile!.firstName,
            lastName: profile!.lastName,
            organizationId: targetOrgId,
            requireAcceptance: false,
            userGroups: targetOrgAdminGroup ? [{ id: targetOrgAdminGroup.id, name: targetOrgAdminGroup.name }] : []
        });

        await refreshPermissions();
    };

    const handleConfirmation = async () => {
        setRemovalInProgress(true);

        !!profile &&
            removeUser({ user: profile })
                .then(() => {
                    emitToast({ message: "Organization access successfully removed." });
                    const refetchEvent = new Event("@opti-product-switcher:refetch");
                    window.dispatchEvent(refetchEvent);

                    refreshPermissions().then(() => {
                        mutate().then(() => {
                            setRemovalError(null);
                            setInvitationToRevoke(null);
                        });
                        setSidebarInvitation(null);
                    });
                })
                .catch((error) => {
                    console.error(error);
                    datadogRum.addError(error);
                    setRemovalError(
                        error[0]?.message ||
                            "Unable to remove organization access at this time. Please try again later or contact your admin."
                    );
                })
                .finally(() => {
                    setRemovalInProgress(false);
                });
    };

    const closeSidebar = () => {
        setTargetOrgId("");
        setShowForm(false);
        setSidebarInvitation(null);
    };

    const handleRemoval = ({ invitation }: { invitation: Invitation }) => {
        setInvitationToRevoke(invitation);
    };

    const handleCancel = () => {
        setRemovalError(null);
        setInvitationToRevoke(null);
        setSidebarInvitation(null);
    };

    const showSidebar = ({ invitation }: { invitation: Invitation }) => {
        setSidebarInvitation(invitation);
    };

    return (
        <>
            <div className="flex flex--row flex-justified--end push-triple--top">
                <Button
                    style="highlight" //eslint-disable-line react/style-prop-object
                    onClick={() => {
                        setShowForm(true);
                    }}
                >
                    Add organization access...
                </Button>
            </div>

            {(showForm || !!sidebarInvitation) && (
                <Sidebar
                    heading={showForm ? "Add Organization Access" : "Organization Access"}
                    isOpen
                    onCancel={closeSidebar}
                >
                    <SupportAccessForm
                        invitation={sidebarInvitation}
                        onClose={closeSidebar}
                        onOrgSelect={setTargetOrgId}
                        onSubmit={handleSupportInvitation}
                        isLoading={userGroupsLoading}
                    />
                </Sidebar>
            )}

            <div className="support__table">
                <SupportTable
                    invitations={detailedInvitations}
                    loading={isLoading}
                    onClick={showSidebar}
                    onRemove={handleRemoval}
                />
            </div>
            {showPagination && (
                <PaginationControls
                    className="anchor--bottom"
                    currentPage={currentPage}
                    goToPage={handlePagination}
                    totalPages={totalPages}
                />
            )}
            {!!invitationToRevoke && (
                <ConfirmationDialog
                    onCancel={handleCancel}
                    onConfirmation={handleConfirmation}
                    loading={removalInProgress}
                    error={removalError}
                    title="Remove Organization Access"
                    callToActionText="Remove access..."
                >
                    <div key={1}>
                        Revoking access to this organization means you will be removed from the organization and unable
                        to enact changes or offer support to customers.
                    </div>
                </ConfirmationDialog>
            )}
        </>
    );
};

Access.displayName = "SupportAccessPage";
