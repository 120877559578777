import { useServicesContext } from "../../providers/ServicesProvider";
import useSWR from "swr";
import { BillingNotification } from "../../rest-model/BillingNotification";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

export const useNotifications = ({ organizationId }: { organizationId?: string }) => {
    const { notificationsService } = useServicesContext();
    const {
        data: billingNotifications,
        error: billingNotificationsError,
        mutate: mutateBillingNotifications,
        isValidating
    } = useSWR<BillingNotification[], Error>(
        organizationId ? `/api/organizations/${organizationId}/billingnotifications` : null,
        () => {
            if (!organizationId) {
                return [];
            }
            return notificationsService!.getBillingNotifications({ organizationId });
        }
    );

    const updateBillingNotification = async ({
        organizationId,
        notificationId,
        updates
    }: {
        organizationId: string;
        notificationId: string;
        updates: Partial<Pick<BillingNotification, "enabled" | "recipients">>;
    }) => {
        const result = await notificationsService!.updateBillingNotification({
            notificationId,
            organizationId,
            updates
        });
        if (result) {
            const updated = [...(billingNotifications || [])];
            const existingEntryIndex = updated.findIndex((notification) => notification.id === notificationId);
            updated[existingEntryIndex] = result;
            await mutateBillingNotifications(updated);
        }
    };
    useEffect(() => {
        if (billingNotificationsError) {
            datadogRum.addError(billingNotificationsError);
        }
    }, [billingNotificationsError]);

    return {
        billingNotifications,
        error: billingNotificationsError,
        loading: isValidating,
        updateBillingNotification
    };
};
